import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import closeIcon from "../../../../../assets/icons/dashboard-x-close.svg";
import React, { useEffect } from "react";
import InviteUserForm from "../../InviteUserForm/InviteUserForm";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getStats } from "../../../../../data/slices/statisticSlice";

function InviteUserModal({ id, open, handleClose, data, ...rest }) {
  const { t } = useTranslation("user");
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getStats({}));
  // }, []);

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason === "escapeKeyDown" && handleClose(id)}
      className="ta-modal user_modal dashboard-user-modal"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px"
          }
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <span className="label"> {t("user-18")} </span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>
      <p className="subtitle">{t("user-19")}</p>

      <DialogContent className="ta-modal-content-scroll">
        <InviteUserForm closeModal={handleClose} />
      </DialogContent>
    </Dialog>
  );
}

export default InviteUserModal;

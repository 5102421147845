import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import closeIcon from "../../../../assets/icons/dashboard-x-close.svg";
import deleteIcon from "../../../../assets/icons/dashboard-modal-delete.svg";
import { closeModal } from "../../../../data/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import "./_CancelModal.scss";
import { cancelPlan } from "../../../../data/slices/currentPlan";
import WrapperLoader from "../../../../components/WrapperLoader/WrapperLoader";
import { useTranslation } from "react-i18next";

function CancelModal({ id, open, handleClose, data, ...rest }) {
  const { t } = useTranslation("other");

  const dispatch = useDispatch();
  const { cancelPlanLoading } = useSelector((state) => state.currentPlan);

  const handleConfirm = () => {
    dispatch(cancelPlan())
      .unwrap()
      .then((res) => {
        console.log(res);
        message.success(t("other-10"));
        window.location.reload(false);
        handleClose("cancel-plan-modal");
      })
      .catch((err) => {
        console.log(err);
        message.error("We encountered an issue while cancel your payment");
      });
  };
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      className="ta-modal confirm_modal"
    >
      <WrapperLoader status={cancelPlanLoading}>
        <DialogTitle id="alert-dialog-title" className="modal_title">
          <img src={deleteIcon} alt="" />
          <span className="close-btn" onClick={() => handleClose(id)}>
            <img src={closeIcon} alt="" />
          </span>
        </DialogTitle>
        <DialogContent className="ta-modal-content-scroll content_confirm">
          <p className="title">{t("other-11")}</p>
          <div className="subtitle-container">
            <p className="subtitle">{t("other-12")}</p>
          </div>
        </DialogContent>
        <div className="action_confirm">
          <button
            onClick={() => dispatch(closeModal("cancel-plan-modal"))}
            className="dashboard-cancel-btn"
          >
            {t("other-13")}
          </button>
          <button onClick={handleConfirm} className="dashboard-confirm-btn">
            {t("other-14")}
          </button>
        </div>
      </WrapperLoader>
    </Dialog>
  );
}

export default CancelModal;

import { Table, Tag } from "antd";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileHistory } from "../../../../data/slices/authSlice";
import { fDateTime } from "../../../../utils/dateFormatter";
import { useTranslation } from "react-i18next";

const History = () => {
  const { t } = useTranslation("profile");
  const dispatch = useDispatch();

  const { history } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getProfileHistory());
  }, []);

  const columns = [
    {
      title: t("profile.Profile-49"),
      dataIndex: "os",
      key: "os",
      render: (text) => <span className="user-history-text-no-wrap">{text || "-"}</span>
    },
    {
      title: t("profile.Profile-59"),
      dataIndex: "browser",
      key: "browser",
      align: "center",
      render: (text) => <span className="user-history-text-no-wrap">{text || "-"}</span>
    },
    {
      title: t("profile.Profile-50"),
      dataIndex: "ipAddress",
      key: "ipAddress",
      align: "center",
      render: (text) => (
        <span className="user-history-text-no-wrap">
          <Tag color="blue">{text || "-"}</Tag>
        </span>
      )
    },
    {
      title: t("profile.Profile-51"),
      dataIndex: "location",
      key: "location",
      align: "center",
      render: (text) => <span className="user-history-text-no-wrap">{text || "-"}</span>
    },
    {
      title: t("profile.Profile-52"),
      dataIndex: "lastLoginAt",
      key: "lastLoginAt",
      align: "center",
      render: (text) => <span className="user-history-text-no-wrap">{fDateTime(text)}</span>
    }
  ];

  return (
    <div className="table-data-container">
      <Table
        columns={columns}
        dataSource={history}
        size="middle"
        pagination={{
          defaultPageSize: 8,
          showSizeChanger: false
        }}
      />
    </div>
  );
};

export default memo(History);

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import closeIcon from "../../../../../assets/icons/dashboard-x-close.svg";
import UserGroupForm from "../../UserGroupForm/UserGroupForm";
import { useTranslation } from "react-i18next";

function UserGroupModal({ id, open, handleClose, data, ...rest }) {
  const { t } = useTranslation("role");

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason === "escapeKeyDown" && handleClose(id)}
      className="ta-modal user_modal group_modal dashboard-group-modal"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "829px"
          }
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <span className="label">{data?.isEdit ? t("role-7") : t("role-8")}</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>
      <p className="subtitle">{!data?.isEdit ? t("role-9") : t("role-10")}</p>

      <DialogContent className="ta-modal-content-scroll">
        <UserGroupForm
          isEdit={data?.isEdit || false}
          id={data?.isEdit ? data?.id : null}
          group={data?.isEdit ? data?.group : null}
        />
      </DialogContent>
    </Dialog>
  );
}

export default UserGroupModal;

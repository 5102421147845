import { MetricDefinition, useMetrics } from "@cabify/prom-react";
import { createCaller } from "react-outside-call";

export const PROM_UI_REQUEST_SECONDS_COUNT = {
  type: "histogram",
  name: "prom_ui_request_seconds",
  description: "A metric for UI request latency",
  buckets: [0.005, 0.01, 0.025, 0.05, 0.1, 0.25, 0.5, 1, 2.5, 5, 10]
};

export const customPromMetrics = [PROM_UI_REQUEST_SECONDS_COUNT];

export const callConfig = createCaller({
  // eslint-disable-next-line react-hooks/rules-of-hooks
  metrics: () => useMetrics()
});

import React from "react";
import { openModal } from "../../data/slices/modals";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

function Feedback() {
  const { pathname } = useLocation();
  const searchParams = new URLSearchParams(document.location.search);
  const type = searchParams.get("type");

  const dispach = useDispatch();

  if (type === "demo") {
    return;
  }

  if (pathname?.includes("/control/")) {
    return;
  }

  return (
    <div className="feedback" onClick={() => dispach(openModal("feedback-modal"))}>
      Feedback
    </div>
  );
}

export default Feedback;

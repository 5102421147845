import axios from "axios";
import { store } from "../data";
import { initialise, logout, setSession } from "../data/slices/authSlice";
import { closeAllModals } from "../data/slices/modals";
import { Config } from "../config";
//prometheus
import { callConfig, PROM_UI_REQUEST_SECONDS_COUNT } from "../constants";

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json"
};
const axiosInstance = axios.create({
  baseURL: Config?.REACT_APP_API_URL,
  headers,
  withCredentials: true
});

const registerMetrics = (response) => {
  if (!response) return;
  const start = response.config.headers?.["request-startTime"];
  const end = performance.now();

  callConfig.call.metrics?.observe(
    PROM_UI_REQUEST_SECONDS_COUNT.name,
    {
      uri: response.config.url?.replace(/\?.*/, "") || "unknown",
      method: response.config.method?.toUpperCase() || "unknown",
      statusCode: response.status.toString()
    },
    (end - start) / 1000
  );
};

//request interceptor to add the auth token header to requests
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("gomydesk_token");
    if (accessToken) {
      config.headers["X-Session-Token"] = accessToken;
    }
    config.headers["request-startTime"] = performance.now().toString();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (newAccessToken) => {
  refreshSubscribers.forEach((callback) => callback(newAccessToken));
  refreshSubscribers = [];
};

axiosInstance.interceptors.response.use(
  (response) => {
    registerMetrics(response);
    return response;
  },
  async (error) => {
    const { dispatch } = store;
    registerMetrics(error?.response);

    const previousRequest = error?.config;
    if (error?.response?.status === 401 && !previousRequest?.sent) {
      previousRequest.sent = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const response = await axiosInstance.get("auth/refresh_token");
          const newAccessToken = response.data.token;
          localStorage.setItem("gomydesk_token", newAccessToken);
          previousRequest.headers["X-Session-Token"] = newAccessToken;

          const res = await axiosInstance.get("profile/whoami");
          const user = res.data;
          axiosInstance.defaults.headers["X-User-ID"] = user?.username;
          dispatch(initialise({ isAuthenticated: true, user }));

          isRefreshing = false;
          onRefreshed(newAccessToken);
          return axiosInstance(previousRequest);
        } catch (err) {
          isRefreshing = false;
          dispatch(closeAllModals());
          // dispatch(logout());
          setSession(null);
          localStorage.removeItem("gomydesk_token");
        }
      } else {
        return new Promise((resolve) => {
          refreshSubscribers.push((newAccessToken) => {
            previousRequest.headers["X-Session-Token"] = newAccessToken;
            resolve(axiosInstance(previousRequest));
          });
        });
      }
    }
    return Promise.reject((error.response && error.response.data) || "Something went wrong");
  }
);

export default axiosInstance;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import axios from "../../utils/axios";

export const createFeedback = createAsyncThunk("api/create-feedback", async (values, thunkApi) => {
  let data;
  try {
    const response = await axios.post(`/tickets`, { ...values });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

const initialState = {
  media: [],
  data: { 1: null, 2: null, 3: null },
  screenShot: false,
  screenRecord: false,
  feedbackType: null,
  loading: false,
  error: null
};

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    cancelEvent: (state, { payload }) => {
      state[payload] = false;
    },
    startEvent: (state, { payload }) => {
      state[payload] = true;
    },
    saveMedia: (state, { payload }) => {
      state.media = [...state.media, { ...payload, feedbackType: state.feedbackType, id: uuid() }];
    },
    setFeedbackType: (state, { payload }) => {
      state.feedbackType = payload;
    },
    deleteMedia: (state, { payload }) => {
      state.media = state.media.filter((media) => media.id !== payload);
    },
    saveData: (state, { payload }) => {
      state.data[payload.type] = payload.data;
    },
    resetFeedbackData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    [createFeedback.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [createFeedback.fulfilled]: (state, action) => {
      state.loading = false;
      state = initialState;
    },
    [createFeedback.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    }
  }
});

export const {
  cancelEvent,
  startEvent,
  saveMedia,
  setFeedbackType,
  deleteMedia,
  saveData,
  resetFeedbackData
} = feedbackSlice.actions;

export default feedbackSlice.reducer;

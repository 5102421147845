import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import { InputText } from "../../../components/InputFields";
import { updateMePassword } from "../../../data/slices/memberSlice";
import { Input } from "antd";

function Security() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string()
        .min(8, "Too Short!")
        .max(24, "Too Long!")
        .required("This field is required"),
      password: Yup.string()
        .min(8, "Too Short!")
        .max(24, "Too Long!")
        .required("This field is required"),
      confirmPassword: Yup.string().when("password", {
        is: (val) => (val && val?.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("password")], "Both password need to be the same")
      })
    }),
    onSubmit: (values, { resetForm }) => {
      const { oldPassword, password } = values;
      dispatch(updateMePassword({ oldPassword, password }))
        .unwrap()
        .then((res) => {
          message.success(`User password updated successfully.`);
          resetForm();
          setShow(false);
        })
        .catch((err) => {
          message.error(err.message || "something went wrong");
        });
    }
  });

  return (
    <div className="security profile-settings">
      <div className="security-container">
        {show ? (
          <form onSubmit={formik.handleSubmit}>
            <InputText
              formik={formik}
              field={{
                name: "oldPassword",
                type: "password",
                placeholder: "Enter your old password",
                label: "Old Password"
              }}
            />
            <InputText
              formik={formik}
              field={{
                name: "password",
                type: "password",
                placeholder: "Enter your password",
                label: "Password"
              }}
            />
            <InputText
              formik={formik}
              field={{
                name: "confirmPassword",
                type: "password",
                placeholder: "Confirm your password",
                label: "Confirm password"
              }}
            />

            <div className="security_form">
              <button
                type="button"
                onClick={() => {
                  setShow(false);
                  formik.resetForm();
                }}
                className="dashboard-cancel-btn"
              >
                Cancel
              </button>
              <button type="submit" className="dashboard-confirm-btn">
                Confirm
              </button>
            </div>
          </form>
        ) : (
          <>
            <div className="s_input">
              <label>Email</label>
              <Input className="input_antd" disabled value={user?.email} />
            </div>

            <div className="s_input security-container-input">
              <label>Password</label>
              <Input className="input_antd" disabled value={"***************"} />
            </div>

            <div className="security-container-change-password" onClick={() => setShow(true)}>
              <button type="button">Change Password</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Security;

import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { updateMePassword } from "../../../../data/slices/memberSlice";
import { message } from "antd";
import { InputText } from "../../../../components/InputFields";
import { checkPasswordStrength } from "../../../../utils/checkPasswordStrength";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Security = () => {
  const { t } = useTranslation("profile");
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required(t("profile.Profile-34")),
      password: Yup.string().min(8, t("profile.Profile-35")).required(t("profile.Profile-36")),
      confirmPassword: Yup.string().when("password", {
        is: (val) => (val && val?.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("password")], t("profile.Profile-37"))
      })
    }),
    onSubmit: (values, { resetForm }) => {
      const { oldPassword, password } = values;
      dispatch(updateMePassword({ oldPassword, password }))
        .unwrap()
        .then((res) => {
          message.success(t("profile.Profile-38"));
          resetForm();
        })
        .catch((err) => {
          message.error(err.message || t("profile.Profile-38"));
        });
    }
  });

  const [strongText, setStrongText] = useState("");

  useEffect(() => {
    setStrongText(checkPasswordStrength(formik?.values?.password));
  }, [formik?.values?.password]);

  return (
    <form className="ProfileV2-page-col-2-form-security" onSubmit={formik.handleSubmit}>
      <InputText
        formik={formik}
        field={{
          name: "oldPassword",
          type: "password",
          placeholder: t("profile.Profile-40"),
          label: t("profile.Profile-41")
        }}
      />

      <div>
        <InputText
          formik={formik}
          field={{
            name: "password",
            type: "password",
            placeholder: t("profile.Profile-42"),
            label: t("profile.Profile-43")
          }}
        />

        <div className="ProfileV2-page-col-2-form-security-stront-password">
          <span
            style={{
              backgroundColor: `${
                strongText === t("profile.Profile-44")
                  ? "#F5365B"
                  : strongText === t("profile.Profile-45")
                    ? "#FB6340"
                    : strongText === t("profile.Profile-46")
                      ? "#2CCE8A"
                      : "#D9D9D9"
              }`
            }}
          ></span>
          <span
            style={{
              backgroundColor: `${
                strongText === t("profile.Profile-45")
                  ? "#FB6340"
                  : strongText === t("profile.Profile-46")
                    ? "#2CCE8A"
                    : "#D9D9D9"
              }`
            }}
          ></span>
          <span
            style={{
              backgroundColor: `${strongText === t("profile.Profile-46") ? "#2CCE8A" : "#D9D9D9"}`
            }}
          ></span>
          {formik?.values?.password && (
            <small
              style={{
                color: `${
                  strongText === t("profile.Profile-44")
                    ? "#F5365B"
                    : strongText === t("profile.Profile-45")
                      ? "#FB6340"
                      : strongText === t("profile.Profile-46")
                        ? "#2CCE8A"
                        : "#D9D9D9"
                }`
              }}
            >
              {strongText}
            </small>
          )}
        </div>
      </div>

      <InputText
        formik={formik}
        field={{
          name: "confirmPassword",
          type: "password",
          placeholder: t("profile.Profile-47"),
          label: t("profile.Profile-48")
        }}
      />

      <div className="ProfileV2-page-col-2-form-btn">
        <button className="ProfileV2-page-col-2-form-btn-confirm" type="submit">
          {t("profile.Profile-11")}
        </button>
      </div>
    </form>
  );
};

export default Security;

import React, { useEffect, useState } from "react";
import ReactConfetti from "react-confetti";
import useWindowSize from "../../hooks/useWindowSize";

function Confetti({ time }) {
  const confetti = localStorage.getItem("invited_user");
  const { width, height } = useWindowSize();
  const [isRunning, setIsRunning] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFadeOut(true);
    }, time);

    const cleanupTimeoutId = setTimeout(() => {
      setIsRunning(false);
      localStorage.removeItem("invited_user");
    }, time + 1000);

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(cleanupTimeoutId);
    };
  }, [time]);

  const confettiStyle = {
    zIndex: "100",
    opacity: fadeOut ? 0 : 1,
    transition: "opacity 1s ease-out"
  };

  return (
    <div>
      {confetti && isRunning && (
        <ReactConfetti style={confettiStyle} width={width} height={height} />
      )}
    </div>
  );
}

export default Confetti;

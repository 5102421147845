import React from "react";
import "./_UpgradeCard.scss";
import { useNavigate } from "react-router-dom";
import upgradeIcon from "../../assets/icons/upgrade-plan.svg";
import { useTranslation } from "react-i18next";

function UpgradeCard() {
  const { t } = useTranslation("other");

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/admin/plans");
  };

  return (
    <div className="section_upgrade">
      <div className="card">
        <img src={upgradeIcon} alt="" />
        <div>
          <p>{t("other-1")}</p>
          <p>{t("other-2")}</p>
        </div>
        <button onClick={handleNavigate} className="btn_plan">
          {t("other-3")}
        </button>
      </div>
    </div>
  );
}

export default UpgradeCard;

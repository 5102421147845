import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPlan } from "../../data/slices/currentPlan";
import UpgradeCard from "../../components/UpgradePlanCard/UpgradeCard";
import "./_FeatureProvider.scss";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { getVerbsForResources } from "../../helpers/getVerbsForResources";
import { Alert } from "antd";

function FeatureProvider({ children, status, type }) {
  const { user, profileOrg } = useSelector((state) => state.auth);
  let haveAccessPlans = getVerbsForResources(["plans"], user?.roles)?.includes("read");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (profileOrg?.isFromMarketplace) {
      return;
    }
    dispatch(getCurrentPlan());
  }, []);

  const handleNavigate = () => {
    return navigate("/admin/plans");
  };

  //enable if the user comes from the marketplace and has a subscription from aws
  if (
    profileOrg?.isFromMarketplace &&
    profileOrg?.marketplaceSubscriptionStatus === "subscribe-success"
  ) {
    return children;
  }

  return (
    <React.Fragment>
      {status ? (
        children
      ) : type === "upgrade" ? (
        <UpgradeCard />
      ) : type === "lock" ? (
        <div className="lock_overlay">
          {/* <img className="upgrade-icon" src={upgradePlanIcon} alt="" /> */}
          {haveAccessPlans ? (
            <Alert
              className="upgrade_alert"
              message={
                <div className="content_alert">
                  {/* <Marquee pauseOnHover gradient={false}> */}
                  {/* To unlock additional features, consider upgrading your plan for enhanced
                    functionality and capabilities */}
                  Upgrade your plan to access more features.
                  {/* </Marquee> */}
                  <Button onClick={handleNavigate} className="upgrade_button">
                    Upgrade
                  </Button>
                </div>
              }
              type="warning"
            />
          ) : (
            <Alert
              className="upgrade_alert"
              message="Reach out to the admin to enable this feature."
              type="warning"
            />
          )}
          <div className="children_wrapper">{children}</div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default FeatureProvider;

import { Slider } from "antd";

const PersonaliseItem = ({ icon, title, marks, formik, type, item }) => {
  const handleChange = (e) => {
    formik.setFieldValue(type, marks[e]);
  };

  return (
    <div className="personalise-content">
      <div className="config-title">
        {icon}
        <span>{title}</span>
      </div>

      <div className="config-select">
        <div className="slider-container">
          <Slider
            marks={marks}
            step={null}
            value={Object.keys(marks).indexOf(
              Object.keys(marks).find((key) => marks[key] === formik?.values[type])
            )}
            tooltip={{ formatter: null }}
            className="slider"
            min={item?.allowedValues?.length === 1 ? 1 : 0}
            max={
              // type === "gpu" && !item?.allowedValues?.includes(0)
              //   ? item?.allowedValues?.length
              // :
              item?.allowedValues?.length - 1
            }
            disabled={item?.allowedValues?.length === 1}
            onChange={handleChange}
          />
        </div>

        <span className="config-select-info">{formik?.values[type]}</span>
      </div>
    </div>
  );
};

export default PersonaliseItem;

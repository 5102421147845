import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import imageIcon from "./../../../../assets/icons/procreate.svg";
import selectedIcon from "./../../../../assets/icons/dashboard-image-selected.svg";
import { IMAGE_GROUP_FIELDS as fields } from "./../../constants/fields";
import { InputText, InputTextArea } from "../../../../components/InputFields";
import { closeModal } from "../../../../data/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import InputSearch from "../../../../components/InputFields/InputSearch/InputSearch";
import { useState } from "react";
import { Checkbox, Switch, message } from "antd";
import { createImageGroup, updateImageGroup } from "../../../../data/slices/groups";
import { getAllTemplates } from "../../../../data/slices/templates";
import { useTranslation } from "react-i18next";

const setImagesToForm = (formik, image) => {
  let images = formik.values["images"] || [];
  const imageIndex = images?.findIndex((img) => img === image?.name);
  if (imageIndex !== -1) images = images?.filter((_, i) => i !== imageIndex);
  else images = [...images, image?.name];
  formik.setFieldValue("images", images);
};

const imageGroupFormValidation = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .matches(/^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/, {
      message: "Invalid format. Use lowercase letters, digits, hyphens, and dots.",
      excludeEmptyString: true // Exclude error message when the field is empty
    })
    .required("This field is required"),
  description: Yup.string().min(2, "Too Short!").max(100, "Too Long!"),
  thumbnail: Yup.string()
});

function ImageGroupForm({ isEdit, id, group }) {
  const { t } = useTranslation("image-group");

  const { allTemplates } = useSelector((state) => state.templates);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [images, setImages] = useState(allTemplates);

  const onSearch = (value) => {
    setImages(allTemplates?.filter((image) => image?.displayName?.includes(value?.target?.value)));
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAllTemplates());
    }
  }, [isAuthenticated]);

  //ensure that the templates will be here after refreshing the page
  useEffect(() => {
    setImages(allTemplates);
  }, [allTemplates]);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: isEdit ? group?.displayName : "",
      description: isEdit ? group?.description : "",
      images: isEdit ? group?.images : []
    },
    validationSchema: imageGroupFormValidation,
    onSubmit: (values) => {
      if (!values?.images || values?.images?.length === 0) {
        message.error(t("image-group-21"));
        return;
      }
      if (formik.values.images.length === images.length) {
        values.images = ["*"];
      }
      if (!isEdit) {
        dispatch(createImageGroup({ ...values }))
          .unwrap()
          .then((res) => {
            message.success(t("image-group-22"));
            dispatch(closeModal("image-group"));
          })
          .catch((err) => {
            message.error(err.message || t("image-group-23"));
          });
      } else {
        dispatch(
          updateImageGroup({
            values,
            id
          })
        )
          .unwrap()
          .then(() => {
            message.success(t("image-group-24"));
            dispatch(closeModal("image-group"));
          })
          .catch((err) => {
            message.error(err.message || t("image-group-25"));
          });
      }
    }
  });

  const handleSelectAllImages = (checked) => {
    if (checked) {
      // formik.setFieldValue("images", ["*"]);
      let allImages = images.map((el) => el.name);
      formik.setFieldValue("images", allImages);
    } else {
      formik.setFieldValue("images", []);
    }
  };

  return (
    <div className="image_group_form form">
      <form action="" onSubmit={formik.handleSubmit}>
        {/* {fields?.map((field, i) => (
          <InputText formik={formik} field={field} key={i} />
        ))} */}

        <InputText
          formik={formik}
          field={{
            name: "name",
            placeholder: t("image-group-26"),
            label: t("image-group-27")
          }}
        />

        <InputTextArea
          formik={formik}
          field={{
            name: "description",
            placeholder: t("image-group-28"),
            label: t("image-group-29")
          }}
        />

        <InputSearch
          formik={formik}
          field={{
            name: "images",
            placeholder: t("image-group-30"),
            label: t("image-group-31"),
            type: "string"
          }}
          onSearch={onSearch}
          loading={false}
        />
        <div className="images images-group-container">
          {images?.map((image, i) => {
            return (
              <div className="images_item" key={i}>
                <div className="images_item-control">
                  <Checkbox
                    defaultChecked={!!formik.values["images"]?.includes(image?.name)}
                    onChange={(e) => setImagesToForm(formik, image)}
                  >
                    <div
                      className={`images_item-label ${
                        formik.values["images"]?.includes(image?.name)
                          ? "images_item-label-active"
                          : ""
                      }`}
                    >
                      <img src={image?.imageIconUrl || imageIcon} alt="error" />
                      <p>{image?.displayName}</p>
                      <img
                        src={selectedIcon}
                        alt="selected"
                        className="images_item-label-active-icon"
                      />
                    </div>
                  </Checkbox>
                </div>
              </div>
            );
          })}
        </div>

        <div className="select_all">
          <div className="title_item">
            {/* <img src={ldapIcon} alt="" /> */}
            <p>{t("image-group-32")}</p>
          </div>
          <div className="switch">
            <Switch
              checked={formik.values.images?.length === images?.length}
              onChange={(e) => handleSelectAllImages(e)}
            ></Switch>
          </div>
        </div>

        <div className="action_form">
          <button
            type="reset"
            onClick={() => {
              dispatch(closeModal("image-group"));
              formik.setValues({});
            }}
            className="dashboard-cancel-btn"
          >
            {t("image-group-33")}
          </button>
          <button type="submit" className="dashboard-confirm-btn">
            {t("image-group-34")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ImageGroupForm;

import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import { ReactComponent as UpgradePlanSVG } from "../../../assets/icons/upgrade-plan.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UpgradeModal = ({ id, open, handleClose, data = null, ...rest }) => {
  const { t } = useTranslation("index");

  const navigate = useNavigate();
  const { canManagePlans } = data || {};

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      className="ta-modal upgrade-modal"
    >
      <DialogContent className="upgrade-modal-content content_confirm">
        <UpgradePlanSVG />
        <p className="upgrade-modal-header">
          {canManagePlans ? t("home.UpgradeModal-1") : t("home.UpgradeModal-2")}
        </p>
        <p className="upgrade-modal-description">
          {canManagePlans ? t("home.UpgradeModal-3") : t("home.UpgradeModal-4")}
        </p>
      </DialogContent>
      <div className="form">
        <div className="action_form">
          {canManagePlans ? (
            <>
              <button className="dashboard-cancel-btn" onClick={() => handleClose(id)}>
                {t("home.UpgradeModal-5")}
              </button>
              <button
                type="submit"
                className="dashboard-confirm-btn"
                onClick={() => {
                  navigate("/admin/plans");
                  handleClose(id);
                }}
              >
                {t("home.UpgradeModal-6")}
              </button>
            </>
          ) : (
            <button type="submit" className="dashboard-cancel-btn" onClick={() => handleClose(id)}>
              {t("home.UpgradeModal-7")}
            </button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UpgradeModal;

import axios from "axios";
import { store } from "../data";
import { initialise, logout } from "../data/slices/authSlice";
import { closeAllModals } from "../data/slices/modals";
import { Config } from "../config";
//prometheus
export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json"
};
const ltiAxios = axios.create({
  baseURL: Config?.REACT_APP_API_URL,
  headers,
  withCredentials: true
});

//request interceptor to add the auth token header to requests
ltiAxios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("gomydesk_token");
    if (accessToken) {
      config.headers["X-Session-Token"] = accessToken;
    }
    config.headers["request-startTime"] = performance.now().toString();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (newAccessToken) => {
  refreshSubscribers.forEach((callback) => callback(newAccessToken));
  refreshSubscribers = [];
};

ltiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { dispatch } = store;

    const previousRequest = error?.config;
    if (error?.response?.status === 401 && !previousRequest?.sent) {
      previousRequest.sent = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const response = await ltiAxios.get("auth/refresh_token");
          const newAccessToken = response.data.token;
          localStorage.setItem("gomydesk_token", newAccessToken);
          previousRequest.headers["X-Session-Token"] = newAccessToken;

          const res = await ltiAxios.get("profile/whoami");
          const user = res.data;
          ltiAxios.defaults.headers["X-User-ID"] = user?.username;
          dispatch(initialise({ isAuthenticated: true, user }));

          isRefreshing = false;
          onRefreshed(newAccessToken);
          return ltiAxios(previousRequest);
        } catch (err) {
          isRefreshing = false;
          dispatch(closeAllModals());
          //   dispatch(logout());
          localStorage.removeItem("gomydesk_token");
        }
      } else {
        return new Promise((resolve) => {
          refreshSubscribers.push((newAccessToken) => {
            previousRequest.headers["X-Session-Token"] = newAccessToken;
            resolve(ltiAxios(previousRequest));
          });
        });
      }
    }
    return Promise.reject((error.response && error.response.data) || "Something went wrong");
  }
);

export default ltiAxios;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  currentData: [],
  categories: [],
  state: "idle",
  error: null
};

//get grouped templates
export const getGroupedTemplates = createAsyncThunk("api/templates-grouped", async () => {
  let data;
  try {
    const response = await axios.get(`/templates?isGrouped=true`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const getCategoriesTemplates = createAsyncThunk("api/categories-template", async () => {
  let data;
  try {
    const response = await axios.get(`/templates-categories`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    resetCategoriesData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    //get grouped templates
    [getGroupedTemplates.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getGroupedTemplates.fulfilled]: (state, action) => {
      const groupedTemplates = action.payload;
      state.currentData = groupedTemplates;
      state.state = "success";
    },
    [getGroupedTemplates.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    [getCategoriesTemplates.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getCategoriesTemplates.fulfilled]: (state, action) => {
      state.categories = action.payload;
      state.state = "success";
    },
    [getCategoriesTemplates.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    }
  }
});

export const { setCurrentData, resetCategoriesData } = categoriesSlice.actions;

export default categoriesSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import axiosInstance from "../../utils/axios";

const initialState = {
  resources_verbs: null,
  error: null,
  loading: null
};

export const getResourcesForCreateRole = createAsyncThunk(
  "api/get-resources-for-create",
  async () => {
    let data;
    try {
      const response = await axiosInstance.get(`/roles/rbac/actions`);

      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const resourcesForCreateSlice = createSlice({
  name: "resources_for_create",
  initialState,
  reducers: {
    resetresourcesForCreateSliceData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    [getResourcesForCreateRole.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getResourcesForCreateRole.fulfilled]: (state, action) => {
      state.resources_verbs = action.payload.actions;
      state.loading = false;
    },
    [getResourcesForCreateRole.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    }
  }
});

export const { resetresourcesForCreateSliceData } = resourcesForCreateSlice.actions;

export default resourcesForCreateSlice.reducer;

import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { refreshToken } from "../../../data/slices/authSlice";

const useServerSentEvents = (url) => {
  const [data, setData] = useState(null);
  const eventSourceRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    // Establish SSE connection
    eventSourceRef.current = new EventSource(url);

    // Event listeners
    eventSourceRef.current.addEventListener("open", (event) => {
      console.log("SSE connection opened", event);
    });

    eventSourceRef.current.addEventListener("error", (error) => {
      console.error("SSE error:", error);
      //   dispatch(refreshToken()).then(() => {});
    });

    eventSourceRef.current.addEventListener("DELETE_SESSION_EVENT", (event) => {
      setData(JSON.parse(event.data));
    });

    return () => {
      // Clean up: close SSE connection on component unmount
      eventSourceRef.current.close();
    };
  }, [url]);

  return data;
};

export default useServerSentEvents;

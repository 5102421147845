import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import axiosInstance from "../../utils/axios";

const initialState = {
  plans: null,
  offers: null,
  error: null,
  loading: null,
  checkoutLoading: null,
  requestQuoteLoading: null
};

export const getOffers = createAsyncThunk("api/get-offers", async ({ query }) => {
  let data;
  try {
    const response = await axiosInstance.get(`/plans/org`);

    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    return null;
    // throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error?.message : data?.message);
  }
});

export const checkoutSession = createAsyncThunk("api/checkout-session", async (values) => {
  let data;
  try {
    const response = await axiosInstance.post(`payment/stripe/checkout-session`, values);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    return null;
    // throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const RequestQuote = createAsyncThunk("api/request-quote", async (values) => {
  let data;
  try {
    const response = await axiosInstance.post(`/tickets`, values);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    return null;
    // throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.error ? error.error : error);
  }
});

export const offersSlice = createSlice({
  name: "offersSlice",
  initialState,
  reducers: {
    resetoffersSliceData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    [getOffers.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getOffers.fulfilled]: (state, action) => {
      state.offers = action.payload.Plans;
      state.loading = false;
    },
    [getOffers.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    //checkout
    [checkoutSession.pending]: (state) => {
      state.error = null;
      state.checkoutLoading = true;
    },
    [checkoutSession.fulfilled]: (state, action) => {
      state.checkoutLoading = false;
    },
    [checkoutSession.rejected]: (state, action) => {
      state.error = false;
      state.checkoutLoading = false;
    },
    //request a quote
    [RequestQuote.pending]: (state) => {
      state.error = null;
      state.requestQuoteLoading = true;
    },
    [RequestQuote.fulfilled]: (state, action) => {
      state.requestQuoteLoading = false;
    },
    [RequestQuote.rejected]: (state, action) => {
      state.error = false;
      state.requestQuoteLoading = false;
    }
  }
});

export const { resetoffersSliceData } = offersSlice.actions;

export default offersSlice.reducer;

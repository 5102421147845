import { UpCircleOutlined } from "@ant-design/icons";
import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  headerTitle: "",
  userTabs: {
    activeTab: "1",
    tabs: [
      {
        value: "1",
        label: `Active`
      },
      {
        value: "2",
        label: `Pending`
      }
    ]
  },
  userDetailsTabs: {
    activeTab: "0",
    type: "details",
    tabs: [
      {
        key: "0",
        label: `User Properties`
      }
      // {
      //   value: "1",
      //   label: `User Settings`,
      // },
    ]
  },

  imageTabs: {
    activeTab: "0",
    tabs: [
      {
        value: "0",
        label: `Image`
      },
      {
        value: "1",
        label: `Group`
      }
    ]
  },

  groupDetailsTabs: {
    activeTab: "0",
    type: "details",

    tabs: [
      {
        key: "0",
        label: `Group Properties`
      }
      // {
      //   value: "1",
      //   label: `Group Settings`,
      // },
    ]
  },
  imageDetailsTabs: {
    activeTab: "0",
    type: "details",
    tabs: [
      {
        key: "0",
        label: `Image Properties`
      }
      // {
      //   value: "1",
      //   label: `Image Settings`,
      // },
    ]
  },
  notificationTabs: {
    activeTab: "0",
    type: "details",
    tabs: [
      {
        key: "0",
        label: `All`
      },
      {
        key: "1",
        label: `Unread`
      },
      {
        key: "2",
        label: `Archived`
      }
    ]
  }
};

export const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    changeTab: (state, action) => {
      state.userTabs.activeTab = action.payload;
    },
    setHeaderTitle: (state, action) => {
      state.headerTitle = action.payload;
    },
    changeUserDetailsTab: (state, action) => {
      state.userDetailsTabs.activeTab = action.payload;
    },
    changeImageTab: (state, action) => {
      state.imageTabs.activeTab = action.payload;
    },
    changeGroupDetailsTab: (state, action) => {
      state.groupDetailsTabs.activeTab = action.payload;
    },
    changeImageDetailsTab: (state, action) => {
      state.groupDetailsTabs.activeTab = action.payload;
    },
    changeNotifTab: (state, action) => {
      state.notificationTabs.activeTab = action.payload;
    }
  }
});

export const {
  changeTab,
  changeUserDetailsTab,
  changeImageTab,
  changeGroupDetailsTab,
  setHeaderTitle,
  changeImageDetailsTab,
  changeNotifTab
} = tabsSlice.actions;

export default tabsSlice.reducer;

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { getUsers } from "../../../../data/slices/memberSlice";
import { InputSelect, InputText } from "../../../../components/InputFields";
import { closeModal } from "../../../../data/slices/modals";
import { Button, Input, Switch, message } from "antd";
import { createNetworkPolicy, updateNetwork } from "../../../../data/slices/networkPolicy";
import { getTemplates } from "../../../../data/slices/templates";
import TagInput from "../InputTag/InputTag";
import ActionType from "../ActionType/ActionType";
import { useTranslation } from "react-i18next";

function NetworkPolicyForm({ isEdit, id, network }) {
  const { t } = useTranslation("policy");
  const dispatch = useDispatch();
  const { members } = useSelector((state) => state.member);
  const { templates } = useSelector((state) => state.templates);
  const formik = useFormik({
    initialValues: {
      templates: [],
      users: [],
      hosts: [],
      name: "",
      enabled: true
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(2, t("policy-13")).max(70, t("policy-14")).required(t("policy-15")),
      hosts: Yup.array()
        .of(Yup.string().required(t("policy-16")))
        .min(1, t("policy-17"))
        .required(),
      users: Yup.array().min(1, t("policy-18")).required(),
      templates: Yup.array().min(1, t("policy-19")).required()
    }),
    onSubmit: (values) => {
      if (!isEdit) {
        dispatch(createNetworkPolicy(values))
          .unwrap()
          .then((res) => {
            message.success(t("policy-20"));
            dispatch(closeModal("network-policy-modal"));
          })
          .catch((err) => {
            message.error(err.message || "something went wrong");
          });
      } else {
        dispatch(updateNetwork({ values, id }))
          .unwrap()
          .then((res) => {
            message.success(t("policy-21"));
          })
          .catch((err) => {
            message.error(err.message || "something went wrong");
          });
      }
    }
  });
  useEffect(() => {
    if (isEdit) {
      dispatch(getUsers({ query: "" }));
      dispatch(getTemplates({ query: "" }));

      formik.setValues({
        templates: network?.templates,
        users: network?.users,
        hosts: network?.hosts,
        name: network?.name,
        enabled: network?.enabled
      });
    }
  }, [network]);
  console.log(formik);

  return (
    <div className="form_network">
      <form className="form" action="" onSubmit={formik.handleSubmit}>
        <InputText
          formik={formik}
          field={{
            name: "name",
            type: "string",
            placeholder: t("policy-29"),
            label: t("policy-22")
          }}
        />

        <div className="hosts">
          <TagInput
            placeholder={t("policy-23")}
            value={formik.values.hosts}
            onChange={(value) => formik.setFieldValue("hosts", value)}
            formik={formik}
          />
          {formik.submitCount > 0 && <p className="red_msg">{formik.errors.hosts}</p>}
        </div>
        <ActionType />
        <div className="select_users">
          <p className={"big_title"}>{t("policy-24")}</p>
        </div>
        <InputSelect
          label={t("policy-25")}
          allowClear
          formik={formik}
          options={members?.map((item) => ({
            label: item?.fullName,
            value: item?.username
          }))}
          defaultOptions={isEdit ? network?.users : formik?.values?.users}
          onChange={(e) => {
            formik.setFieldValue("users", e);
          }}
          field="roles"
          onDropdownVisibleChange={() => {
            dispatch(getUsers({ query: "" }));
          }}
        />
        {formik.errors.users && formik.submitCount > 0 && (
          <p className="red_msg_top">{formik.errors.users}</p>
        )}

        <InputSelect
          label={t("policy-26")}
          formik={formik}
          allowClear
          options={templates?.data?.map((item) => ({
            label: item?.displayName,
            value: item?.name
          }))}
          defaultOptions={isEdit ? network?.templates : formik?.values?.templates}
          onChange={(e) => {
            formik.setFieldValue("templates", e);
          }}
          field="roles"
          onDropdownVisibleChange={() => {
            dispatch(getTemplates({ query: "" }));
          }}
        />
        {formik.errors.templates && formik.submitCount > 0 && (
          <p className="red_msg_top">{formik.errors.templates}</p>
        )}

        {/* <div className="enabled-switch">
          <label htmlFor="enabled">Enabled</label>
          <Switch
            id="enabled"
            checked={formik.values.enabled}
            onChange={(checked) => formik.setFieldValue("enabled", checked)}
          />
        </div> */}
        <div className="action_form">
          <button
            onClick={() => {
              dispatch(closeModal("network-policy-modal"));
              formik.setValues({});
            }}
            className="dashboard-cancel-btn"
          >
            {t("policy-27")}
          </button>
          <button type="submit" className="dashboard-confirm-btn">
            {t("policy-28")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default NetworkPolicyForm;

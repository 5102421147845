import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { message } from "antd";

const initialState = {
  groups: null,
  group: null,
  reservedResources: null,
  metadata: null,
  isLoading: false
};

export const getQuotaUserGroups = createAsyncThunk("api/quota-user-groups", async ({ query }) => {
  let data;
  try {
    const response = await axiosInstance.get(`/user-groups`, { params: query });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const selectActiveQuotaGroup = createAsyncThunk("api/select-group", async ({ id }) => {
  let data;
  try {
    const response = await axiosInstance.post(`/user-groups/${id}/select`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    console.log({ error });
    message.error(error?.error);
    return Promise.reject(error.message ? error.message : err);
  }
});

export const getQuotaUserGroup = createAsyncThunk("api/quota-user-group", async ({ id }) => {
  let data;
  try {
    const response = await axiosInstance.get(`/user-groups/${id}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const createQuotaUserGroup = createAsyncThunk(
  "api/create-quota-user-group",
  async (values, thunkApi) => {
    let data;
    try {
      const response = await axiosInstance.post(`/user-groups`, {
        ...values
      });
      data = await response.data;
      if (response.status === 200) {
        thunkApi.dispatch(getQuotaUserGroups({}));
        thunkApi.dispatch(getPlanReservedResources({}));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : error.error);
    }
  }
);

export const updateQuotaUserGroup = createAsyncThunk(
  "api/update-quota-user-group",
  async ({ id, ...body }, thunkApi) => {
    let data;
    try {
      const response = await axiosInstance.put(`/user-groups/${id}`, body);
      data = await response.data;
      if (response.status === 200) {
        thunkApi.dispatch(getQuotaUserGroups({ query: "" }));
        thunkApi.dispatch(getQuotaUserGroup({ id }));
        thunkApi.dispatch(getPlanReservedResources({}));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : error.error);
    }
  }
);

export const updateUserConsumption = createAsyncThunk(
  "api/update-user-consumption",
  async ({ id, ...body }, thunkApi) => {
    let data;
    try {
      const response = await axiosInstance.put(`/user-groups/${id}`, body);
      data = await response.data;
      if (response.status === 200) {
        thunkApi.dispatch(getQuotaUserGroups({ query: "" }));
        thunkApi.dispatch(getQuotaUserGroup({ id }));
        thunkApi.dispatch(getPlanReservedResources({}));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : error.error);
    }
  }
);

export const deleteQuotaUserGroup = createAsyncThunk(
  "api/delete-quota-user-group",
  async ({ id }, thunkApi) => {
    let data;
    try {
      const response = await axiosInstance.delete(`/user-groups/${id}`);
      data = await response.data;
      if (response.status === 200) {
        thunkApi.dispatch(getQuotaUserGroups({}));
        thunkApi.dispatch(getPlanReservedResources({}));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : error.error);
    }
  }
);

export const getPlanReservedResources = createAsyncThunk(
  "api/get-plan-reserved-resources",
  async ({ query }, thunkApi) => {
    let data;
    try {
      const response = await axiosInstance.get(`/consumption/reserved`, query);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : error.error);
    }
  }
);

export const quotaManagementSlice = createSlice({
  name: "quotaManagement",
  initialState,
  reducers: {},
  extraReducers: {
    [getQuotaUserGroups.pending]: (state) => {
      state.isLoading = true;
    },
    [getQuotaUserGroups.fulfilled]: (state, action) => {
      state.groups = action.payload?.userGroups;
      state.isLoading = false;
    },
    [getQuotaUserGroups.rejected]: (state) => {
      state.isLoading = false;
    },
    [getQuotaUserGroup.pending]: (state) => {
      state.isLoading = true;
    },
    [getQuotaUserGroup.fulfilled]: (state, action) => {
      state.group = action.payload;
      state.isLoading = false;
    },
    [getQuotaUserGroup.rejected]: (state) => {
      state.isLoading = false;
    },
    [getPlanReservedResources.pending]: (state) => {
      state.isLoading = true;
    },
    [getPlanReservedResources.fulfilled]: (state, action) => {
      state.reservedResources = action.payload;
      state.isLoading = false;
    },
    [getPlanReservedResources.rejected]: (state) => {
      state.isLoading = false;
    },
    [createQuotaUserGroup.pending]: (state) => {
      state.isLoading = true;
    },
    [createQuotaUserGroup.fulfilled]: (state, action) => {
      state.groups = [...state.groups, action.payload];
      state.isLoading = false;
    },
    [createQuotaUserGroup.rejected]: (state) => {
      state.isLoading = false;
    },

    [deleteQuotaUserGroup.fulfilled]: (state, action) => {
      console.log(action.payload);
      // state.groups = [...state.groups, action.payload];
      state.isLoading = false;
    },

    [updateQuotaUserGroup.pending]: (state) => {
      state.isLoading = true;
    },
    [updateQuotaUserGroup.fulfilled]: (state, action) => {
      state.groups = state.groups?.map((group) =>
        group?.name === action.payload?.name ? action.payload : group
      );
      state.isLoading = false;
    },
    [updateQuotaUserGroup.rejected]: (state) => {
      state.isLoading = false;
    }
  }
});

export const {} = quotaManagementSlice.actions;

export default quotaManagementSlice.reducer;

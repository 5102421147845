import { useFormik } from "formik";
import React, { useEffect } from "react";
import { closeModal } from "../../../data/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { InputText, InputTextArea } from "../../InputFields";
import { Rate, message } from "antd";
import ControllBar from "../ControllBar/ControllBar";
import { store } from "../../../data";
import { createFeedback, deleteMedia, saveData } from "../../../data/slices/feedback";
import { ReactComponent as DeleteIcon } from "./../../../assets/icons/trash.svg";
import { ReactComponent as DownloadIcon } from "./../../../assets/icons/fi-rr-download (1).svg";
import { ReactComponent as PreviewIcon } from "./../../../assets/icons/fi-rr-eye.svg";
import { dataURItoBlob } from "../../../helpers/dataURIToBlob";

const uploadFiles = async (files) => {
  const uploadPromises = files.map(async (file) => {
    const url = await uploadFile(file);
    return url;
  });

  const urls = await Promise.all(uploadPromises);
  return urls;
};

const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "ph4slroc");

  try {
    const response = await fetch("https://api.cloudinary.com/v1_1/dj6kxvxqb/image/upload", {
      method: "POST",
      body: formData
    });
    const data = await response.json();
    const imageURL = data?.secure_url;
    return imageURL;
  } catch (error) {
    console.error(error);
  }
};

const getFiles = (media) => {
  return media.map((file) => {
    let rt = null;
    if (file?.type === "screenshot") {
      rt = dataURItoBlob(file.blobURL);
    } else rt = file.file;
    return rt;
  });
};

const getMediaName = (media) => {
  if (media.type === "attachement") {
    return media.file.name;
  } else {
    return media.type;
  }
};

const openPreview = (media) => {
  window.open(media.blobURL, "_blank");
};

const removeMedia = (file) => {
  store.dispatch(deleteMedia(file.id));
};

const downloadFile = (media) => {
  const a = document.createElement("a");
  a.href = media?.blobURL;
  a.download = "gomydesk_media.png";
  a.click();
};

const feedbackTypeMsg = {
  1: "Tell us what's broken",
  2: "Tell us how we can improve",
  3: "How would you rate your experience"
};

function FeedbackForm({ type }) {
  const { media, data, loading } = useSelector((state) => state.feedback);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  console.log(data);
  const formik = useFormik({
    initialValues: {
      title: data[type]?.title || "",
      email: data[type]?.email || user?.email,
      comment: data[type]?.comment || ""
      // rate: data[type]?.rate || 0
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("This field is required"),
      email: Yup.string().required("This field is required"),
      comment: Yup.string().required("This field is required")
    }),
    onSubmit: async (values) => {
      let feedbackType = "bug";
      if (type === 3) feedbackType = "feedback";
      else if (type === 2) feedbackType = "feature";
      const files = getFiles(media.filter((media) => media.feedbackType === type));
      const urls = await uploadFiles(files);
      console.log({ urls });
      console.log({ files });
      // const
      // uploadFile(files[0]);
      dispatch(
        createFeedback({
          ...values,
          attachementURL: urls[0],
          email: "",
          feedbackType
        })
      ).then((res) => {
        if (res.error) {
          message.error("Error sending feedback.");
        } else {
          dispatch(closeModal("feedback-modal"));
          message.success("Feedback sent successfully");
        }
      });
    }
  });
  const groupedMedia = media?.reduce((group, media) => {
    const { feedbackType } = media;
    group[feedbackType] = group[feedbackType] ?? [];
    group[feedbackType].push(media);
    return group;
  }, {});
  useEffect(() => {
    dispatch(saveData({ data: formik.values, type }));
  }, [formik.values]);

  return (
    <div className="form_user form_feedback">
      <p className="help-message">{feedbackTypeMsg[type]}</p>
      <form action="" onSubmit={formik.handleSubmit}>
        {type === 3 ? (
          <div className="rate">
            <Rate
              onChange={(e) => {
                if (formik.values) {
                  formik.setFieldValue("rate", e);
                }
              }}
              defaultValue={formik.values.rate}
            />
          </div>
        ) : null}
        <InputText
          formik={formik}
          field={{
            name: "title",
            type: "string",
            placeholder: "Add a title"
          }}
        />
        <InputText
          formik={formik}
          field={{
            name: "email",
            type: "string",
            placeholder: "Please enter your email"
          }}
        />
        <InputTextArea
          formik={formik}
          field={{
            name: "comment",
            type: "string",
            placeholder: "Describe the bug in detail..."
          }}
        />
        <ControllBar />
        <div className="media-container">
          {groupedMedia[type]?.map((file, i) => {
            const name = getMediaName(file);
            const { icons } = file;
            return (
              <div className="media-item" key={i}>
                <div className="media-item-s1">
                  <div className="media-item-preview">
                    <img src={file.blobURL} />
                  </div>
                  <p>{name}</p>
                </div>
                <div className="media-item-s2">
                  {icons?.includes("P") && (
                    <span onClick={() => openPreview(file)}>
                      <PreviewIcon />
                    </span>
                  )}
                  {icons?.includes("D") && (
                    <span onClick={() => downloadFile(file)}>
                      <DownloadIcon />
                    </span>
                  )}
                  <span onClick={() => removeMedia(file)}>
                    <DeleteIcon />
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="action_form">
          <button
            onClick={() => {
              dispatch(closeModal("feedback-modal"));
              formik.setValues({});
            }}
            type="reset"
            className="dashboard-cancel-btn"
          >
            Cancel
          </button>
          <button type="submit" className="dashboard-confirm-btn">
            {loading ? "Loading..." : "Confirm"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default FeedbackForm;

// export function ResizableDraggablePopup() {
//   const popupRef = useRef(null);
//   const [currentZIndex, setCurrentZIndex] = useState(100);
//   const [dragging, setDragging] = useState(false);
//   const [resizing, setResizing] = useState(false);
//   const [startX, setStartX] = useState(0);
//   const [startY, setStartY] = useState(0);
//   const [startWidth, setStartWidth] = useState(200); // Initial width
//   const [startHeight, setStartHeight] = useState(150); // Initial height

//   const handleDragStart = (e) => {
//     const rect = popupRef.current.getBoundingClientRect();
//     setDragging(true);
//     setCurrentZIndex(currentZIndex + 1);
//     setStartX(e.clientX - rect.left);
//     setStartY(e.clientY - rect.top);
//   };

//   const handleDragMove = (e) => {
//     if (!dragging) return;

//     const newX = e.clientX - startX;
//     const newY = e.clientY - startY;

//     popupRef.current.style.left = newX + "px";
//     popupRef.current.style.top = newY + "px";
//   };

//   const handleDragEnd = () => {
//     setDragging(false);
//   };

//   const handleResizeStart = (e) => {
//     setResizing(true);
//     setCurrentZIndex(currentZIndex + 1);
//     setStartX(e.clientX);
//     setStartY(e.clientY);
//     setStartWidth(popupRef.current.offsetWidth);
//     setStartHeight(popupRef.current.offsetHeight);
//   };

//   const handleResizeMove = (e) => {
//     if (!resizing) return;

//     const newWidth = startWidth + e.clientX - startX;
//     const newHeight = startHeight + e.clientY - startY;

//     popupRef.current.style.width = newWidth + "px";
//     popupRef.current.style.height = newHeight + "px";
//   };

//   const handleResizeEnd = () => {
//     setResizing(false);
//   };

//   return (
//     <div
//       className="drag resizable"
//       ref={popupRef}
//       style={{ zIndex: currentZIndex }}
//       onMouseDown={handleDragStart}
//       onMouseMove={handleDragMove}
//       onMouseUp={handleDragEnd}
//     >
//       <div className="drag-header" onMouseDown={handleResizeStart}>
//         Drag from anywhere to move, drag bottom-right corner to resize
//       </div>
//     </div>
//   );
// }

// const media = [{ blobUrL: "", RESTOFDATA: "", type: "record" | "screenshot" | "file" }];

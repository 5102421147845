const Table = ({ headerColumns = null, bodyRows = null }) => {
  return (
    <div className="responsive_container">
      <div className="table_container">
        <div className="table__header">{headerColumns}</div>
        {bodyRows}
      </div>
    </div>
  );
};

export default Table;

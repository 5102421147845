import { format, getTime, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------

/**
 * Formats a date.
 * @param {InputValue} date - The input date.
 * @param {string} newFormat - The format for the output date (optional).
 * @returns {string} - The formatted date.
 */
export function fDate(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy";

  return date ? format(new Date(date), fm) : "";
}

/**
 * Formats a date and time.
 * @param {InputValue} date - The input date.
 * @param {string} newFormat - The format for the output date and time (optional).
 * @returns {string} - The formatted date and time.
 */
export function fDateTime(date, newFormat) {
  if (
    navigator.userAgent.indexOf("Safari") !== -1 &&
    navigator.userAgent.indexOf("Chrome") === -1
  ) {
    return date;
  }

  const fm = newFormat || "dd MMM yyyy p";
  return String(date ? format(new Date(date), fm) : "");
}

/**
 * Gets the timestamp of a date.
 * @param {InputValue} date - The input date.
 * @returns {number} - The timestamp of the date.
 */
export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : "";
}

/**
 * Formats a date to a human-readable relative time (e.g., "2 hours ago").
 * @param {InputValue} date - The input date.
 * @returns {string} - The formatted relative time.
 */
export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true
      })
    : "";
}

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeDrawer } from "../../data/slices/modals";
import ImageDetails from "../../features/ImageGroup/components/ImageDetails/ImageDetails";
import ImageGroupDetails from "../../features/ImageGroup/components/ImageGroupDetails";
import GroupDetails from "../../features/UserGroup/components/GroupDetails";
import UserDetails from "../../features/Users/components/UserDetails/UserDetails";
import DrawerTable from "../Drawer/Drawer";
import NotificationsDrawer from "../../features/Notifications/components/NotificationsDrawer/NotificationsDrawer";

const DrawersProvider = (props) => {
  const { drawers } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const modalState = (id, key) => {
    const res = drawers.find((modal) => modal.id === id);
    return res[key];
  };
  const handleClose = (id) => {
    dispatch(closeDrawer(id));
  };
  return (
    <>
      <DrawerTable
        id="drawer-example"
        open={modalState("drawer-example", "open")}
        data={modalState("drawer-example", "data")}
        handleClose={handleClose}
      />
      <UserDetails
        id="drawer-user"
        open={modalState("drawer-user", "open")}
        data={modalState("drawer-user", "data")}
        handleClose={handleClose}
      />
      <GroupDetails
        id="drawer-user-group"
        open={modalState("drawer-user-group", "open")}
        data={modalState("drawer-user-group", "data")}
        handleClose={handleClose}
      />
      <ImageGroupDetails
        id="drawer-image-group"
        open={modalState("drawer-image-group", "open")}
        data={modalState("drawer-image-group", "data")}
        handleClose={handleClose}
      />
      <ImageDetails
        id="drawer-image"
        open={modalState("drawer-image", "open")}
        data={modalState("drawer-image", "data")}
        handleClose={handleClose}
      />
      <NotificationsDrawer
        id="drawer-notification"
        open={modalState("drawer-notification", "open")}
        data={modalState("drawer-notification", "data")}
        handleClose={handleClose}
      />
    </>
  );
};

export default DrawersProvider;

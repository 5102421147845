import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialiseMembers = (users) => {
  return users.map((member) => {
    return { ...member, checked: false };
  });
};

const initialState = {
  networkPolicies: [],
  searchItems: [],
  meta: {},
  network: {},
  state: "idle",
  error: null,
  message: null,
  loading: null,
  searchString: ""
};

//get networks
export const getNetworkPolicies = createAsyncThunk("api/network-policies", async ({ query }) => {
  let data;
  try {
    const response = await axios.get(`/network-policies${query}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const createNetworkPolicy = createAsyncThunk(
  "api/crate-network",
  async (values, thunkApi) => {
    let data;
    try {
      const response = await axios.post(`/network-policies`, { ...values });
      data = await response.data;
      if (response.status === 200) {
        thunkApi.dispatch(getNetworkPolicies({ query: "?page=1&limit=8" }));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;

      return Promise.reject(error.message ? error.message : error.error);
    }
  }
);
// delete network policy
export const deleteNetworkPolicy = createAsyncThunk("api/delete-network", async (id, thunkApi) => {
  let data;
  try {
    const response = await axios.delete(`/network-policies/${id}`);
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getNetworkPolicies({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

// get one network policy
export const getNetworkPolicy = createAsyncThunk("api/get-network-policy", async (id) => {
  let data;
  try {
    const response = await axios.get(`//network-policies/${id}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

// edit network policy
export const updateNetwork = createAsyncThunk("api/edit-network", async (query, thunkApi) => {
  let data;
  let { values, id } = query;
  try {
    const response = await axios.put(`/network-policies/${id}`, { ...values });

    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getNetworkPolicies({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const networkPolicySlice = createSlice({
  name: "networkPolicy",
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.networkPolicies = current(state).networkPolicies.map((member) => {
        return { ...member, checked: action.payload };
      });
    },
    selectById: (state, action) => {
      state.networkPolicies = current(state).networkPolicies.map((member) => {
        if (member.name === action.payload.id) return { ...member, checked: action.payload.value };
        return member;
      });
    },

    setSearchString: (state, action) => {
      state.searchString = action.payload;
    },
    resetnetworkPolicySliceData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    //get network policies
    [getNetworkPolicies.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getNetworkPolicies.fulfilled]: (state, action) => {
      const networks = action.payload;
      const newData = initialiseMembers(networks);
      state.networkPolicies = newData;
      state.searchItems = newData;
      state.meta = action.payload.meta;
      state.state = "success";
    },
    [getNetworkPolicies.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },

    //create network policy
    [createNetworkPolicy.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [createNetworkPolicy.fulfilled]: (state, action) => {
      state.state = "success";
    },
    [createNetworkPolicy.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    //get network policy
    [getNetworkPolicy.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getNetworkPolicy.fulfilled]: (state, action) => {
      const network = action.payload;
      state.state = "success";
      state.network = network;
    },
    [getNetworkPolicy.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    // update Network Policy
    [updateNetwork.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [updateNetwork.fulfilled]: (state, action) => {
      state.state = "success";
    },
    [updateNetwork.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    }
  }
});

export const { selectAll, selectById, setUsers, setSearchString, resetnetworkPolicySliceData } =
  networkPolicySlice.actions;

export default networkPolicySlice.reducer;

import { Ability } from "@casl/ability";
import { ReactComponent as ShowIcon } from "../../../../assets/icons/fi-rr-eye.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/fi-rr-pencil.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/fi-rr-trash.svg";
import { Can } from "../../../../context/permissions/Can";

const TableActions = ({
  onClickShowAction = null,
  onClickEditAction = null,
  onClickDeleteAction = null,
  ability = new Ability(),
  type
}) => {
  return (
    <div className="table_actions">
      {onClickShowAction && (
        <div className="icon_container" onClick={() => onClickShowAction()}>
          <ShowIcon />
        </div>
      )}
      <Can I="update" a={ability}>
        {onClickEditAction && (
          <div className="icon_container" onClick={() => onClickEditAction()}>
            <EditIcon />
          </div>
        )}
      </Can>
      {type === "network-policy" && (
        <>
          {/* TODO: add the ability key for the network delete  */}
          {/* <Can I="delete" a={ability}> */}
          {onClickDeleteAction && (
            <div className="icon_container" onClick={() => onClickDeleteAction()}>
              <DeleteIcon />
            </div>
          )}
          {/* </Can> */}
        </>
      )}

      {onClickDeleteAction && (
        <Can I="delete" a={ability}>
          {onClickDeleteAction && (
            <div className="icon_container" onClick={() => onClickDeleteAction()}>
              <DeleteIcon />
            </div>
          )}
        </Can>
      )}
    </div>
  );
};

export default TableActions;

import { Alert, Button, Divider, Space, Spin, Steps, message } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import InputText from "./InputText";
import { CheckSquareOutlined, CopyOutlined, QrcodeOutlined } from "@ant-design/icons";
import { updateOrgProfile } from "../../../../data/slices/memberSlice";
import {
  getOrgTokenDomain,
  getProfileOrg,
  verifyOrgTokenDomain
} from "../../../../data/slices/authSlice";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

const OrgDomain = () => {
  const { t } = useTranslation("profile");
  const dispatch = useDispatch();
  const { profileOrg } = useSelector((state) => state.auth);
  const [currentStep, setCurrentStep] = useState(0);
  const [errorVerification, setErrorVerification] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: profileOrg?.name,
      domain: profileOrg?.domain
    },
    validationSchema: Yup.object().shape({
      domain: Yup.string().required(t("profile.Profile-19"))
    })
  });

  useEffect(() => {
    if (profileOrg?.token) {
      setCurrentStep(1);
    }
    if (profileOrg?.isDomainVerified) {
      setCurrentStep(2);
    }
  }, []);

  useEffect(() => {
    if (!formik.values.name) {
      formik.setFieldValue("name", profileOrg?.name);
      formik.setFieldValue("domain", profileOrg?.domain);
      if (profileOrg?.token) {
        setCurrentStep(1);
      }
      if (profileOrg?.isDomainVerified) {
        setCurrentStep(2);
      }
    }
  }, [profileOrg?.name]);

  const generateToken = () => {
    setErrorVerification(false);

    if (profileOrg?.isDomainVerified) {
      setCurrentStep(2);
      return;
    }

    dispatch(
      getOrgTokenDomain({
        domain: formik.values?.domain
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          updateOrgProfile({
            values: { doamin: formik.values?.domain, orgName: formik.values.name }
          })
        );
        setCurrentStep(1);
      })
      .catch((err) => {});
  };

  const verifyToken = () => {
    if (profileOrg?.isDomainVerified) {
      setCurrentStep(3);
      setErrorVerification(false);
      return;
    }
    dispatch(
      verifyOrgTokenDomain({
        domain: profileOrg?.domain
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getProfileOrg());
        setCurrentStep(3);
      })
      .catch((err) => {
        setErrorVerification(true);
      });
  };

  if (!profileOrg?.name) {
    return <Spin />;
  }

  return (
    <div className="ProfileV2-page-col-2-form-org-domain">
      <p className="ProfileV2-page-col-2-form-org-domain-title">{t("profile.Profile-20")}</p>

      <Steps
        // size="small"
        current={currentStep}
        labelPlacement="vertical"
        items={[
          {
            title: t("profile.Profile-21"),
            description: t("profile.Profile-22")
          },
          {
            title: t("profile.Profile-23"),
            description: t("profile.Profile-24")
          },
          {
            title: t("profile.Profile-25"),
            description: t("profile.Profile-26")
          }
        ]}
      />

      <div className="ProfileV2-page-col-2-form-input">
        <div className="ProfileV2-page-col-2-form-input-width">
          <InputText
            formik={formik}
            field={{
              name: "domain",
              type: "text",
              placeholder: t("profile.Profile-27"),
              label: t("profile.Profile-28")
            }}
            disabled={currentStep !== 0}
            className={`personal-info-input personal-info-input-domain ${
              currentStep !== 0 ? "personal-info-input-disabled" : ""
            }`}
            suffix={
              currentStep === 0 ? (
                <Button htmlType="button" onClick={generateToken}>
                  <div className="personal-info-input-btn">
                    <QrcodeOutlined /> &nbsp; {t("profile.Profile-29")}
                  </div>
                </Button>
              ) : (
                currentStep === 1 && (
                  <Button htmlType="button" onClick={verifyToken}>
                    <div className="personal-info-input-btn">
                      <CheckSquareOutlined /> &nbsp; {t("profile.Profile-30")}
                    </div>
                  </Button>
                )
              )
            }
          />
        </div>
      </div>

      <div className="ProfileV2-page-col-2-form-alert-container">
        {errorVerification && (
          <Alert
            message={t("profile.Profile-31")}
            type="error"
            showIcon
            closable
            className="ProfileV2-page-col-2-form-alert"
            onClose={() => {
              setErrorVerification(false);
            }}
          />
        )}

        {!errorVerification && profileOrg?.token && currentStep === 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "22px"
            }}
          >
            <p style={{ fontWeight: "500" }}>{t("profile.Profile-32")}</p>

            <Alert
              message={`${profileOrg?.token}`}
              type="info"
              showIcon
              className="ProfileV2-page-col-2-form-alert"
              action={
                <Space>
                  <Divider type="vertical"></Divider>

                  <CopyToClipboard text={profileOrg?.token}>
                    <CopyOutlined onClick={() => message.info(t("profile.Profile-13"))} />
                  </CopyToClipboard>
                </Space>
              }
            />
          </div>
        )}

        {currentStep === 2 && (
          <Alert
            message={t("profile.Profile-33")}
            type="success"
            showIcon
            className="ProfileV2-page-col-2-form-alert"
          />
        )}
      </div>
    </div>
  );
};

export default OrgDomain;

import React, { useState } from "react";
import { WarningOutlined } from "@ant-design/icons";
import eyeOn from "./eyeOff.svg";
import eyeOff from "./eyeOn.svg";

const Input = (props) => {
  const { name, label, type, placeholder, formik, ...rest } = props;
  const [showPassword, setShowPassword] = useState(true);

  return (
    <div className="form_input">
      <label htmlFor={name}>{label}</label>
      <div className="input-eye-icon-container">
        <input
          name={name}
          // type={type}
          type={type === "password" ? (showPassword ? "password" : "text") : type}
          className={`input ${
            formik.errors[name] && formik.touched[name] ? "input-validation-error" : ""
          }`}
          placeholder={placeholder}
          value={formik?.values[name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          {...rest}
        />
        {type === "password" && (
          <img
            src={showPassword ? eyeOn : eyeOff}
            alt="eye-icon"
            className="eye-icon"
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </div>
      <p>
        {formik.errors[name] && formik.touched[name] && (
          // <Alert type="error" message={formik.errors[name]} banner />
          <span className="">
            <WarningOutlined /> {formik.errors[name]}
          </span>
        )}
      </p>
    </div>
  );
};
export default Input;

import { Tooltip } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { startEvent } from "../../../data/slices/feedback";
import { closeModal } from "../../../data/slices/modals";

function ScreenShot() {
  const dispatch = useDispatch();
  const captureScreenshot = () => {
    dispatch(startEvent("screenShot"));
    dispatch(closeModal("feedback-modal"));
  };

  return (
    <Tooltip title="screenshot">
      <div className="userback-controls-item" onClick={captureScreenshot}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path d="M400 354.2V157.8C427.5 150.6 448 125.8 448 96c0-35.38-28.62-64-64-64c-29.75 0-54.63 20.5-61.75 48H125.8C118.6 52.5 93.75 32 64 32C28.62 32 0 60.62 0 96c0 29.75 20.5 54.62 48 61.75v196.5C20.5 361.4 0 386.2 0 416c0 35.38 28.62 64 64 64c29.75 0 54.62-20.5 61.75-48h196.5c7.125 27.5 32 48 61.75 48c35.38 0 64-28.62 64-64C448 386.2 427.5 361.4 400 354.2zM322.2 400H125.8C120 377.6 102.4 360.1 80 354.2V157.8C102.4 152 119.9 134.4 125.8 112h196.5C328.1 134.4 345.6 151.9 368 157.8v196.5C345.6 360.1 328.1 377.6 322.2 400zM384 64c17.62 0 32 14.38 32 32s-14.38 32-32 32s-32-14.38-32-32S366.4 64 384 64zM32 96c0-17.62 14.38-32 32-32s32 14.38 32 32S81.62 128 64 128S32 113.6 32 96zM64 448c-17.62 0-32-14.38-32-32s14.38-32 32-32s32 14.38 32 32S81.62 448 64 448zM384 448c-17.62 0-32-14.38-32-32s14.38-32 32-32s32 14.38 32 32S401.6 448 384 448z"></path>
        </svg>
      </div>
    </Tooltip>
  );
}

export default ScreenShot;

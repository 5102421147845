import { useEffect } from "react";
import NProgress from "accessible-nprogress";
import "accessible-nprogress/dist/accessible-nprogress.css";

const LazyLoad = ({ showSpinner = false }) => {
  console.error("Lazy Loading");
  useEffect(() => {
    NProgress.configure({ showSpinner: false });
    NProgress.start();

    return () => {
      NProgress.done();
    };
  });

  return null;
};

export default LazyLoad;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { set_connected_sessions } from "../../../../data/slices/sessions";
import { Tooltip } from "antd";

function formatTime(milliseconds) {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
}

const getInitalTime = (session) => {
  const startAt = session?.podRunningAt;
  return startAt ? Date.now() - new Date(startAt).getTime() : session?.timer;
};

const SessionCounter = ({ session }) => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(getInitalTime(session));

  //TO fix the timer issue
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1000);
      dispatch(set_connected_sessions({ name: session?.id, timer: timer + 1000 }));
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [timer, session, dispatch]);

  return (
    <Tooltip title={formatTime(timer)}>
      <div className="sessionCounter">{formatTime(timer)}</div>
    </Tooltip>
  );
};

export default SessionCounter;

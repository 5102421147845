import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

const initialState = {
  snapshots: null,
  error: null,
  loading: null
};

export const getSnapshots = createAsyncThunk("get-snapshots", async ({ query }) => {
  let data;
  try {
    const response = await axiosInstance.get(`/snapshots`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const createSnapshot = createAsyncThunk("create-snapshots", async (body) => {
  let data;
  try {
    const response = await axiosInstance.post(`/snapshots`, body);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const snapshotSlice = createSlice({
  name: "snapshotSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getSnapshots.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getSnapshots.fulfilled]: (state, action) => {
      state.snapshots = action.payload?.snapshots;
      state.loading = false;
    },
    [getSnapshots.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [createSnapshot.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [createSnapshot.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createSnapshot.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    }
  }
});

// eslint-disable-next-line no-empty-pattern
export const {} = snapshotSlice.actions;

export default snapshotSlice.reducer;

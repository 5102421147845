import closeIcon from "../../../../../assets/icons/dashboard-x-close.svg";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ReactComponent as UserAddedIcon } from "./../../../../../assets/icons/invited-users.svg";
import React from "react";
import { Button, Tooltip } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const InviteUserListModal = ({ id, open, handleClose, data, ...rest }) => {
  const { t } = useTranslation("user");

  const GroupsInfo = {
    0: { color: "#05B7ED", label: t("user-34") },
    1: { color: "#105624", background: "#88D49D", label: t("user-35") },
    2: { color: "#A40404", background: "#F8A7A7", label: t("user-36") }
  };

  const getEmailGroup = (email) => {
    let group = null;
    Object.keys(data.response)?.forEach((groupKey, i) => {
      console.log(groupKey, i);
      if (data.response[groupKey]?.includes(email)) {
        group = i;
      }
    });
    return group !== null ? GroupsInfo[group] : null;
  };

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason === "escapeKeyDown" && handleClose(id)}
      className="ta-modal user_modal dashboard-user-modal invite-user-list"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px"
          }
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <span className="label">
          <UserAddedIcon />
          <p>{t("user-28")}</p>
          <div className="invite-user-info">
            {t("user-29")}{" "}
            <b>
              {[...new Set(data?.emails)]?.length}{" "}
              {data?.emails?.length > 1 ? " " + t("user-30") + " " : t("user-31") + " "}
            </b>
            {t("user-32")}
          </div>
        </span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <div className="invite-user-list-content">
          {Array.from(new Set(data?.emails))?.map((email, i) => {
            const { color = "", label = "-", background } = getEmailGroup(email) || {};
            return (
              <div className="invited-user" key={i}>
                <UserAddOutlined />
                <Tooltip title={email}>
                  <p>{email}</p>
                </Tooltip>
                <div className="user-tag" style={{ background, color, borderColor: color }}>
                  {label}
                </div>
              </div>
            );
          })}
        </div>
        <div className="action_form">
          <Button type="primary" onClick={() => handleClose(id)}>
            {t("user-33")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InviteUserListModal;

import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./_WrapperLoader.scss";
function WrapperLoader({ children, status }) {
  return (
    <Spin
      spinning={status}
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 30
          }}
          spin
        />
      }
      style={{ width: "100%" }}
    >
      {children}
    </Spin>
  );
}

export default WrapperLoader;

export const getLabelFromRoute = (pathname) => {
  if (pathname === "/admin/dashboard") {
    return "Dashboard";
  }

  if (pathname === "/admin/users") {
    return "Users";
  }

  if (pathname === "/admin/user-groups") {
    return "Roles";
  }

  if (pathname === "/admin/images") {
    return "Image & Group";
  }

  if (pathname === "/admin/billing") {
    return "Billing";
  }

  if (pathname === "/admin/plans") {
    return "Plans";
  }

  if (pathname === "/admin/ldap-config") {
    return "LDAP Configuration";
  }

  if (pathname === "/admin/network-policy") {
    return "Traffic Filtering";
  }

  if (pathname === "/admin/quota-managment") {
    return "Quota Management";
  }

  return "";
};

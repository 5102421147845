import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ReactComponent as CloseIcon } from "./../../assets/icons/x-close.svg";
import { ReactComponent as FolderUpIcon } from "./../../assets/icons/Folder_up.svg";
import { ReactComponent as VideoIcon } from "./../../assets/icons/Icon.svg";
import { CloseCircleOutlined, DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Button, message, Progress, Tooltip, Tree } from "antd";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../utils/axios";
import path from "path-browserify";
import { closeModal, openModal } from "../../data/slices/modals";
import Dragger from "antd/es/upload/Dragger";

const allowedTypesToPreview = ["image", "video", "audio", "pdf", "text"];

const fileSize = (bytes = 0) => {
  if (bytes === 0) {
    return "0.00 B";
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, e)).toFixed(2) + " " + " KMGTP".charAt(e) + "B";
};

export const getFileType = (contentType) => {
  const type = allowedTypesToPreview.find((type) => contentType?.includes(type));
  return type || "notAllowed";
};

const removeUploadItem = (item, list) => {
  return list.filter((ele) => ele?.file.uid !== item?.file?.uid);
};

const updatePercent = (percent, list, file) => {
  const ItemIndex = list?.findIndex((item) => item?.uid === file?.uid);
  if (ItemIndex >= 0) {
    list[ItemIndex] = { ...file, percent };
  } else list = [...list, { file, percent }];
  return list;
};

function FileTransferModal({ id, open, handleClose, data, ...rest }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { activeSession } = useSelector((state) => state.sessions);
  const [selectedNode, setSelectedNode] = useState(null);
  const homeDir = `/home/${user?.fullName}`;
  const [nodes, setNodes] = useState(null);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [nodesInfo, setNodesInfo] = useState(null);
  const [uploadList, setUploadList] = useState([]);
  const [percent, setPercent] = useState(null);
  const [downloaded, setDownloaded] = useState(false);

  const evaluateDirNode = async (stat, fullPath, isHome) => {
    let title;
    if (isHome) {
      title = homeDir;
    } else {
      title = stat.name;
    }
    const node = {
      fullPath,
      title,
      children: [],
      key: stat?.key || "0",
      expandable: true,
      lazy: false,
      isLeaf: false
    };

    if (!stat.contents || !stat.contents?.length) return node;

    stat.contents.forEach((child, index) => {
      const childNode = {
        fullPath: `${node.fullPath}/${child.name}`,
        title: child.name,
        size: child.size,
        expandable: child.isDirectory,
        key: `${node.key}-${index}`,
        contentType: child?.contentType
      };
      if (child.isDirectory) {
        childNode.lazy = true;
        childNode.isLeaf = false;
      } else childNode.isLeaf = true;

      node.children.push(childNode);
    });

    setNodesInfo([...(nodesInfo || []), node]);

    return node;
  };

  const statPath = async (fpath) => {
    try {
      fpath = fpath.replace(homeDir, ".");
      const res = await axiosInstance.get(
        `/desktops/fs/${activeSession?.namespace}/${activeSession?.name}/stat/${fpath}`
      );
      return res.data.stat;
    } catch (err) {
      message.error("Fail To Get The Directory");
      dispatch(closeModal("file-transfer-modal"));
      return null;
    }
  };

  const syncRootNode = async () => {
    const root = await statPath(".");
    if (!root) return;
    const rootNode = await evaluateDirNode(root, homeDir, true);
    setSelectedNode(rootNode);
    setNodes([rootNode]);
  };

  const onLazyLoad = async (node) => {
    new Promise(async (resolve) => {
      if (node?.children) {
        resolve();
        return;
      }
      const stat = await statPath(node.fullPath);
      const newNode = await evaluateDirNode({ ...stat, key: node?.key }, node.fullPath, false);
      setTimeout(() => {
        setSelectedNode({ ...(selectedNode || {}), ...newNode });
        setNodes((origin) => updateTreeData(origin, node?.key, newNode));
        resolve();
      }, 0);
    });
  };

  useEffect(() => {
    activeSession && open && syncRootNode();
  }, [activeSession, open]);

  const updateTreeData = (list, key, newNode) =>
    list.map((node) => {
      if (node.key === key) {
        return { ...newNode };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, newNode)
        };
      }
      return node;
    });

  const fetchNodePreview = async () => {
    const node = selectedNode;
    const fpath = node.fullPath.replace(homeDir, ".");
    if (!node.size) {
      message.error(`${path.basename(fpath)} is an empty file`);
      return;
    }
    const previewAlloawed = getFileType(node.contentType) !== "notAllowed";
    if (previewAlloawed) {
      setLoadingPreview(true);
      try {
        const isBlob = !node?.contentType.includes("text") ? { responseType: "blob" } : {};
        await axiosInstance
          .get(`/desktops/fs/${activeSession?.namespace}/${activeSession?.name}/get/${fpath}`, {
            ...isBlob
          })
          .then((res) => {
            setLoadingPreview(false);
            dispatch(
              openModal("file-preview-modal", {
                src: res.data,
                name: path.basename(fpath),
                type: getFileType(node.contentType)
              })
            );
          });
      } catch (err) {
        setLoadingPreview(false);
        message.error("Fail To Get Preview");
      }
    } else message.error("Sorry, file type not supported. Please download to view.");
  };

  const onDownloadProgress = (ev) => {
    const current = ev.loaded;
    let total;
    if (ev?.lengthComputable) total = ev.total;
    // else {
    //   const contentLength = ev.event.target.getResponseHeader(
    //     "x-decompressed-content-length"
    //   );
    //   total = parseInt(contentLength);
    // }
    setPercent(Math.floor((current / total) * 100));
  };

  const getImageType = (contentType) => {
    return contentType.split("/")[1];
  };

  const fetchNode = async () => {
    let node = selectedNode;
    const fpath = node.fullPath.replace(homeDir, ".");
    if (!node.size && !node.expandable) {
      message.error(`${path.basename(fpath)} is an empty file`);
      return;
    }
    setLoadingDownload(true);
    try {
      await axiosInstance(
        `/desktops/fs/${activeSession?.namespace}/${activeSession?.name}/get/${fpath}`,
        {
          method: "GET",
          responseType: "blob"
        }
      ).then((res) => {
        setLoadingDownload(false);
        const localname = getFilenameForDownload(fpath, res.headers);
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", localname.concat(!node.isLeaf ? ".tar.gz" : ``));
        document.body.appendChild(fileLink);
        fileLink.click();
        setDownloaded(true);
        message.success(`${path.basename(fpath)} Downloaded Successfully`);
      });
    } catch (err) {
      setLoadingDownload(false);
      message.error(`Failed to download ${path.basename(fpath)}`);
      setDownloaded(false);
      setTimeout(() => {
        setPercent(null);
      }, 500);
      return;
    }
    setTimeout(() => {
      setPercent(null);
    }, 500);
  };

  const getFilenameForDownload = (fpath, headers) => {
    const disposition = headers["content-disposition"];
    const suggestedFname = headers["x-suggested-filename"];
    // try the disposition header first
    if (disposition && disposition !== "") {
      const fname = disposition.split("filename=")[1].split(";")[0];
      console.log(`Using ${fname} from disposition header`);
      return fname;
    }
    // next try the suggested filename
    if (suggestedFname && suggestedFname !== "") {
      console.log(`Using ${suggestedFname} from suggested filename header`);
      return suggestedFname;
    }
    // finally use the name of the path in the url
    return path.basename(fpath);
  };
  const props = {
    name: "file",
    multiple: false,
    method: "put",
    showUploadList: false,
    customRequest: ({ file, onProgress, onSuccess, onError }) => {
      const formData = new FormData();
      formData.append("file", file);

      axiosInstance
        .put(`/desktops/fs/${activeSession?.namespace}/${activeSession?.name}/put`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            onProgress({ percent });
            setUploadList(updatePercent(percent, uploadList, file));
          }
        })
        .then((result) => {
          onSuccess(result, file);
        })
        .catch((error) => {
          onError(error, file);
        });
    },

    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file?.error?.error || "error"}`);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason === "escapeKeyDown" && handleClose(id)}
      className="ta-modal transferfile"
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <span className="title">File Transfer</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          <CloseIcon />
        </span>
      </DialogTitle>
      <DialogContent className="transferfile-content">
        <div className="transferfile-files">
          <Tree.DirectoryTree
            loadData={onLazyLoad}
            treeData={nodes}
            onSelect={(_, info) => setSelectedNode({ ...(selectedNode || {}), ...info.node })}
          />
          <div className="transferfile-details">
            <div className="transferfile-details-info">
              <Tooltip title={selectedNode?.title}>
                <p className="transferfile-details--title">{selectedNode?.title}</p>
              </Tooltip>
              <p className="transferfile-details--path">
                <span>Full Path :</span>
                <Tooltip title={selectedNode?.fullPath}>
                  <span>{selectedNode?.fullPath}</span>
                </Tooltip>
              </p>
              <p className="transferfile-details--size">
                {!selectedNode?.expandable && (
                  <>
                    <span>size :</span> <span>{fileSize(selectedNode?.size)}</span>
                  </>
                )}
              </p>
              <p className="transferfile-details--items">
                {selectedNode?.expandable && (
                  <>
                    <span>items :</span>
                    <span>{selectedNode?.children?.length}</span>
                  </>
                )}
              </p>
            </div>
            <div className="transferfile-details--buttons">
              {!selectedNode?.expandable && (
                <Button
                  type="primary"
                  ghost
                  size={"middle"}
                  icon={<EyeOutlined />}
                  onClick={fetchNodePreview}
                  loading={loadingPreview}
                >
                  Preview
                </Button>
              )}
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                size={"middle"}
                onClick={fetchNode}
                loading={loadingDownload}
              >
                Download
              </Button>
            </div>
          </div>
        </div>
        <div className="transferfile-section">
          <div className="transferfile-upload">
            <Dragger {...props} className="transferfile-dragger">
              <p className="ant-upload-drag-icon">
                <FolderUpIcon />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single upload. Strictly prohibited from uploading company data or
                other banned files.
              </p>
            </Dragger>
            <div className="transferfile-upload-list">
              {uploadList?.map((item, i) => {
                return (
                  <div key={i} className="upload-item">
                    <span
                      className="close-icon"
                      onClick={() => setUploadList(removeUploadItem(item, uploadList))}
                    >
                      <CloseCircleOutlined />
                    </span>
                    <div className="upload-item-icon">
                      <VideoIcon />
                    </div>
                    <div className="upload-item-info">
                      <p>{item?.file?.name}</p>
                      <Progress percent={item?.percent} size="small" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default FileTransferModal;

export const getNavigateRoute = (ability) => {
  if (ability.can("read", "stats")) {
    return { label: "Dashboard", link: "/admin/dashboard" };
  }

  if (ability.can("read", "users")) {
    return { label: "Users", link: "/admin/users" };
  }

  if (ability.can("read", "roles")) {
    return { label: "Roles", link: "/admin/user-groups" };
  }

  if (ability.can("read", "imagegroups")) {
    return { label: "Image & Group", link: "/admin/images" };
  }

  if (ability.can("read", "subscriptions")) {
    return { label: "Billing", link: "/admin/billing" };
  }

  if (ability.can("read", "plans")) {
    return { label: "Plans", link: "/admin/plans" };
  }

  if (ability.can("read", "ldap")) {
    return { label: "LDAP Configuration", link: "/admin/ldap-config" };
  }

  return { label: "Traffic Filtering", link: "/admin/network-policy" };
};

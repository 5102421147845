import { Avatar, Table, Tooltip } from "antd";
import { ReactComponent as CpuSVG } from "./../../assets/icons/cpu-icon.svg";
import { ReactComponent as RamSVG } from "./../../assets/icons/ram-icon.svg";
import React, { useEffect } from "react";
import { TableActions } from "../../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteQuotaUserGroup,
  getQuotaUserGroups
} from "../../../../data/slices/quotaManagementSlice";
import { useNavigate } from "react-router-dom";
import { openModal } from "../../../../data/slices/modals";

const getPercentage = (value, limit) => {
  console.log(value, limit);
  return ((value / limit) * 100).toFixed(2);
};

const UserGroupsActions = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <TableActions
      ability={"usergroups"}
      onClickEditAction={() =>
        dispatch(openModal("create-quota-group-modal", { ...data, isEdit: true }))
      }
      onClickDeleteAction={() => dispatch(deleteQuotaUserGroup({ id: data?.name }))}
      onClickShowAction={() => navigate("/admin/quota-managment/" + data?.name)}
    />
  );
};

const columns = [
  {
    title: "Group Name",
    dataIndex: "displayName",
    key: "name"
  },
  {
    title: "Creation date",
    key: "date",
    render: (data) => {
      const date = new Date(data?.createdAt);
      const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}-${date.getFullYear()}`;
      return formattedDate;
    }
  },
  {
    title: "Group Users",
    key: "group-users",
    render: (data) => {
      const { users, hiddenUsers } = data?.userDetails || {};
      return (
        <div className="group-users">
          {users?.map((user, i) => (
            <Tooltip title={user?.username}>
              <Avatar src={user?.profilePicture} size={"small"}>
                {user?.username && user?.username[0]?.toUpperCase()}
              </Avatar>
            </Tooltip>
          ))}
          {hiddenUsers ? <div className="group-users-hidden-users">+{hiddenUsers}</div> : null}
        </div>
      );
    }
  },
  {
    title: "Desktop Group",
    key: "desktop-groups",
    render: (data) => {
      const desktopGroupsNames = data?.imageGroups?.map((group) => group?.displayName);
      return (
        <div className="desktop-group">
          {desktopGroupsNames?.map((groupName) => (
            <div className="desktop-group-item">{groupName}</div>
          ))}
        </div>
      );
    }
  },
  {
    title: "Consumption",
    key: "consomption",
    render: (data) => {
      const { consumption, limitCpu, limitRam } = data;
      return (
        <div className="group-consomption">
          <div>
            <CpuSVG />
            <span>{getPercentage(consumption?.cpu, limitCpu)}%</span>
            <span>Cpu</span>
          </div>
          <div>
            <RamSVG />
            <span>{getPercentage(consumption?.ram, limitRam)}%</span>
            <span>Ram</span>
          </div>
        </div>
      );
    }
  },
  {
    title: "Status",
    key: "status",
    render: () => {
      return <div className="group-status">Active</div>;
    }
  },
  {
    title: "Action",
    key: "action",
    render: (data) => {
      return <UserGroupsActions data={data} />;
    }
  }
];

const GroupTable = () => {
  const dispatch = useDispatch();
  const { groups, isLoading } = useSelector((state) => state.quota);
  useEffect(() => {
    if (!groups) dispatch(getQuotaUserGroups({}));
  }, []);

  return (
    <div className="group-table">
      <Table columns={columns} dataSource={groups} pagination={false} loading={isLoading} />
    </div>
  );
};

export default GroupTable;

import { Input, Modal, message } from "antd";
import deleteIcon from "../../../../assets/icons/dashboard-modal-delete.svg";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteOrganisation, deleteProfile, logout } from "../../../../data/slices/authSlice";

const DeleteProfileOrg = ({ isOpen, setIsOpen, modalType }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (value === "DELETE PERMANENTLY") {
      if (modalType === "Profile") {
        dispatch(deleteProfile())
          .unwrap()
          .then(() => {
            dispatch(logout());
          })
          .catch((err) => {
            message.error(err.message || "Something went wrong");
          });
      } else {
        dispatch(deleteOrganisation())
          .unwrap()
          .then(() => {
            dispatch(logout());
          })
          .catch((err) => {
            message.error(err.message || "Something went wrong");
          });
      }
      setValue("");
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setValue("");
    setSubmitting(false);
  }, [modalType]);

  return (
    <Modal
      centered
      open={isOpen}
      onOk={() => alert(1)}
      onCancel={() => setIsOpen(false)}
      footer={null}
    >
      <form onSubmit={handleSubmit} className="delete-my-profile-org-container">
        <img src={deleteIcon} alt="delete" />

        <h1 className="delete-my-profile-org-container-title">Delete My {modalType}</h1>

        <p className="delete-my-profile-org-container-subtitle">
          <span>WARNING: </span>
          {modalType === "Profile"
            ? "Deleting your account removes your access to the GoMyDesk platform and all the information in your account. This process is final and not reversible."
            : "Deleting your organization removes access for all members and all associated data on the GoMyDesk platform. This action is final and cannot be reversed."}
        </p>

        <p className="delete-my-profile-org-container-subtitle">
          You will be signed out of the system and will not be able to sign in again.
        </p>

        <div className="delete-my-profile-org-container-confirm">
          <p className="delete-my-profile-org-container-subtitle">
            To confirm that you want to delete your account, type{" "}
            <span className="delete-my-profile-org-container-subtitle-bold">
              DELETE PERMANENTLY
            </span>{" "}
            below.
          </p>
          <Input
            status={submitting && value !== "DELETE PERMANENTLY" ? "error" : ""}
            size="large"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>

        <div className="delete-my-profile-org-container-btns">
          <button
            type="button"
            className="delete-my-profile-org-container-btns-cancel"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
          <button type="submit" className="delete-my-profile-org-container-btns-confirm">
            Confirm
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DeleteProfileOrg;

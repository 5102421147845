import React from "react";
import Spinner from "./Loading.svg";

const LoaderComponent = () => {
  return (
    <div className="loading-component">
      <div id="preloader">
        <img src={Spinner} width={21.2} height={50} alt="loader" className="icon" />
      </div>
    </div>
  );
};

export default LoaderComponent;

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect } from "react";
import closeIcon from "../../../assets/icons/x-close.svg";
import { useDispatch, useSelector } from "react-redux";
import { message, Tag } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputSelect } from "../../InputFields";
import { assignUsersToGroups, getUserGroups } from "../../../data/slices/userGroup";

function AssignModal({ id, open, handleClose, data = null, ...rest }) {
  const dispatch = useDispatch();
  const { usersGroup } = useSelector((state) => state.usersGroup);

  const formik = useFormik({
    initialValues: {
      roles: [],
      users: data
    },
    validationSchema: Yup.object().shape({
      roles: Yup.array().min(1).required()
    }),
    onSubmit: (values) => {
      dispatch(assignUsersToGroups({ ...values }))
        .unwrap()
        .then(() => {
          message.success("Users Assigned successfully");
          handleClose(id);
          formik.resetForm();
        })
        .catch((err) => {
          console.log(err);
          message.error(err.message || "something went wrong");
        });
    }
  });

  useEffect(() => {
    formik.setFieldValue(
      "users",
      data?.map((el) => el?.username)
    );
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      className="ta-modal  assign_modal"
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        {/* <img className="img_assign" src={deleteIcon} alt="" /> */}
        <p className="title">Assign Users To Group/s</p>

        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>

      <DialogContent className="ta-modal-content-scroll content_confirm">
        {/* <p className="title">Assign Users To Group/s</p> */}
        {/* <p className="subtitle"></p> */}
        <div className="list_users">
          Users :{" "}
          {data?.map((el, index) => (
            <Tag key={index}>{el?.username}</Tag>
          ))}
        </div>
        <form action="" onSubmit={formik.handleSubmit}>
          <InputSelect
            formik={formik}
            options={usersGroup?.map((item) => ({
              value: item?.name,
              label: item?.name
            }))}
            defaultOptions={formik.values.roles}
            onChange={(e) => {
              formik.setFieldValue("roles", e);
            }}
            field="roles"
            onDropdownVisibleChange={() => {
              dispatch(getUserGroups({ query: "" }));
            }}
            placeholder="Select Group/s"
          />
        </form>
      </DialogContent>
      <div className="action_confirm">
        <button className="cancel" onClick={() => handleClose(id)}>
          Cancel
        </button>
        <button type="submit" className="assign_btn" onClick={formik.handleSubmit}>
          Assign
        </button>
      </div>
    </Dialog>
  );
}

export default AssignModal;

export const RUN_RDP_DESKTOP_FIELDS = [
  {
    name: "hostname",
    type: "string",
    placeholder: "IP Address",
    label: "IP Address *"
  },
  {
    name: "username",
    type: "text",
    placeholder: "User Name",
    label: "User Name *"
  },
  {
    name: "password",
    type: "password",
    placeholder: "Password",
    label: "Password"
  }
];

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

const initialState = {
  config: null,
  error: null,
  loading: null
};

export const getConfig = createAsyncThunk("api/get-config", async () => {
  let data;
  try {
    const response = await axiosInstance.get(`/lti/config`);

    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    return null;
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error?.message : data?.message);
  }
});

//create config

export const createConfig = createAsyncThunk("api/create-config", async (values) => {
  let data;
  try {
    const response = await axiosInstance.post(`/lti/config`, values);

    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    return null;
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error?.message : data?.message);
  }
});

export const configSlice = createSlice({
  name: "configSlice",
  initialState,
  reducers: {
    resetoffersSliceData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    [getConfig.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getConfig.fulfilled]: (state, action) => {
      state.config = action.payload;
      state.loading = false;
    },
    [getConfig.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [createConfig.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [createConfig.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createConfig.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    }
  }
});

export const { resetoffersSliceData } = configSlice.actions;

export default configSlice.reducer;

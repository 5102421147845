import img from "../../../../assets/icons/Ubuntu.svg";
import offIcon from "../../../../assets/icons/shutdown.svg";
import counterIcon from "../../../../assets/icons/Contour.svg";
import { Modal, Tooltip } from "antd";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/dashboard-modal-delete.svg";
import { useDispatch } from "react-redux";
import { deleteSession, getSessions, set_active_session } from "../../../../data/slices/sessions";
import { useLocation, useNavigate } from "react-router-dom";
import SessionCounter from "../SessionCounter/SessionCounter";
import { useTranslation } from "react-i18next";

const ActiveDesktop = ({ session }) => {
  const { t } = useTranslation("other");
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const sessionName = session?.name || "_";
  const sessionIcon = session?.template?.imageIconUrl || session?.imageIconUrl;
  const sessionIsActive = !!session?.podRunningAt || session?.isConnected;

  const showDeleteConfirm = () => {
    confirm({
      centered: true,
      title: t("other-15"),
      content: "",
      okText: t("other-16"),
      okType: "danger",
      cancelText: t("other-17"),
      maskClosable: true,
      wrapClassName: "modal_delete_multiple",
      icon: (
        <div className="delete-model-icon">
          <DeleteIcon />
        </div>
      ),
      onOk() {
        dispatch(deleteSession({ name: session.name, namespace: session.namespace }));
        if (pathname === `/control/${session.name}`) {
          navigate("/");
        }
      },
      onCancel() {
        console.log("Cancel");
      },
      autoFocusButton: "cancel"
    });
  };
  const goControl = () => {
    if (pathname !== `/control/${session.name}`) {
      dispatch(set_active_session(session));
      navigate(`/control/${session.name}`, { state: session });
    }
    //to check the existence of this session
    dispatch(getSessions());
  };

  const getClassName = () => {
    if (pathname === `/control/${session.name}`) {
      return "_active";
    } else {
      return null;
    }
  };
  return (
    <div className={`active_desktop ${getClassName()}`} onClick={goControl}>
      <div className="desktop_left">
        <div className="desktop_left-icon">
          <img src={sessionIcon || img} alt="" />
        </div>
        <div className="desktop_left-info">
          <Tooltip title={sessionName}>
            <p className="desktop_name">{sessionName}</p>
          </Tooltip>

          <p className="desktop_time">
            <img src={counterIcon} alt="error" />
            {sessionIsActive ? <SessionCounter session={session} /> : "00h 00m 00s"}
          </p>
        </div>
      </div>
      <div className="desktop_right">
        <img
          src={offIcon}
          alt=""
          onClick={(e) => {
            e.stopPropagation();
            showDeleteConfirm();
          }}
        />
      </div>
    </div>
  );
};

export default ActiveDesktop;

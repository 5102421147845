import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  ldapConfig: null,
  ldapGroups: null,
  state: null,
  error: null
};

export const configureLdap = createAsyncThunk("api/configure-ldap", async (values) => {
  let data;
  try {
    const response = await axios.post(`/ldap/config`, values);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const testLdapConnection = createAsyncThunk("api/test-ldap-connection", async (values) => {
  let data;
  try {
    const response = await axios.post(`/ldap/test`, values);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const getLdapGroups = createAsyncThunk("api/get-ldap-groups", async () => {
  let data;
  try {
    const response = await axios.get(`/ldap/groups`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const confirmLdapConfig = createAsyncThunk("api/confirm-ldap-config", async (values) => {
  let data;
  try {
    const response = await axios.post(`/ldap/map-groups`, values);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const getLdapConfig = createAsyncThunk("api/get-ldap-config", async () => {
  let data;
  try {
    const response = await axios.get(`/ldap/config`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const ldapSlice = createSlice({
  name: "ldapSlice",
  initialState,
  reducers: {
    resetldapSliceData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    [configureLdap.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [configureLdap.fulfilled]: (state, action) => {
      state.state = "success";
    },
    [configureLdap.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    //
    [testLdapConnection.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [testLdapConnection.fulfilled]: (state, action) => {
      state.configureLdap = action.payload;
      state.state = "success";
    },
    [testLdapConnection.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    //
    [getLdapGroups.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getLdapGroups.fulfilled]: (state, action) => {
      state.ldapGroups = action.payload;
      state.state = "success";
    },
    [getLdapGroups.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    //
    [getLdapConfig.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getLdapConfig.fulfilled]: (state, action) => {
      state.ldapConfig = action.payload;
      state.state = "success";
    },
    [getLdapConfig.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    }
  }
});

export const { resetldapSliceData } = ldapSlice.actions;

export default ldapSlice.reducer;

import React, { useEffect } from "react";
import { ReactComponent as EditGroupIcon } from "../../assets/icons/edit-group.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/fi-rr-pencil.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/fi-rr-trash.svg";
import { ReactComponent as CpuSVG } from "../../assets/icons/cpu-icon.svg";
import { ReactComponent as UsersSVG } from "../../assets/icons/users.svg";
import { ReactComponent as RamSVG } from "../../assets/icons/ram-icon.svg";
import { ReactComponent as DiskSVG } from "../../assets/icons/disk-icon.svg";
import { Input, Spin, Table, message } from "antd";
import ResourceProgress from "../ResourceProgress/ResourceProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuotaUserGroup,
  updateQuotaUserGroup
} from "../../../../data/slices/quotaManagementSlice";
import { openModal } from "../../../../data/slices/modals";
import { store } from "../../../../data";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export const columns = [
  {
    title: "User Name",
    dataIndex: "username",
    key: "name"
  },
  {
    title: "CPU",
    key: "cpu",
    render: (data) => {
      return <div>{data?.consumption?.cpu} CPU/H</div>;
    }
  },
  {
    title: "Ram",
    key: "ram",
    render: (data) => {
      return <div>{data?.consumption?.ram} GB</div>;
    }
  },
  {
    title: "Disk",
    key: "disk",
    render: (data) => {
      return <div>{data?.consumption?.disk}</div>;
    }
  },

  {
    title: "Action",
    key: "action",
    render: (data) => {
      const group = store.getState().quota.group;
      const deleteUser = () => {
        const filtredUsers = group?.userDetails?.users
          ?.filter((user) => user?.username !== data?.username)
          .map((user) => user?.username);

        store.dispatch(
          updateQuotaUserGroup({
            id: group?.name,
            displayName: group?.displayName,
            description: group?.description,
            users: filtredUsers
          })
        );
      };
      return (
        <div className="quota-group-users-action">
          <EditIcon
            onClick={() =>
              store.dispatch(openModal("update-user-group-modal", { group, user: data }))
            }
          />
          <DeleteIcon onClick={deleteUser} />
        </div>
      );
    }
  }
];

export const QuotaGroupHeader = ({ label, button, onClick }) => {
  return (
    <div className="quota-group-header">
      <p className="quota-group-title">{label}</p>
      {button && (
        <div className="quota-group-button" onClick={onClick}>
          {button}
        </div>
      )}
    </div>
  );
};

const GroupDetails = ({ groupId }) => {
  const dispatch = useDispatch();
  const { group, isLoading } = useSelector((state) => state.quota);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getQuotaUserGroup({ id: groupId }))
      .unwrap()
      .catch(() => navigate("/admin/quota-managment"));
  }, []);

  const updateGroupHandler = () => {
    dispatch(openModal("create-quota-group-modal", { ...group, isEdit: true }));
  };

  if (!group && isLoading)
    return (
      <Spin
        className="quota-group-details-loading"
        indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
      />
    );

  return (
    <div className="quota-group">
      <div className="quota-group-section1">
        <div className="quota-group-info">
          <QuotaGroupHeader
            label={"Group Details"}
            button={
              <span>
                <EditGroupIcon /> Edit Group
              </span>
            }
            onClick={updateGroupHandler}
          />
          <div className="quota-group-body">
            <div className="quota-group-input">
              <label>Name</label>
              <Input value={group?.displayName} disabled />
            </div>
            <div className="quota-group-input">
              <label>Description</label>
              <Input value={group?.description} disabled />
            </div>
          </div>
        </div>
        <div className="quota-group-consumption">
          <QuotaGroupHeader label={"Group consumption"} />
          <div className="quota-group-body">
            <div className="col1">
              <ResourceProgress
                percent={group?.usedPercentage?.cpu}
                label={"CPU"}
                icon={<CpuSVG />}
                usage={`${group?.consumption?.cpu}/${group?.limitCpu}`}
                size={"small"}
              />
              <ResourceProgress
                percent={group?.usedPercentage?.ram}
                label={"Ram"}
                icon={<RamSVG />}
                usage={`${group?.consumption?.ram}/${group?.limitRam}`}
                size={"small"}
              />
            </div>
            <div className="col2">
              <ResourceProgress
                percent={group?.usedPercentage?.disk}
                label={"Disk"}
                icon={<DiskSVG />}
                usage={"6/10"}
                size={"small"}
              />
              <ResourceProgress
                percent={0}
                label={"Number of users"}
                icon={<UsersSVG />}
                usage={"6/10"}
                size={"small"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="quota-group-section2">
        <div className="quota-group-limit">
          <QuotaGroupHeader label={"Group Quota"} />
          <div className="quota-group-body">
            <div className="group-limit">
              <div className="group-limit-col1">
                <CpuSVG /> CPU
              </div>
              <div className="group-limit-col2">{group?.limitCpu} CPU</div>
            </div>
            <div className="group-limit">
              <div className="group-limit-col1">
                <RamSVG /> Ram
              </div>
              <div className="group-limit-col2">{group?.limitRam} GB</div>
            </div>
            <div className="group-limit">
              <div className="group-limit-col1">
                <DiskSVG /> Disk Size
              </div>
              <div className="group-limit-col2">{group?.limitDisk || 12} GB</div>
            </div>
          </div>
        </div>
        <div className="quota-group-users">
          <QuotaGroupHeader
            label={"Group Details"}
            button={
              <span>
                <EditGroupIcon /> Add user
              </span>
            }
            onClick={updateGroupHandler}
          />
          <div className="quota-group-users-table group-table">
            <Table
              columns={columns}
              dataSource={group?.userDetails?.users}
              pagination={false}
              loading={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDetails;

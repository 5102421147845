import { ReactComponent as ConfigCpuIcon } from "../../../../../assets/images/config-cpu.svg";
import { ReactComponent as ConfigDiskIcon } from "../../../../../assets/images/config-disk.svg";
import { ReactComponent as ConfigRamkIcon } from "../../../../../assets/images/config-ram.svg";

const ConfigItem = ({
  img,
  cpu,
  disk,
  ram,
  formik,
  isDisabled = false,
  isPersonalise = false,
  isActive = false,
  setSelectedConfig
}) => {
  const handleClick = () => {
    formik.setFieldValue("cpu", cpu);
    formik.setFieldValue("storage", disk);
    formik.setFieldValue("ram", ram);

    setSelectedConfig();
  };

  return (
    <button
      type="button"
      className={`
        config-item 
        ${isDisabled ? "grayscale-effect" : ""} 
        ${isPersonalise ? "config-item-disabled" : ""}
        ${!isDisabled && isActive ? "config-item-active" : ""}
      `}
      onClick={handleClick}
    >
      <img src={img} alt="img" />

      <div className="item-flex">
        <ConfigCpuIcon />
        <span className="item-title">CPU</span>
        <span className={`item-value ${isDisabled || isPersonalise ? "" : "item-value-disabled"}`}>
          {isDisabled ? " - " : cpu}
        </span>
      </div>

      <div className="item-flex">
        <ConfigDiskIcon />
        <span className="item-title">Disk Size</span>
        <span className={`item-value ${isDisabled || isPersonalise ? "" : "item-value-disabled"}`}>
          {isDisabled ? " - " : disk}
        </span>
      </div>

      <div className="item-flex">
        <ConfigRamkIcon />
        <span className="item-title">Ram Size</span>
        <span className={`item-value ${isDisabled || isPersonalise ? "" : "item-value-disabled"}`}>
          {isDisabled ? " - " : ram}
        </span>
      </div>
    </button>
  );
};

export default ConfigItem;

import { Modal } from "antd";
import React from "react";
import infoIcon from "../../assets/info.svg";

import timerIcon from "../../assets/timer.svg";
import "./_ReconnectModal.scss";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteSession } from "../../../../data/slices/sessions";
import SessionCounter from "../../../Desktops/components/SessionCounter/SessionCounter";

function ReconnectModal({ id, open, handleClose, data = null, ...rest }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const onClose = () => {
    dispatch(
      deleteSession({ name: data?.activeSession?.name, namespace: data?.activeSession?.namespace })
    );
    if (pathname === `/control/${data?.activeSession?.name}`) {
      navigate("/");
    }
    handleClose(id);
  };
  return (
    <Modal
      open={open}
      onOk={() => {
        data?.newDisplayManager?.connect();
        data?.setError(null);
        handleClose(id);
      }}
      onCancel={onClose}
      className="reconnect-modal"
      cancelText="Close Desktop"
      okText="Resume Desktop"
      closable={false}
    >
      <div className="section_reconnect">
        <p className="title">Resume or close the session</p>
        <span className="img_section">
          <img src={infoIcon} alt="" />
          {/* <SessionCounter session={data?.activeSession} /> */}
        </span>
        {/* <span className="img_section">
          <img src={infoIcon} alt="" />
        </span> */}
        <p className="message">The timer is still running. Would you like to resume or close it?</p>
      </div>
    </Modal>
  );
}

export default ReconnectModal;

import React, { useContext, useEffect } from "react";
import { ReactComponent as CpuSVG } from "./assets/icons/cpu-icon.svg";
import { ReactComponent as UsersSVG } from "./assets/icons/users.svg";
import { ReactComponent as RamSVG } from "./assets/icons/ram-icon.svg";
import { ReactComponent as DiskSVG } from "./assets/icons/disk-icon.svg";
import { ReactComponent as LightSVG } from "./assets/icons/lightBall.svg";
import GroupTable from "./components/GroupTable/GroupTable";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../data/slices/modals";
import ResourceProgress from "./components/ResourceProgress/ResourceProgress";
import { useNavigate, useParams } from "react-router-dom";
import GroupDetails from "./components/GroupDetails/GroupDetails";
import { getPlanReservedResources } from "../../data/slices/quotaManagementSlice";
import FeatureProvider from "../../providers/FeaturesProvider/FeatureProvider";
import { useTranslation } from "react-i18next";

const QuotaManagment = () => {
  const { t } = useTranslation("quota");
  const { reservedResources } = useSelector((state) => state.quota);
  const { currentPlan } = useSelector((state) => state.currentPlan);
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPlanReservedResources({}));
  }, []);

  const { usedPercentage, used, total } = reservedResources || {};

  return (
    <FeatureProvider status={currentPlan?.quotaManagementFeature?.isOn} type="upgrade">
      <div className="quota-managment">
        {groupId ? (
          <GroupDetails groupId={groupId} />
        ) : (
          <>
            <div className="quota-managment-section1">
              <div className="quota-managment-col1">
                <div className="upgrade-title">
                  <p>{t("quota-1")}</p>
                  <div className="upgrade-button" onClick={() => navigate("/admin/plans")}>
                    {t("quota-2")}
                  </div>
                </div>
                <div className="plan-resources-statistics">
                  <div className="plan-resources-statistics-col">
                    <ResourceProgress
                      percent={usedPercentage?.cpu}
                      label={"CPU"}
                      icon={<CpuSVG />}
                      usage={`${used?.cpu || 0}/${total?.cpu || 0}`}
                    />
                    <ResourceProgress
                      percent={usedPercentage?.ram}
                      label={"Ram Size"}
                      icon={<RamSVG />}
                      usage={`${used?.ram || 0}/${total?.ram || 0}`}
                    />
                  </div>
                  <div className="plan-resources-statistics-col">
                    <ResourceProgress
                      percent={usedPercentage?.disk}
                      label={"Disk Size"}
                      icon={<DiskSVG />}
                      usage={`${used?.disk || 0}/${total?.disk || 0}`}
                    />
                    <ResourceProgress
                      percent={0}
                      label={"Number of users"}
                      icon={<UsersSVG />}
                      usage={`${used?.user || 0}/${total?.users || 0}`}
                    />
                  </div>
                </div>
              </div>
              <div className="quota-managment-col2">
                <div className="user-list-container">
                  <div className="icon">
                    <LightSVG />
                  </div>
                  <div className="title">{t("quota-3")}</div>
                  <div className="description">{t("quota-4")}</div>
                </div>
              </div>
            </div>
            <div className="quota-managment-section2">
              <div className="section2-title">
                <p>{t("quota-5")}</p>
                <div onClick={() => dispatch(openModal("create-quota-group-modal"))}>
                  {t("quota-6")}
                </div>
              </div>
              <GroupTable />
            </div>
          </>
        )}
      </div>
    </FeatureProvider>
  );
};

export default QuotaManagment;

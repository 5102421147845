import React from "react";
import { Slider } from "antd";

const SliderValues = ({ label, onChange, marks, max, min, labelIcon, unit, value }) => {
  return (
    <div className="slider-antd">
      <p className="slider-label">
        {labelIcon}
        {label}
      </p>
      <div className="slider-content">
        <Slider
          marks={marks}
          step={1}
          defaultValue={value}
          value={value}
          max={max}
          min={min || 0}
          onChange={onChange}
        />
        <span className="active-value">
          {value} {unit}
        </span>
      </div>
    </div>
  );
};
export default SliderValues;

import React, { Fragment, useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import useWindowSize from "../../hooks/useWindowSize";
import { ReactComponent as CancelIcon } from "./../../assets/icons/fi-rr-cross-small (2).svg";
const colors = ["red", "green", "black", "blue"];

function ScreenShot({ start, onCaptureEnd, cancel }) {
  const { width, height } = useWindowSize();
  // const canvasRef = useRef(null);
  // let ctx = null;
  // let isDown = false;
  // let startX = 0;
  // let startY = 0;
  // let endX = 0;
  // let endY = 0;

  // const handleMouseDown = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   startX = parseInt(e.clientX - canvasRef.current.getBoundingClientRect().left);
  //   startY = parseInt(e.clientY - canvasRef.current.getBoundingClientRect().top);
  //   isDown = true;
  // };

  // const handleMouseUp = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   isDown = false;
  //   endX = parseInt(e.clientX - canvasRef.current.getBoundingClientRect().left);
  //   endY = parseInt(e.clientY - canvasRef.current.getBoundingClientRect().top);
  //   captureScreenshot();
  // };

  // const handleMouseOut = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   isDown = false;
  // };

  // const handleMouseMove = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (!isDown) return;
  //   const mouseX = parseInt(e.clientX - canvasRef.current.getBoundingClientRect().left);
  //   const mouseY = parseInt(e.clientY - canvasRef.current.getBoundingClientRect().top);

  //   ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

  //   const width = mouseX - startX;
  //   const height = mouseY - startY;

  //   ctx.strokeRect(startX, startY, width, height);
  // };

  // const initializeCanvas = () => {
  //   const canvas = canvasRef.current;
  //   ctx = canvas.getContext("2d");
  //   ctx.strokeStyle = "#ff7e42";
  //   ctx.lineWidth = 4;

  //   canvas.addEventListener("mousedown", handleMouseDown);
  //   canvas.addEventListener("mouseup", handleMouseUp);
  //   canvas.addEventListener("mousemove", handleMouseMove);
  //   canvas.addEventListener("mouseout", handleMouseOut);
  // };

  // const captureScreenshot = () => {
  //   const rectCanvas = document.createElement("canvas");
  //   rectCanvas.width = Math.abs(endX - startX);
  //   rectCanvas.height = Math.abs(endY - startY);
  //   const rectCtx = rectCanvas.getContext("2d");
  //   console.log({
  //     width: Math.abs(endX - startX),
  //     height: Math.abs(endY - startY),
  //     x: startX,
  //     y: startY
  //   });
  //   rectCtx.drawImage(
  //     canvasRef.current,
  //     startX,
  //     startY,
  //     rectCanvas.width,
  //     rectCanvas.height,
  //     0,
  //     0,
  //     rectCanvas.width,
  //     rectCanvas.height
  //   );
  //   const rectPOS = {
  //     x: startX + 8,
  //     y: startY + 8,
  //     width: Math.abs(endX - startX) - 5,
  //     height: Math.abs(endY - startY) - 5
  //   };
  //   html2canvas(document.body, rectPOS).then(function (canvas) {
  //     const dataURL = canvas.toDataURL();
  //     onCaptureEnd(dataURL);
  //     cancel();
  //   });
  // };

  // useEffect(() => {
  //   if (start) initializeCanvas();
  // }, [width, height, start]);

  const canvasRef = useRef(null);
  const [context, setContext] = useState(null);
  const [drawing, setDrawing] = useState(false);
  const [currentColor, setCurrentColor] = useState(colors[0]);
  const [showToolbar, setShowToolbar] = useState(true);

  // Initialize the canvas context on component mount

  const startDrawing = () => {
    setDrawing(true);
    context.beginPath();
  };

  const stopDrawing = () => {
    setDrawing(false);
    context.closePath();
  };

  const handleMouseMove = (e) => {
    if (!drawing) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    context.strokeStyle = currentColor;
    context.lineWidth = 5;
    context.lineJoin = "round";
    context.lineCap = "round";

    context.lineTo(x, y);
    context.stroke();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      setContext(ctx);
    }
  }, [start, width, height]);

  const captureScreenshot = () => {
    document.querySelector(".screenshot-toolbar").style.display = "none";
    html2canvas(document.body, {
      logging: true,
      letterRendering: 1,
      allowTaint: false,
      useCORS: true
    }).then(function (canvas) {
      const dataURL = canvas.toDataURL();
      onCaptureEnd(dataURL);
      cancel();

      document.querySelector(".screenshot-toolbar").style.display = "flex";
    });
  };

  if (!start) return Fragment;
  return (
    <div className="screenshot">
      {/* <canvas ref={canvasRef} width={width} height={height}>
        Canvas not supported
      </canvas> */}
      <div className="screenshot-cancel-button" onClick={cancel}>
        <CancelIcon />
      </div>
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        onMouseDown={startDrawing}
        onMouseUp={stopDrawing}
        onMouseMove={handleMouseMove}
      />

      <DraggableDiv>
        <div className={`screenshot-toolbar `}>
          {colors?.map((color, i) => {
            return (
              <div
                className={`screenshot-toolbar-button ${
                  color === currentColor ? "active-color" : ""
                }`}
                onClick={() => setCurrentColor(color)}
              >
                <div
                  className="screenshot-toolbar-color"
                  key={i}
                  style={{ backgroundColor: color }}
                ></div>
              </div>
            );
          })}
          <div
            className="screenshot-toolbar-confirm"
            onClick={() => {
              setShowToolbar(false);
              captureScreenshot();
            }}
          >
            Confirm
          </div>
        </div>
      </DraggableDiv>
    </div>
  );
}

export default ScreenShot;

function DraggableDiv({ children }) {
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    console.log("down");
    e.preventDefault();
    setDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseMove = (e) => {
    console.log("move");
    if (!dragging) return;

    const newX = e.clientX - offset.x;
    const newY = e.clientY - offset.y;

    setPosition({ x: newX, y: newY });
  };

  const handleMouseUp = () => {
    console.log("up");
    setDragging(false);
  };

  return (
    <div
      className="draggable"
      // style={{ left: position.x, top: position.y }}
      // onMouseDown={handleMouseDown}
      // onMouseMove={handleMouseMove}
      // onMouseUp={handleMouseUp}
    >
      {children}
    </div>
  );
}

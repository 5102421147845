import { ReactComponent as ProfileIcon } from "./assets/profile.svg";
import { ReactComponent as OrganisationIcon } from "./assets/organisation.svg";
import { ReactComponent as SecurityIcon } from "./assets/security.svg";
import { ReactComponent as HistoryIcon } from "./assets/history.svg";
import { Segmented, message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProfileAvatar from "./components/ProfileAvatar";
import ProfileDetails from "./components/ProfileDetails";
import History from "./components/History";
import Security from "./components/Security";
import OrgDomain from "./components/OrgDomain";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getProfileOrg, getRequestData } from "../../../data/slices/authSlice";
import { Can } from "../../../context/permissions/Can";
import { useTranslation } from "react-i18next";
import { openModal } from "../../../data/slices/modals";
import RequestData from "./components/RequestData/RequestData";
import DeleteProfileOrg from "./components/DeleteProfileOrg";
import ConfigLti from "../components/ConfigLti/ConfigLti";

const Profile = () => {
  const { t } = useTranslation("profile");

  const { user, profileOrg } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  useEffect(() => {
    dispatch(getProfileOrg());
  }, []);

  const items = [
    {
      value: "general",
      label: (
        <div className="profile-page-container-menu-tab">
          <ProfileIcon /> {t("profile.Profile-1")}
        </div>
      )
    },
    {
      value: "organization",
      label: (
        <div className="profile-page-container-menu-tab">
          <OrganisationIcon /> {t("profile.Profile-2")}
        </div>
      )
    },
    {
      value: "security",
      label: (
        <div className="profile-page-container-menu-tab">
          <SecurityIcon /> {t("profile.Profile-3")}
        </div>
      )
    },
    {
      value: "history",
      label: (
        <div className="profile-page-container-menu-tab">
          <HistoryIcon /> {t("profile.Profile-4")}
        </div>
      )
    },
    {
      value: "config",
      label: (
        <div className="profile-page-container-menu-tab">
          <HistoryIcon /> {t("profile.Profile-62")}
        </div>
      )
    }
    // {
    //   value: "request_data",
    //   label: (
    //     <div className="profile-page-container-menu-tab">
    //       <HistoryIcon /> {t("profile.Profile-61")}
    //     </div>
    //   )
    // }
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const handleDeleteMyProfile = () => {
    setModalType("Profile");
    setIsOpen(true);
  };

  const handleDeleteMyOrg = () => {
    setModalType("Organisation");
    setIsOpen(true);
  };

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleRequestData = () => {
    message.open({
      type: "loading",
      content: "Action in progress..",
      duration: 0
    });

    dispatch(getRequestData())
      .unwrap()
      .then(async (res) => {
        for (let link of res) {
          if (link) {
            window.open(link, "_blank", "noopener, noreferrer");
          }
        }

        message.destroy();
      })
      .catch((err) => {
        message.destroy();
        message.error(err.message || "something went wrong");
      });
  };

  return (
    <div className="profile-page-container">
      <div className="profile-page-content">
        <div className="profile-page-content-row">
          <div className="profile-page-content-col-1">
            <ProfileAvatar />

            <div className="profile-page-delete-my-profile">
              <button
                type="button"
                onClick={handleRequestData}
                className="profile-page-delete-my-profile-request-data"
              >
                Request Data
              </button>

              {user?.username !== profileOrg?.owner && (
                <button type="button" onClick={handleDeleteMyProfile}>
                  Delete My Profile
                </button>
              )}

              <Can
                I="delete"
                a="organizations"
                onClick={() => dispatch(openModal("request-quote-modal"))}
              >
                <button type="button" onClick={handleDeleteMyOrg}>
                  Delete My Organisation
                </button>
              </Can>
            </div>
          </div>
          <div className="profile-page-content-col-2-content">
            <div className="profile-page-content-col-2">
              <div className="profile-page-content-col-2-tabs">
                <Segmented
                  defaultValue={`${type || "general"}`}
                  options={items}
                  onChange={(value) => {
                    navigate(`/settings/profile?type=${value}`);
                  }}
                />
              </div>
              {type === "history" ? (
                <History />
              ) : type === "security" ? (
                <Security />
              ) : type === "organization" ? (
                //   <ProfileOrg />
                // ) : type === "request_data" ? (
                <RequestData />
              ) : type === "config" ? (
                <ConfigLti />
              ) : (
                <ProfileDetails />
              )}
            </div>

            <Can I="updatemy" a="organizations">
              {type === "organization" && <OrgDomain />}
            </Can>
          </div>
        </div>
      </div>

      <DeleteProfileOrg isOpen={isOpen} setIsOpen={setIsOpen} modalType={modalType} />
    </div>
  );
};

export default Profile;

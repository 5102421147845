import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";
import { Segmented } from "antd";

const { TabPane } = Tabs;

const CustomTabs = ({ tabs, setTab, defaultTab, type }) => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notification);

  const handleNotificationsLength = (notifications, activeTab) => {
    if (activeTab === "0") {
      return notifications.filter((el) => el.isArchived === false).length;
    }
    if (activeTab === "1") {
      return notifications.filter((el) => el.isNotificationRead === false).length;
    }
    if (activeTab === "2") {
      return notifications.filter((el) => el.isArchived === true).length;
    }
  };

  return (
    <div className="view_tabs_notif">
      {type === "details" ? (
        <Tabs tabBarGutter={25} defaultActiveKey={defaultTab} onChange={(e) => dispatch(setTab(e))}>
          {tabs.map((tab, index) => (
            <TabPane
              key={index}
              tab={
                <span className="tab_notif_item">
                  {tab.label}
                  <div
                    className={
                      tab.key === "0"
                        ? "length_tab all_notif"
                        : tab.key === "1"
                          ? "length_tab unread_notif"
                          : "length_tab archived_notif"
                    }
                  >
                    {handleNotificationsLength(notifications, tab.key)}
                  </div>
                </span>
              }
            />
          ))}
        </Tabs>
      ) : (
        <Segmented
          activeClassName="active-segment"
          defaultValue={defaultTab}
          options={tabs}
          onChange={(e) => dispatch(setTab(e))}
        />
      )}
    </div>
  );
};

export default CustomTabs;

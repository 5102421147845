import React, { useEffect, useState } from "react";
import { ReactComponent as CloseSVG } from "../../../../../assets/icons/x-close.svg";
import { ReactComponent as DeleteUserSVG } from "../../../assets/icons/delete-selected-user.svg";
import { ReactComponent as CpuSVG } from "../../../assets/icons/cpu-icon.svg";
import { ReactComponent as UsersSVG } from "../../../assets/icons/users.svg";
import { ReactComponent as RamSVG } from "../../../assets/icons/ram-icon.svg";
import { ReactComponent as DiskSVG } from "../../../assets/icons/disk-icon.svg";
import { Avatar, Drawer, Select, Switch, message } from "antd";
import { useFormik } from "formik";
import { InputText } from "../../../../../components/InputFields";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../../../data/slices/memberSlice";
import { handleQuery } from "../../../../../helpers/searchQuery";
import { getImageGroups } from "../../../../../data/slices/groups";
import ResourceProgress from "../../ResourceProgress/ResourceProgress";
import SliderValues from "./../../SliderValues/SliderValues";
import {
  createQuotaUserGroup,
  getPlanReservedResources,
  getQuotaUserGroup,
  updateQuotaUserGroup
} from "../../../../../data/slices/quotaManagementSlice";

const cpuMarks = {
  2: "2",
  4: "4",
  6: "6",
  8: "8",
  10: "10",
  12: "12"
};
const ramMarks = {
  2: "2",
  8: "8",
  14: "14",
  20: "20",
  26: "26",
  32: "32"
};
const diskMarks = {
  16: "16",
  32: "32",
  48: "48",
  64: "64",
  80: "80",
  96: "96",
  112: "112",
  128: "128"
};

export const imageGroupRender = (props) => {
  return <div className="desktop-group-item">{props?.label}</div>;
};

const reformulateMembers = (members, selectedUsers) => {
  return members
    ?.filter((member) => !selectedUsers?.includes(member?.username))
    ?.map((member) => {
      return { label: member?.fullName || member?.username, value: member?.username };
    });
};

export const reformulateImageGroups = (imageGroups) => {
  return imageGroups?.map((imageGroup) => {
    return { label: imageGroup?.displayName, value: imageGroup?.name };
  });
};

const CreateGroup = ({ id, open, handleClose, data, ...rest }) => {
  const dispatch = useDispatch();
  const { isLoading, reservedResources } = useSelector((state) => state.quota);
  const { members } = useSelector((state) => state.member);
  const { ImageGroups } = useSelector((state) => state.imageGroups);
  const [selectedUsers, setSelecetdUsers] = useState(null);
  const getMemberByUserName = (username) =>
    members?.find((member) => member?.username === username);
  const removeUser = (username) => {
    setSelecetdUsers((users) => users?.filter((user) => user !== username));
  };
  useEffect(() => {
    if (!reservedResources && open) dispatch(getPlanReservedResources({}));
  }, [open]);

  const { usedPercentage, used, total } = reservedResources || {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.isEdit ? data?.displayName : "",
      description: data?.isEdit ? data?.description : "",
      users: [],
      divideResourcesEqually: data?.isEdit ? data?.divideResourcesEqually : true,
      imageGroups: data?.isEdit ? data?.imageGroups?.map((group) => group?.name) : [],
      limitRam: data?.isEdit ? data?.limitRam : 2,
      limitCpu: data?.isEdit ? data?.limitCpu : 2,
      limitDisk: data?.isEdit ? data?.limitDisk : 16
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .matches(
          /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
          "Invalid format. Use lowercase letters, digits, hyphens, and dots."
        )
        .required("This field is required")
    }),
    onSubmit: (values) => {
      const newGroup = { ...values, users: selectedUsers };
      dispatch(
        data?.isEdit
          ? updateQuotaUserGroup({ id: data?.name, ...newGroup })
          : createQuotaUserGroup(newGroup)
      )
        .unwrap()
        .then((res) => {
          handleClose(id);
          if (data?.isEdit) dispatch(getQuotaUserGroup({ id: data?.name }));
        })
        .catch((err) => {
          message.error(err?.message);
        });
    }
  });
  // TODO : Add the paginated select component
  useEffect(() => {
    if (open) {
      dispatch(getUsers({ query: "" }));
      dispatch(getImageGroups({ query: "" }));
    }
    formik.resetForm();
    setSelecetdUsers(null);
  }, [open]);

  useEffect(() => {
    if (data?.isEdit && members)
      setSelecetdUsers(data?.userDetails?.users?.map(({ username }) => username));
  }, [data, members]);

  return (
    <Drawer
      placement="right"
      open={open}
      width="35%"
      closable={false}
      className="quota-group-drawer"
    >
      <div className="quota-group-drawer-content drawer-group">
        <div className="drawer-group-close-icon" onClick={() => handleClose(id)}>
          <CloseSVG />
        </div>
        <div className="drawer-group-title">
          <p>{data?.isEdit ? "Edit" : "Create New"} Group</p>
          <p>Add all the information in order to create your new image</p>
        </div>
        <div className="drawer-group-form">
          <form action="" onSubmit={formik.handleSubmit}>
            <InputText
              formik={formik}
              field={{
                label: "Group Name*",
                name: "name",
                required: true,
                placeholder: "Enter your full name"
              }}
            />
            <InputText
              formik={formik}
              field={{
                label: "Description",
                name: "description",
                required: true,
                placeholder: "Write a brief description..."
              }}
            />
            <div className="drawer-group-form-users">
              <label className="drawer-group-label">Add a users</label>
              <div className="users-search">
                <Select
                  options={members && reformulateMembers(members, selectedUsers)}
                  value={null}
                  onChange={(user) => setSelecetdUsers((users) => [...(users || []), user])}
                  bordered={false}
                  placeholder="Select users "
                />
                <div className="selected-users">
                  {selectedUsers?.map((user) => {
                    return (
                      <div className="selected-user-item">
                        <Avatar /> {getMemberByUserName(user)?.fullName}{" "}
                        <DeleteUserSVG onClick={() => removeUser(user)} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="drawer-group-form-users">
              <label className="drawer-group-label">Add Desktop Image/Group</label>
              <div className="users-search">
                <Select
                  options={ImageGroups && reformulateImageGroups(ImageGroups)}
                  mode={"multiple"}
                  tagRender={(props) => imageGroupRender(props)}
                  onChange={(groups) => {
                    formik.setFieldValue("imageGroups", groups);
                  }}
                  value={formik.values.imageGroups}
                  bordered={false}
                  placeholder="Select Image Groups"
                />
              </div>
            </div>
            <div className="drawer-group-quota">
              <p className="title">Your plan Total consumption</p>
              <div className="drawer-group-resources">
                <div className="col1">
                  <ResourceProgress
                    percent={usedPercentage?.cpu}
                    label={"CPU"}
                    icon={<CpuSVG />}
                    usage={`${used?.cpu}/${total?.cpu}`}
                    size={"small"}
                  />
                  <ResourceProgress
                    percent={usedPercentage?.ram}
                    label={"Ram Size"}
                    icon={<RamSVG />}
                    usage={`${used?.ram}/${total?.ram}`}
                    size={"small"}
                  />
                </div>
                <div className="col2">
                  <ResourceProgress
                    percent={usedPercentage?.disk}
                    label={"Disk Size"}
                    icon={<DiskSVG />}
                    usage={`${used?.disk || 0}/${total?.disk || 0}`}
                    size={"small"}
                  />
                  <ResourceProgress
                    percent={0}
                    label={"Number of users"}
                    icon={<UsersSVG />}
                    usage={`${used?.user || 0}/${total?.users || 0}`}
                    size={"small"}
                  />
                </div>
              </div>
              <p className="title">Customize your group consumption</p>
              <div className="drawer-group-quota-setting">
                <SliderValues
                  labelIcon={<CpuSVG />}
                  min={2}
                  max={12}
                  label="Number of Cores (CPU)"
                  marks={cpuMarks}
                  value={formik.values["limitCpu"]}
                  unit="CPU/H"
                  onChange={(cpu) => formik.setFieldValue("limitCpu", cpu)}
                />
                <SliderValues
                  labelIcon={<RamSVG />}
                  min={2}
                  max={32}
                  label="Ram Size (GB)"
                  marks={ramMarks}
                  unit="GB"
                  value={formik.values["limitRam"]}
                  onChange={(ram) => formik.setFieldValue("limitRam", ram)}
                />
                <SliderValues
                  labelIcon={<DiskSVG />}
                  min={16}
                  max={128}
                  label="Disk Size (GB)"
                  marks={diskMarks}
                  unit="GB"
                  value={formik.values["limitDisk"]}
                  onChange={(disk) => formik.setFieldValue("limitDisk", disk)}
                />
              </div>
              <div className="drawer-group-switch">
                <Switch
                  defaultChecked
                  onChange={(e) => formik.setFieldValue("divideResourcesEqually", e)}
                />
                Divide Resources Equally
              </div>
              <div className="confirmation">
                <button
                  className="confirmation-cancel"
                  type="reset"
                  onClick={() => {
                    handleClose(id);
                    setSelecetdUsers(null);
                  }}
                >
                  Cancel
                </button>
                <button className="confirmation-confirm">
                  {isLoading ? "Loading..." : "Confirm"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Drawer>
  );
};

export default CreateGroup;

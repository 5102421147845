import HamburgerMenuTrigger from "../HamburgerMenuTrigger";
import useWindowSize from "../../hooks/useWindowSize";
import { ReactComponent as Logo } from "../../assets/icons/GoMyDeskLogo.svg";
import DesktopsHeader from "../../features/Desktops/components/DesktopsHeader";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Button, Dropdown } from "antd";

import enFlagIcon from "../../assets/icons/navbar/en-flag.png";
import frFlagIcon from "../../assets/icons/navbar/fr-flag.png";
import { getLabelFromRoute } from "../../utils/getLabelFromRoute";
import DropDownHeader from "../Menu/AntdDropDown";

const Header = ({ isAdmin }) => {
  const { t, i18n } = useTranslation("translation");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { session } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { headerTitle } = useSelector((state) => state.tabs);
  const { mousePosition } = useSelector((state) => state.sessions);
  const fullHeight = mousePosition >= 10 && document.fullscreenElement && session;

  const [lang, setLang] = useState(i18n?.language?.toString());

  const onChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLang(language);
  };

  const languagesItems = [
    {
      key: "1",
      label: (
        <div className="navbar-flag-container" onClick={() => onChangeLanguage("en")}>
          <img src={enFlagIcon} alt="flag" className="navbar-flag" />
          <p>{t("language.en")}</p>
        </div>
      )
    },
    {
      key: "2",
      label: (
        <div className="navbar-flag-container" onClick={() => onChangeLanguage("fr")}>
          <img src={frFlagIcon} alt="flag" className="navbar-flag" />
          <p>{t("language.fr")}</p>
        </div>
      )
    }
  ];

  return (
    <>
      <header className={isAdmin ? "header_admin" : "header"} id={fullHeight ? "hideHeader" : ""}>
        {!isAdmin && (
          <>
            <div className="header__group header__group__logo" onClick={() => navigate("/")}>
              <Logo />
            </div>
            <DesktopsHeader onClick={() => navigate("/")} />
          </>
        )}
        {isAdmin && width >= 1090 && (
          <div className="header__group">
            {isAdmin ? (
              <p className="admin_page_title">{t(headerTitle || getLabelFromRoute(pathname))}</p>
            ) : (
              <Logo />
            )}
          </div>
        )}

        <div className="header__group">
          {isAdmin && <HamburgerMenuTrigger />}
          {width < 1090 && isAdmin && <Logo />}
          <div className="header_main_items">
            {/* <Notifications /> */}
            <Dropdown
              menu={{ items: languagesItems }}
              trigger={["click"]}
              placement="bottomRight"
              arrow
            >
              <Button type="link" shape="circle">
                <div className="navbar-flag-container">
                  <img
                    src={lang === "fr" ? frFlagIcon : enFlagIcon}
                    alt="flag"
                    className="navbar-flag"
                  />
                </div>
              </Button>
            </Dropdown>
            <DropDownHeader isAdmin={isAdmin} />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import ConfigLtiV1_3 from "../ConfigLtiV1-3/ConfigLtiV1-3";
import { Select } from "antd";
import ConfigLtiV1_0 from "../ConfigLtiV1-0/ConfigLtiV1-0";

function ConfigLti() {
  // Set default state from localStorage or fallback to "lti_1_3"
  const [version, setVersion] = useState(localStorage.getItem("ltiVersion") || "lti_1_3");

  const ltiVersions = [
    {
      label: "LTI 1.3",
      value: "lti_1_3"
    },
    {
      label: "LTI 1.0",
      value: "lti_1_0"
    }
  ];

  const handleVersionChange = (value) => {
    setVersion(value);
    localStorage.setItem("ltiVersion", value);
  };

  useEffect(() => {
    // If no version is stored, initialize with the default
    if (!localStorage.getItem("ltiVersion")) {
      localStorage.setItem("ltiVersion", "lti_1_3");
    }
  }, []);

  const currentLtiLabel = ltiVersions.find((lti) => lti.value === version).label;

  return (
    <div className="config-versions">
      <Select
        className="lti_select_version"
        options={ltiVersions}
        name="version"
        onChange={handleVersionChange}
        value={version} // Use value to stay in sync with state
      />
      <h3>{currentLtiLabel} Configuration and Setup </h3>

      {version === "lti_1_3" ? <ConfigLtiV1_3 /> : <ConfigLtiV1_0 />}
      {/* Add other components based on version if needed */}
    </div>
  );
}

export default ConfigLti;

import { useSelector, useDispatch } from "react-redux";
import { closeSidebar } from "../../data/slices/settingsSlice";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
// import { ReactComponent as Logo } from "../../assets/icons/Logo.svg";
import Logo from "../../assets/icons/SidebarLogo.svg";

import useWindowSize from "../../hooks/useWindowSize";
import Navigation from "../Navigation/Navigation";
import { Link } from "react-router-dom";
const CloseSidebarTrigger = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  if (width >= 1090) return null;

  return (
    <div className="close_sidebar_trigger" onClick={() => dispatch(closeSidebar())}>
      <CloseIcon />
    </div>
  );
};

const Sidebar = () => {
  const { isSidebarOpened } = useSelector((state) => state.settings);
  const { width } = useWindowSize();
  return (
    <div className={isSidebarOpened === true && width < 1090 ? "sidebar open" : "sidebar"}>
      <div className="sidebar__group">
        <Link to={"/"} className="logo__container">
          {/* <a style={{ cursor: "pointer" }} href="/">
            Daas Dashboard
          </a> */}
          {/* <Logo /> */}
          {/* <CloseSidebarTrigger /> */}
          <img src={Logo} alt="logo" className="sidebar-logo" />
        </Link>
        <Navigation />
      </div>
    </div>
  );
};

export default Sidebar;

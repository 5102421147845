import { Tooltip } from "antd";
import React from "react";
import ScreenRecord from "../ScreenRecord/ScreenRecord";
import ScreenShot from "../ScreenShot/ScreenShot";
import { useDispatch } from "react-redux";
import { saveMedia } from "../../../data/slices/feedback";

//["P", "D"] => P for Preview and D for Download

const Attachment = ({ setMedia }) => {
  const dispatch = useDispatch();
  return (
    <Tooltip title="file">
      <label className="userback-controls-item" htmlFor="feedback-file">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M149.106 512c-33.076 0-66.153-12.59-91.333-37.771-50.364-50.361-50.364-132.305-.002-182.665L319.842 29.498c39.331-39.331 103.328-39.331 142.66 0 39.331 39.332 39.331 103.327 0 142.657l-222.63 222.626c-28.297 28.301-74.347 28.303-102.65 0-28.3-28.301-28.3-74.349 0-102.649l170.301-170.298c4.686-4.686 12.284-4.686 16.97 0l5.661 5.661c4.686 4.686 4.686 12.284 0 16.971l-170.3 170.297c-15.821 15.821-15.821 41.563.001 57.385 15.821 15.82 41.564 15.82 57.385 0l222.63-222.626c26.851-26.851 26.851-70.541 0-97.394-26.855-26.851-70.544-26.849-97.395 0L80.404 314.196c-37.882 37.882-37.882 99.519 0 137.401 37.884 37.881 99.523 37.882 137.404.001l217.743-217.739c4.686-4.686 12.284-4.686 16.97 0l5.661 5.661c4.686 4.686 4.686 12.284 0 16.971L240.44 474.229C215.26 499.41 182.183 512 149.106 512z"></path>
        </svg>
        <input
          type="file"
          id="feedback-file"
          onChange={(event) => {
            dispatch(
              saveMedia({
                file: event.target.files[0],
                blobURL: event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null,
                icons: ["P"],
                type: "attachement"
              })
            );
          }}
        />
      </label>
    </Tooltip>
  );
};

function ControllBar({ setMedia }) {
  return (
    <div className="controls-attach-actions">
      <ScreenShot setMedia={setMedia} />
      {/* <ScreenRecord setMedia={setMedia} /> */}
      <Attachment setMedia={setMedia} />
    </div>
  );
}

export default ControllBar;

import { Input, message } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../data/slices/memberSlice";
import { useTranslation } from "react-i18next";

const ProfileDetails = () => {
  const { t } = useTranslation("profile");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [fullName, setFullName] = useState(user?.fullName);

  const updateHandler = (e) => {
    e.preventDefault();
    if (fullName?.trim() === user?.fullName?.trim()) {
      message.warning(t("profile.Profile-5"));
    } else {
      dispatch(updateUser({ values: { fullName: fullName?.trim() }, id: user?.username }));
      message.success(t("profile.Profile-6"));
    }
  };

  return (
    <form className="ProfileV2-page-col-2-form-general" onSubmit={updateHandler}>
      <div className="ProfileV2-page-col-2-form-input-width">
        <label className="ProfileV2-page-col-2-label" htmlFor="personal-info-input-fullname">
          {t("profile.Profile-7")}*
        </label>
        <Input
          className="personal-info-input"
          defaultValue={user?.fullName}
          onChange={(e) => {
            setFullName(e.target.value);
          }}
          id="personal-info-input-fullname"
          status={fullName === "" ? "error" : null}
        />
        <small className="error_txt">{fullName === "" && t("profile.Profile-8")}</small>
      </div>

      <div className="ProfileV2-page-col-2-form-input-width">
        <label className="ProfileV2-page-col-2-label" htmlFor="personal-info-input-username">
          {t("profile.Profile-9")}*
        </label>
        <Input
          defaultValue={user?.username}
          className="personal-info-input personal-info-input-disabled"
          disabled={true}
          id="personal-info-input-username"
        />
      </div>
      <div className="ProfileV2-page-col-2-form-input-width">
        <label className="ProfileV2-page-col-2-label" htmlFor="personal-info-input-username">
          {t("profile.Profile-10")}*
        </label>
        <Input
          type="email"
          defaultValue={user?.email}
          className="personal-info-input personal-info-input-disabled"
          disabled={true}
          id="personal-info-input-email"
        />
      </div>
      <div className="ProfileV2-page-col-2-form-btn">
        <button className="ProfileV2-page-col-2-form-btn-confirm" type="submit">
          {t("profile.Profile-11")}
        </button>
      </div>
    </form>
  );
};

export default ProfileDetails;

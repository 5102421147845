import { Button, Checkbox } from "antd";
import "./_RequestData.scss";
import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText, InputTextArea } from "../../../../../components/InputFields";

function RequestData() {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      message: "",
      email: "",
      phone: "",
      request_type: []
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("This field is required"),
      address: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("This field is required"),
      message: Yup.string()
        .min(2, "Too Short!")
        .max(1550, "Too Long!")
        .required("This field is required"),
      email: Yup.string().email().required("This field is required")
    }),
    onSubmit: async (values) => {
      console.log(values);
    }
  });

  const handleRequestType = (type) => {
    const updatedRequestType = formik.values.request_type.filter((t) => t !== type);

    if (formik.values.request_type.includes(type)) {
      formik.setFieldValue("request_type", updatedRequestType);
    } else {
      formik.setFieldValue("request_type", [...formik.values.request_type, type]);
    }
  };

  return (
    <div className="request-data-section">
      <form action="">
        <div className="content-request-data">
          <div className="top-section-data">
            <InputText
              field={{
                name: "name",
                type: "text",
                placeholder: "Enter your name",
                label: "Your Name *"
              }}
              formik={formik}
            />
            <InputText
              field={{
                name: "email",
                type: "email",
                placeholder: "Enter your email",
                label: "Your Email *"
              }}
              formik={formik}
            />
            <InputText
              field={{
                name: "address",
                type: "text",
                placeholder: "Enter your address",
                label: "Address"
              }}
              formik={formik}
            />

            <InputText
              field={{
                name: "phone",
                type: "number",
                placeholder: "Enter your phone number",
                label: "Phone Number"
              }}
              formik={formik}
            />
          </div>
          <div className="request-type">
            <label htmlFor="request-type">Request Type *</label>
            <div className="types-of-request">
              <div className="request-type-1" onClick={() => handleRequestType("export")}>
                <Checkbox checked={formik.values.request_type.includes("export")} />
                <p className="title-type">Data export</p>
              </div>
              <div className="request-type-1" onClick={() => handleRequestType("deletion")}>
                <Checkbox checked={formik.values.request_type.includes("deletion")} />
                <p className="title-type">Data deletion</p>
              </div>
              <div className="request-type-1" onClick={() => handleRequestType("other")}>
                <Checkbox checked={formik.values.request_type.includes("other")} />
                <p className="title-type">Other request</p>
              </div>
            </div>
          </div>
          <InputTextArea
            field={{
              name: "message",
              type: "textarea",
              placeholder: "Enter your message",
              label: "Message"
            }}
            formik={formik}
          />
        </div>
        <div className="actions-modal">
          {/* <Button className="btn-privacy cancel-btn">Cancel </Button> */}
          <Button className="btn-privacy save-btn">Save </Button>
        </div>
      </form>
    </div>
  );
}

export default RequestData;

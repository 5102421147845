import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import closeIcon from "../../../../../assets/icons/dashboard-x-close.svg";
import { QuotaGroupHeader, columns } from "../../GroupDetails/GroupDetails";
import ResourceProgress from "../../ResourceProgress/ResourceProgress";
import { ReactComponent as CpuSVG } from "../../../assets/icons/cpu-icon.svg";
import { ReactComponent as UsersSVG } from "../../../assets/icons/users.svg";
import { ReactComponent as RamSVG } from "../../../assets/icons/ram-icon.svg";
import { ReactComponent as DiskSVG } from "../../../assets/icons/disk-icon.svg";
import { ReactComponent as WarningSVG } from "../../../assets/icons/warning.svg";
import { Avatar, Input, Table, message } from "antd";
import SliderValues from "../../SliderValues/SliderValues";
import { useDispatch } from "react-redux";
import { updateUserConsumption } from "../../../../../data/slices/quotaManagementSlice";

const cpuMarks = {
  2: "2",
  4: "4",
  6: "6",
  8: "8",
  10: "10",
  12: "12"
};
const ramMarks = {
  2: "2",
  8: "8",
  14: "14",
  20: "20",
  26: "26",
  32: "32"
};
const diskMarks = {
  16: "16",
  32: "32",
  48: "48",
  64: "64",
  80: "80",
  96: "96",
  112: "112",
  128: "128"
};

const filterSearch = (users, search) => {
  return users?.filter((user) => user?.username?.includes(search));
};

const UpdateUserGroup = ({ id, open, handleClose, data, ...rest }) => {
  const dispatch = useDispatch();
  const { group, user } = data || {};
  const [selectedUser, setSeletedUser] = useState(user);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [newData, setData] = useState({ ...(user?.limit || {}) });
  let users = group?.userDetails?.users?.map((user) => ({ [user?.username]: user?.limit }));
  const setDataHandler = (label, value) =>
    setData((prev) => ({
      ...prev,
      [selectedUser?.username]: { ...(prev[selectedUser?.username] || {}), [label]: value }
    }));

  const selectedUserData = newData[selectedUser?.username];

  useEffect(() => {
    if (selectedUser)
      setData((prev) => ({ [selectedUser?.username]: selectedUser?.limit, ...(prev || {}) }));
  }, [selectedUser]);

  useEffect(() => {
    if (user) setSeletedUser(user);
  }, [user]);

  const reformulateUsers = (users) => {
    return users?.map((user) => {
      return {
        ...user,
        key: user?.username
      };
    });
  };

  const rowSelection = {
    selectedRowKeys: [selectedUser?.username],
    hideSelectAll: true,
    onChange: (_, value) => {
      console.log({ value });
      setSeletedUser(value[0]);
    },
    type: "radio"
  };

  const submit = () => {
    setLoading(true);
    const newUsers = {};
    group?.userDetails?.users?.forEach((user, i) =>
      users[i] && users[i][user?.username]
        ? (newUsers[user?.username] = users[i][user?.username])
        : null
    );

    dispatch(
      updateUserConsumption({
        id: group?.name,
        customConfig: newData
      })
    )
      .unwrap()
      .then(() => handleClose(id))
      .catch((err) => message.error(err?.message))
      .finally(() => setLoading(false));
  };
  return (
    <Dialog
      open={open}
      className="ta-modal user_modal dashboard-user-modal quota-group-drawer user-consumption-modal"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1010px"
          }
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <span className="label">Edit User Consumption</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>

      <DialogContent className="ta-modal-content-scroll drawer-group">
        <div className="user-consumption-section1 quota-group-consumption">
          <QuotaGroupHeader label="Group consumption" />
          <div className="quota-group-body">
            <div className="col1">
              <ResourceProgress
                percent={group?.usedPercentage?.cpu}
                label={"CPU"}
                icon={<CpuSVG />}
                usage={`${group?.consumption?.cpu}/${group?.limitCpu}`}
                size={"small"}
              />
              <ResourceProgress
                percent={group?.usedPercentage?.ram}
                label={"Ram"}
                icon={<RamSVG />}
                usage={`${group?.consumption?.ram}/${group?.limitRam}`}
                size={"small"}
              />
            </div>
            <div className="col2">
              <ResourceProgress
                percent={group?.usedPercentage?.disk}
                label={"Disk"}
                icon={<DiskSVG />}
                usage={"6/10"}
                size={"small"}
              />
              <ResourceProgress
                percent={30}
                label={"Nbr Users"}
                icon={<UsersSVG />}
                usage={"6/10"}
                size={"small"}
              />
            </div>
          </div>
        </div>
        <div className="user-consumption-section2">
          <div className="user-consumption-warning">
            <WarningSVG />
            <p>
              You can edit individual user resource usage based on the organization's overall
              consumption.
            </p>
          </div>
        </div>
        <div className="user-consumption-section3">
          <Avatar src={selectedUser?.profilePicture}>
            {selectedUser?.username && selectedUser?.username[0]?.toUpperCase()}
          </Avatar>
          <p>{selectedUser?.username}</p>
        </div>
        <div className="user-consumption-section4">
          <div className="drawer-group-quota-setting">
            <div className="sliders-container">
              <SliderValues
                labelIcon={<CpuSVG />}
                min={2}
                max={12}
                label="CPU"
                marks={cpuMarks}
                value={selectedUserData?.cpu}
                unit="CPU/H"
                onChange={(cpu) => setDataHandler("cpu", cpu)}
              />
              <SliderValues
                labelIcon={<RamSVG />}
                min={2}
                max={32}
                label="Ram (GB)"
                marks={ramMarks}
                unit="GB"
                value={selectedUserData?.ram}
                onChange={(ram) => setDataHandler("ram", ram)}
              />
              <SliderValues
                labelIcon={<DiskSVG />}
                min={16}
                max={128}
                label="Disk (GB)"
                marks={diskMarks}
                unit="GB"
                value={selectedUserData?.disk}
                onChange={(disk) => setDataHandler("disk", disk)}
              />
            </div>
          </div>
          <div className="drawer-group-quota-table">
            <div className="users-search-input">
              <Input.Search
                placeholder="Search user by name"
                onChange={(s) => setSearch(s.target.value)}
              />
            </div>
            <div className="quota-group-users-table group-table">
              <Table
                columns={columns.slice(0, -1)}
                dataSource={filterSearch(reformulateUsers(group?.userDetails?.users), search)}
                pagination={false}
                loading={false}
                sticky
                rowSelection={rowSelection}
                onRow={(record) => ({
                  onClick: () => setSeletedUser(record)
                })}
              />
            </div>
          </div>
        </div>
        <div className="confirmation">
          <button className="confirmation-cancel" type="reset" onClick={() => handleClose(id)}>
            Cancel
          </button>
          <button className="confirmation-confirm" onClick={submit}>
            {isLoading ? "Loading..." : "Confirm"}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateUserGroup;

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import closeIcon from "../../../../../assets/icons/dashboard-x-close.svg";
import NetworkPolicyForm from "../../NetworkPolicyForm/NetworkPolicyForm";
import { closeModal } from "../../../../../data/slices/modals";
import { useDispatch } from "react-redux";
import "./_NetworkPolicyModal.scss";
import { useTranslation } from "react-i18next";
function NetworkPolicyModal({ id, open, handleClose, data, ...rest }) {
  const dispatch = useDispatch();
  const { t } = useTranslation("policy");

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason === "escapeKeyDown" && handleClose(id)}
      className="ta-modal user_modal dashboard-user-modal"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1029px"
          }
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <div className="head_card">
          <span className="label">{t("policy-11")}</span>
          <p className="subtitle">{t("policy-12")}</p>
        </div>
        <span className="close-btn" onClick={() => dispatch(closeModal(id))}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>
      {/* <p className="subtitle">Choose a username and complete all user details.</p> */}

      <DialogContent className="ta-modal-content-scroll">
        <NetworkPolicyForm
          isEdit={data?.isEdit || false}
          id={data?.isEdit ? data?.id : null}
          network={data?.isEdit ? data?.network : null}
        />
      </DialogContent>
    </Dialog>
  );
}

export default NetworkPolicyModal;

import { Outlet, useNavigate, useParams } from "react-router-dom";
import AuthGuard from "../../components/AuthGuard";
import Header from "../../components/Header";
import Backdrop from "../../components/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import Confetti from "../../components/Confetti/Confetti";
import { Suspense, memo, useEffect } from "react";
import { login, logout } from "../../data/slices/authSlice";

const MainLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(document.location.search);
  const type = searchParams.get("type");
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (type === "demo" && user?.isDemo !== true) {
      dispatch(logout());
      dispatch(login({ state: "test1234", username: "demo-account", password: "demo-account" }))
        .unwrap()
        .then(() => {
          navigate(`/?type=demo`);
        });
    }
  }, []);

  return (
    <AuthGuard>
      <div className="main_layout">
        <Confetti time={3000} />
        <div className="main_layout__container">
          {!type && <Header isAdmin={false} />}
          <Suspense>
            <MainLayoutContent />
          </Suspense>
        </div>
        <Backdrop />
      </div>
    </AuthGuard>
  );
};

export default memo(MainLayout);

const MainLayoutContent = memo(() => {
  const searchParams = new URLSearchParams(document.location.search);
  const type = searchParams.get("type");

  const { mousePosition } = useSelector((state) => state.sessions);
  const { session } = useParams();
  const fullHeight = mousePosition >= 10 && document.fullscreenElement && session;
  return (
    <main
      style={{ paddingTop: !fullHeight ? "85px" : "0" }}
      className={`main_layout__container-content  ${
        type === "demo" ? "main_layout__container-content-no-padding" : ""
      } `}
    >
      <Suspense>
        <Outlet />
      </Suspense>
    </main>
  );
});

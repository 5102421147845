import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "./Feedback/Feedback";
import ScreenShot from "./ScreenShot/ScreenShot";
import { openModal } from "../data/slices/modals";
import { cancelEvent, saveMedia } from "../data/slices/feedback";
import { useLocation } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(document.location.search);
  const type = searchParams.get("type");

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { screenShot } = useSelector((state) => state.feedback);
  const dispatch = useDispatch();

  if (!isAuthenticated || !user) {
    const { pathname, search } = location;
    const callbackURL = pathname ? pathname + search : null;

    let url = `/auth/login${type ? "?type=demo" : ""}`;
    if (callbackURL && callbackURL !== "/" && callbackURL.indexOf("?state=") === -1) {
      url = `/auth/login${callbackURL ? `?callbackURL=${callbackURL}` : ""}`;
    }

    return <Navigate to={url} />;
  }

  return (
    <>
      <Feedback />
      {/* <ScreenRecording start={true} /> */}
      <ScreenShot
        start={screenShot}
        onCaptureEnd={(rs) => {
          dispatch(saveMedia({ blobURL: rs, type: "screenshot", icons: ["D"] }));
          dispatch(openModal("feedback-modal"));
        }}
        cancel={() => {
          dispatch(cancelEvent("screenShot"));
          dispatch(openModal("feedback-modal"));
        }}
      />
      {children}
    </>
  );
};

export default AuthGuard;

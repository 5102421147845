import NoPermissionImg from "./no-permission.svg";
import { Link } from "react-router-dom";

const NoPermissionView = () => {
  return (
    <div className="no-permission-page">
      <div className="no-permission-page-info">
        <h1 className="no-permission-page-title">Permission Denied!</h1>
        <p className="no-permission-page-subtitle">
          You do not have permission to access this page
        </p>
      </div>

      <img
        src={NoPermissionImg}
        alt="NoPermissionImg"
        style={{
          width: "347px"
        }}
      />

      <Link to={"/"} className="no-permission-page-btn">
        Go To Home
      </Link>
    </div>
  );
};

export default NoPermissionView;

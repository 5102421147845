import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../data/slices/modals";

function RequestDataModal({ id, open, handleClose, data = null, ...rest }) {
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(closeModal(id));
  };
  return (
    <Modal
      wrapClassName="modal-privacy"
      open={open}
      closable={false}
      footer={null}
      // maskClosable={true}
      centered
    >
      <div className="content-modal"></div>
      <div className="actions-modal">
        <Button className="btn-privacy cancel-btn" onClick={handleCancel}>
          Cancel{" "}
        </Button>
        <Button className="btn-privacy save-btn">Save </Button>
      </div>
    </Modal>
  );
}

export default RequestDataModal;

import React, { FC, useState, ChangeEvent, useRef } from "react";
import { message, Tag, Tooltip, Input, Button } from "antd";
import "./InputTag.scss";
import closeIcon from "../../assets/icons/close.svg";
import addIcon from "../../assets/icons/add.svg";
import { BsArrowReturnLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const TagInput = ({ value = [], onChange, placeholder, formik, ...props }) => {
  const { t } = useTranslation("policy");
  const [content, setContent] = useState();
  const inputRef = useRef();

  const handleDelete = (tag) => {
    const newArr = value.filter((i) => i !== tag);
    onChange(newArr);
    message.destroy();
  };

  const handleChange = (e) => {
    setContent(e.target.value);
    // onChange([...value, content]);
  };

  const handleBlur = () => {
    if (content) {
      if (value.includes(content)) {
        message.error(`[Url: ${content}] already exists`);
        return;
      }
      onChange([...value, content]);
      setContent("");
    }
  };

  const handleKeyPress = (e) => e.key === "Enter" && e.preventDefault();

  const handleKeyUp = (e) => {
    e.preventDefault();

    if (e.key === "Enter" && content) {
      if (value.includes(content)) {
        message.error(`[tag: ${content}] already exists`);
        return;
      }
      onChange([...value, content]);
      setContent("");
    }
  };

  const handleClear = () => {
    onChange([]);
  };
  const handleAdd = (e) => {
    e.preventDefault();
    if (content) {
      if (value.includes(content)) {
        message.error(`[Url: ${content}] already exists`);
        return;
      }
      onChange([...value, content]);
      setContent("");
    }
  };

  return (
    <div className="tags">
      <label htmlFor="url">{t("policy-30")}</label>
      <div className={`tagInput`} {...props}>
        <Input
          ref={inputRef}
          placeholder={placeholder}
          className="input_tag"
          value={content}
          onChange={handleChange}
          // onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          onKeyUp={handleKeyUp}
        />
        <button className="add_url_btn" onClick={(e) => handleAdd(e)}>
          {/* <img src={addIcon} alt="" />
           */}
          <BsArrowReturnLeft size={30} />
        </button>
      </div>
      <div className="tags_items">
        {value.map((item) =>
          item.length > 20 ? (
            <Tooltip title={item} key={item}>
              <Tag
                className="tag_url"
                closable
                closeIcon={<img src={closeIcon} />}
                onClose={() => handleDelete(item)}
              >{`${item.slice(0, 20)}...`}</Tag>
            </Tooltip>
          ) : (
            <Tag
              closable
              className="tag_url"
              closeIcon={<img src={closeIcon} />}
              key={item}
              onClose={() => handleDelete(item)}
            >
              {item}
            </Tag>
          )
        )}
      </div>
    </div>
  );
};

export default TagInput;

import { Dialog } from "@mui/material";
import CardIcon from "../../../assets/images/credit-card.png";
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import WrapperLoader from "../../WrapperLoader/WrapperLoader";
import { useDispatch } from "react-redux";
import { getCurrentPlan } from "../../../data/slices/currentPlan";

function SuccessPaymentModal({ id, open, handleClose, data = null, ...rest }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();
  const closeModal = () => {
    handleClose(id);
    setSearchParams("");
  };

  useEffect(() => {
    let timer;

    if (open) {
      // After 10 seconds, set isValid to true
      timer = setTimeout(() => {
        setIsValid(true);
        closeModal();
        dispatch(getCurrentPlan());
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick" && reason !== "escapeKeyDown" && isValid) {
          closeModal();
        }
      }}
      className="ta-modal success-payment confirm_modal"
    >
      <WrapperLoader status={!isValid}>
        <div>
          <img src={CardIcon} alt="Card Icon" />
          <p className={isValid ? "valid-status" : "invalid-status"}>Payment Validation</p>
          <p>Your Payment has been processed!</p>
        </div>
        <div className={"action_confirm"}>
          <button
            onClick={() => {
              closeModal();
            }}
            disabled={!isValid}
          >
            Close
          </button>
        </div>
      </WrapperLoader>
    </Dialog>
  );
}

export default SuccessPaymentModal;

import TextArea from "antd/es/input/TextArea";

const InputTextArea = (props) => {
  const { field, formik, value } = props;

  return (
    <span className="s_input">
      <label htmlFor={field?.name}>{field?.label}</label>
      <TextArea
        style={{
          height: 76,
          resize: "none"
        }}
        id={field?.name}
        name={field?.name}
        onChange={formik.handleChange}
        placeholder={field?.placeholder}
        value={value || formik?.values[field?.name]}
        onBlur={formik.handleBlur}
        status={formik.errors[field?.name] && formik.touched[field?.name] ? "error" : null}
      />
      <p className="error_txt">{formik.touched[field?.name] && formik.errors[field.name]}</p>
    </span>
  );
};

export default InputTextArea;

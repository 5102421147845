import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../data/slices/memberSlice";
import { Avatar, Button, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { BsFillCameraFill } from "react-icons/bs";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const ProfileAvatar = () => {
  const { t } = useTranslation("profile");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const updateUserPicHandler = (event) => {
    const file = event?.target?.files[0];

    // Check if file exists
    if (!file) {
      return;
    }

    // Check file size
    const maxSize = 3 * 1024 * 1024; // 3MB in bytes
    if (file.size > maxSize) {
      message.error(t("profile.Profile-60"));
      return;
    }

    // Check if the selected file has a valid extension
    const allowedExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file?.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      message.error(t("profile.Profile-53"));
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ph4slroc");

    fetch("https://api.cloudinary.com/v1_1/dj6kxvxqb/image/upload", {
      method: "POST",
      body: formData
    })
      .then((response) => response.json())
      .then((data) => {
        const imageURL = data?.secure_url;
        dispatch(updateUser({ values: { profilePicture: imageURL }, id: user?.username }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRemove = () => {
    Swal.fire({
      title: t("profile.Profile-54"),
      text: t("profile.Profile-55"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("profile.Profile-56")
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateUser({ values: { profilePicture: "" }, id: user?.username }));
      }
    });
  };

  return (
    <div className="profile-page-content-col-1-avatar">
      <div className="ProfileV2-page-photo-border">
        <div className="ProfileV2-page-photo">
          {user?.profilePicture ? (
            <img
              src={user?.profilePicture}
              style={{ width: "126px", height: "126px" }}
              alt="user-avatar"
            />
          ) : (
            <Avatar size={126} icon={<UserOutlined />} />
          )}

          <label htmlFor="update-my-pic" className="ProfileV2-page-photo-hover">
            <BsFillCameraFill color="#fff" size={32} />
            <p className="ProfileV2-page-photo-hover-title">{t("profile.Profile-57")}</p>
            {user?.profilePicture && (
              <Button type="primary" size="small" danger onClick={handleRemove}>
                Delete
              </Button>
            )}
          </label>
        </div>
      </div>

      <input
        type="file"
        id="update-my-pic"
        onChange={updateUserPicHandler}
        style={{ display: "none" }}
        accept=".png, .jpeg, .jpg, image/png, image/jpeg"
      />

      <p className="ProfileV2-page-photo-description">{t("profile.Profile-58")}</p>

      <h3 className="ProfileV2-page-photo-title">{user?.fullName}</h3>
    </div>
  );
};

export default ProfileAvatar;

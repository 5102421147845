import { Progress } from "antd";
import React from "react";

function limitDecimalPlaces(number = 0) {
  if (number === "undefined") return 0;
  const decimalCount = (number.toString().split(".")[1] || "")?.length;
  return decimalCount > 3 ? (+number)?.toFixed(3) : number;
}

const ResourceProgress = ({ icon, label, percent, usage, size }) => {
  const firstNumber = limitDecimalPlaces(usage?.split("/")[0] || "0");
  const secondNumber = limitDecimalPlaces(usage?.split("/")[1] || "0");

  return (
    <div className={"resource " + size}>
      <div className="resource-header">
        {icon}
        <p>{label}</p>
        <p>{firstNumber + "/" + secondNumber}</p>
      </div>
      <div className="resource-progress">
        <Progress percent={percent} showInfo={false} />
      </div>
    </div>
  );
};

export default ResourceProgress;

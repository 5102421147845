/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProfileOrg, initialise, setSession } from "../data/slices/authSlice";
import jwtDecode from "jwt-decode";
import axios from "../utils/axios";
import useIsMounted from "../hooks/useIsMountedRef";
import SplashScreen from "./SplashScreen/Splashscreen";
import axiosInstance from "../utils/axios";

// export const socket = io.connect("https://g2fz0c0t-3001.euw.devtunnels.ms/");

const AuthProvider = ({ children }) => {
  const isMounted = useIsMounted();

  const { isInitialised, isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const isValidToken = (token) => {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  };

  // const JoinRoom = () => {
  //   if (user) {
  //     socket.emit("join_room", user?.username);
  //   }
  // };

  // useEffect(() => {
  //   JoinRoom();
  // }, [user]);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    async function fetchUser() {
      try {
        let token = window.localStorage.getItem("gomydesk_token");
        if (token) {
          if (!isValidToken(token)) {
            const res = await axiosInstance.get("auth/refresh_token");
            const newAccessToken = res.data.token;
            localStorage.setItem("gomydesk_token", newAccessToken);
            axiosInstance.defaults.headers["X-Session-Token"] = newAccessToken;
            token = newAccessToken;
          }
          setSession(token);
          const response = await axios.get("profile/whoami");
          const user = response.data;
          axiosInstance.defaults.headers["X-User-ID"] = user?.username;
          dispatch(initialise({ isAuthenticated: true, user }));
        } else {
          dispatch(initialise({ isAuthenticated: false, user: null }));
          window.localStorage.removeItem("gomydesk_token");
        }
      } catch (error) {
        dispatch(initialise({ isAuthenticated: false, user: null }));
        window.localStorage.removeItem("gomydesk_token");
      }
    }
    fetchUser();
  }, []);

  useEffect(() => {
    if (!isMounted.current || !isAuthenticated) {
      return;
    }
    dispatch(getProfileOrg());
  }, [isAuthenticated]);

  if (!isInitialised) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export default AuthProvider;

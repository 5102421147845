import { Switch } from "antd";
import React from "react";
import "./_BooleanFeature.scss";

function BooleanFeature({ title, icon, formik, checked, onChange, disabled, type, content }) {
  return (
    <div className="boolean-item">
      <div className="ldap_checkbox">
        <div className="title_item">
          <img src={icon} alt="" />
          <p> {title}</p>
        </div>
        <div className="switch">
          <Switch disabled={disabled} checked={checked} onChange={onChange}></Switch>
          {/* <p className={checked ? "status-active" : "status-inactive"}>
          {checked ? "Active" : "Inactive"}
        </p> */}
        </div>
      </div>
      {type === "gpu" ? <div className="item-content">{content}</div> : null}
    </div>
  );
}

export default BooleanFeature;

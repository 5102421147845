import React, { useState } from "react";
import "./_ActionType.scss";
import { Button } from "antd";
import activeIcon from "../../assets/icons/activeCircle.svg";
import inactiveIcon from "../../assets/icons/normalCircle.svg";

function ActionType() {
  const [type, setType] = useState("allow");
  return (
    <div className="action_type">
      <label htmlFor="action_type">Action*</label>
      <div className="btn_action">
        <Button
          className={type === "block" ? `btn btn_active` : "btn"}
          onClick={() => setType(type === "allow" ? "block" : "allow")}
          disabled
          style={{
            cursor: "not-allowed",
            backgroundColor: "#EBEBE4 !important",
            color: "#C6C6C6",
            border: "1px solid #C6C6C6"
          }}
        >
          Block
          <img src={type === "block" ? activeIcon : inactiveIcon} alt="" />
        </Button>
        <Button
          className={type === "allow" ? `btn btn_active` : "btn"}
          // onClick={() => setType(type === "block" ? "allow" : "block")}
        >
          Allow
          <img src={type === "allow" ? activeIcon : inactiveIcon} alt="" />
        </Button>
      </div>
    </div>
  );
}

export default ActionType;

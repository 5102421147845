import { Outlet } from "react-router-dom";
import Backdrop from "../../components/Backdrop";
import { useDispatch } from "react-redux";
import { Suspense, memo, useEffect } from "react";
import { getSessions } from "../../data/slices/sessions";
import AuthGuard from "../../components/AuthGuard";

const LTiLayout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSessions());
  }, []);
  return (
    <AuthGuard>
      <div className="main_layout">
        <div className="main_layout__container">
          <Suspense>
            <LtiLayoutContent />
          </Suspense>
        </div>
        <Backdrop />
      </div>
    </AuthGuard>
  );
};

export default memo(LTiLayout);

const LtiLayoutContent = memo(() => {
  return (
    <main>
      <Suspense>
        <Outlet />
      </Suspense>
    </main>
  );
});

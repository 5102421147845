import { Drawer, Avatar, Tag } from "antd";
import closeIcon from "../../../../assets/icons/x-close.svg";
import Tabs from "../../../../components/Tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { changeGroupDetailsTab } from "../../../../data/slices/tabsSlice";
import useWindowSize from "../../../../hooks/useWindowSize";
import { useState } from "react";
import { useEffect } from "react";
import { List } from "antd";
import { getUsers } from "../../../../data/slices/memberSlice";
import { UserOutlined } from "@ant-design/icons";
import { fDateTime } from "../../../../utils/dateFormatter";
import { useTranslation } from "react-i18next";

function GroupDetails({ id, open, handleClose, data, ...rest }) {
  const { t } = useTranslation("role");
  let { width } = useWindowSize();
  const dispatch = useDispatch();
  const { groupDetailsTabs } = useSelector((state) => state.tabs);
  const { members, meta } = useSelector((state) => state.member);
  const [users, setUsers] = useState([]);
  const group = data?.group || null;

  const filterUsers = () => {
    let newUsers = [];
    for (let i = 0; i < members?.length; i++) {
      for (let k = 0; k < members[i]?.roles?.length; k++) {
        if (members[i]?.roles[k].name === group?.name) {
          newUsers.push(members[i]);
        }
      }
    }
    return newUsers;
  };

  const checkState = (key, value) => {
    if (key === "State" && value === "active") {
      return "active";
    } else if (key === "State" && value === "inactive") {
      return "inactive";
    }
  };
  let groupDetails = {
    Name: group?.displayName,
    Date: group ? fDateTime(group?.createdAt) : "--"
    // ImageGroup: group?.imageGroups?.map((item) => <Tag color="green">{item}</Tag>)
    //State: group?.isActive ? "active" : "inactive" // remove from the front temporary
  };

  const mappedObject = Object.keys(groupDetails).map((key) => (
    <div className="key_value" key={key}>
      <p className="key">{t(key)}</p>
      <p className={`${checkState(key, groupDetails[key])}`}>{groupDetails[key]}</p>
    </div>
  ));
  useEffect(() => {
    const filteredUsers = filterUsers();
    setUsers(filteredUsers);
  }, [group, meta]);

  useEffect(() => {
    if (open) dispatch(getUsers({ query: "" }));
  }, [group]);
  return (
    <Drawer
      placement="right"
      open={open}
      width={width > 1000 ? "35%" : "large"}
      closable={false}
      className="user_drawer"
    >
      <div className="head_user_drawer">
        <div className="user_avatar">
          <span>
            <p className="title">{group?.name || group?.metadata?.name}</p>
          </span>
        </div>
        <div className="close_ion" onClick={() => handleClose(id)}>
          <img src={closeIcon}></img>
        </div>
      </div>
      {/* //content drawer  */}
      <div className="user_content">
        <Tabs
          tabs={groupDetailsTabs?.tabs?.map((item) => {
            return { ...item, label: t(item.label) };
          })}
          defaultTab={groupDetailsTabs.activeTab}
          setTab={changeGroupDetailsTab}
          type={groupDetailsTabs.type}
        />

        {groupDetailsTabs.activeTab === "0" ? (
          <div className="user_details">
            {mappedObject}
            <ListUsers users={users} meta={meta} />
          </div>
        ) : (
          <div className="user_settings">group settings</div>
        )}
      </div>
    </Drawer>
  );
}

export default GroupDetails;

const ListUsers = ({ users, meta }) => {
  const { t } = useTranslation("role");

  return (
    <>
      <div className="title_list">{t("Users")}</div>
      <div className="title_name_list">{t("Name")}</div>
      <List
        dataSource={users}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} src={item?.profilePicture} />}
              title={item?.fullName}
              description={item?.email}
            />
          </List.Item>
        )}
      />
    </>
  );
};

import { Dialog, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import closeIcon from "../../assets/icons/x-close.svg";
import noDataIcon from "../../assets/icons/no-data.png";
import { ReactComponent as FailSVG } from "../../assets/icons/snapshot-fail.svg";
import { Avatar } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getSnapshots } from "../../data/slices/snapshot";
import { useTranslation } from "react-i18next";

const reformulate = (originalDateString) => {
  const originalDate = new Date(originalDateString);

  const formattedDateResult = originalDate.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });
  return formattedDateResult;
};

const SnapshotList = ({ id, open, handleClose, data = null, ...rest }) => {
  const { t } = useTranslation("snapshot");

  const [view, setView] = useState(1);
  const { snapshots } = useSelector((state) => state.snapshot);
  const dispatch = useDispatch();
  useEffect(() => {
    if (open) dispatch(getSnapshots({}));
    const interval = setInterval(() => {
      if (open) dispatch(getSnapshots({}));
    }, 5000); // 5 seconds in milliseconds

    return () => clearInterval(interval); // Cleanup function to clear interval
  }, [open, dispatch]);
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      className="ta-modal  assign_modal snapshot-list-modal"
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        {/* <img className="img_assign" src={deleteIcon} alt="" /> */}
        <p className="title">{t("snapshot-1")}</p>

        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>

      <DialogContent className="ta-modal-content-scroll">
        <div className="tabs">
          <span onClick={() => setView(1)} className={`${view === 1 ? "active-tab" : ""}`}>
            {t("snapshot-2")}
          </span>
          <span onClick={() => setView(2)} className={`${view === 2 ? "active-tab" : ""}`}>
            {t("snapshot-3")}
          </span>
        </div>
        {view === 1 ? (
          <div className="snapshot-list list-view">
            {snapshots?.length > 0 ? (
              <>
                <div className="snapshot-list-header">
                  <div>{t("snapshot-4")}</div>
                  <div>{t("snapshot-5")}</div>
                  <div>{t("snapshot-6")}</div>
                  <div>{t("snapshot-7")}</div>
                </div>
                {snapshots?.map((snapshot) => {
                  return (
                    <div className="snapshot-item-list">
                      <div className="col1">
                        <div className="snapshot-item-image">
                          <Avatar src={snapshot?.icon}>
                            {snapshot?.displayName && snapshot?.displayName[0]?.toLocaleUpperCase()}
                          </Avatar>
                        </div>
                        <p className="snapshot-item-name">{snapshot?.displayName}</p>
                        <div
                          className={`snapshot-item-status ${
                            snapshot?.status === "Failed"
                              ? "fail"
                              : snapshot?.status === "InProgress"
                                ? "inprogress"
                                : "success"
                          }`}
                        >
                          {snapshot?.status === "Failed" ? (
                            <p>
                              {t("snapshot-8")}
                              <Tooltip title={snapshot?.failWith || "something went wrong"}>
                                <FailSVG />
                              </Tooltip>
                            </p>
                          ) : snapshot?.status === "InProgress" ? (
                            <p>{t("snapshot-9")}</p>
                          ) : (
                            <p>{t("snapshot-10")}</p>
                          )}
                        </div>
                      </div>

                      <p className="snapshot-item-user col2">
                        <Avatar />
                        {snapshot?.userName}
                      </p>
                      <div className="snapshot-item-user-groups col2">
                        {snapshot?.categories?.map((cat) => (
                          <div className="desktop-group-item">{cat}</div>
                        ))}
                      </div>
                      <p className="snapshot-item-created col2">
                        {reformulate(snapshot.createdAt)}
                      </p>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="no-data">
                <img src={noDataIcon} />
                {t("snapshot-11")}
              </div>
            )}
          </div>
        ) : (
          <div className="snapshot-list">
            {snapshots?.length > 0 ? (
              snapshots?.map((snapshot) => {
                return (
                  <div className="snapshot-item">
                    <div className="snapshot-item-col1">
                      <div className="snapshot-item-image">
                        <Avatar src={snapshot?.icon}>
                          {snapshot?.displayName && snapshot?.displayName[0]?.toLocaleUpperCase()}
                        </Avatar>
                      </div>
                      <div className="snapshot-item-info">
                        <p className="snapshot-item-name">{snapshot?.displayName}</p>
                        <p className="snapshot-item-created">
                          {t("snapshot-12")}: {reformulate(snapshot.createdAt)}
                        </p>
                        <p className="snapshot-item-user">
                          <Avatar />
                          {snapshot?.userName}
                        </p>
                      </div>
                    </div>
                    <div className="snapshot-item-col2">
                      <div className="snapshot-item-description">{snapshot?.description}</div>
                      <div className="snapshot-item-user-groups">
                        {snapshot?.categories?.map((cat) => (
                          <div className="desktop-group-item">{cat}</div>
                        ))}
                      </div>
                      <div
                        className={`snapshot-item-status ${
                          snapshot?.status === "Failed"
                            ? "fail"
                            : snapshot?.status === "InProgress"
                              ? "inprogress"
                              : "success"
                        }`}
                      >
                        {snapshot?.status === "Failed" ? (
                          <p>
                            {t("snapshot-13")}
                            <Tooltip title={snapshot?.failWith || "something went wrong"}>
                              <FailSVG />
                            </Tooltip>
                          </p>
                        ) : snapshot?.status === "InProgress" ? (
                          <p>{t("snapshot-14")}</p>
                        ) : (
                          <p>{t("snapshot-15")}</p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="no-data">
                <img src={noDataIcon} />
                {t("snapshot-16")}
              </div>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SnapshotList;

/*
 <div className="snapshot-item">
                  <div className="snapshot-item-col1">
                    <div className="snapshot-item-image">
                      <Avatar src={snapshot?.icon}>
                        {snapshot?.displayName && snapshot?.displayName[0]?.toLocaleUpperCase()}
                      </Avatar>
                    </div>
                    <div className="snapshot-item-info">
                      <p className="snapshot-item-name">{snapshot?.displayName}</p>
                      <p className="snapshot-item-created">
                        Created: {reformulate(snapshot.createdAt)}
                      </p>
                      <p className="snapshot-item-user">
                        <Avatar />
                        {snapshot?.userName}
                      </p>
                    </div>
                  </div>
                  <div className="snapshot-item-col2">
                    <div className="snapshot-item-description">{snapshot?.description}</div>
                    <div className="snapshot-item-user-groups">
                      {snapshot?.categories?.map((cat) => (
                        <div className="desktop-group-item">{cat}</div>
                      ))}
                    </div>
                    <div
                      className={`snapshot-item-status ${
                        snapshot?.status === "Failed"
                          ? "fail"
                          : snapshot?.status === "InProgress"
                          ? "inprogress"
                          : "success"
                      }`}
                    >
                      {snapshot?.status === "Failed" ? (
                        <p>
                          Creation failed
                          <Tooltip title={snapshot?.failWith || "something went wrong"}>
                            <FailSVG />
                          </Tooltip>
                        </p>
                      ) : snapshot?.status === "InProgress" ? (
                        <p>In Progress</p>
                      ) : (
                        <p>Successfully created</p>
                      )}
                    </div>
                  </div>
                </div>
*/

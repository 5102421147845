import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ReactComponent as CloseIcon } from "./../../assets/icons/x-close.svg";
import React, { useEffect } from "react";
import { getFileType } from "../FileTransfer/FileTransfer";
import { message } from "antd";

const createObjectURLWithFallback = (src) => {
  try {
    const url = URL.createObjectURL(src);
    return url;
  } catch (error) {
    message.error("Error while creating object URL");
    throw Error("Error while creating object URL");
  }
};

const renderImage = (data) => {
  const url = createObjectURLWithFallback(data?.src);
  return (
    <div className="file-preview">
      <img src={url} autoPlay controls />
    </div>
  );
};

const renderVideo = (data) => {
  const url = createObjectURLWithFallback(data?.src);
  return (
    <div className="file-preview">
      <video src={url} autoPlay controls />
    </div>
  );
};

const renderAudio = (data) => {
  const url = createObjectURLWithFallback(data?.src);
  window.open(url);
};

const renderText = (data) => {
  return <div className="file-preview">{data?.src}</div>;
};

const renderPdf = (data) => {
  const file = new Blob([data?.src], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

const renderError = () => {
  return (
    <div className="file-preview error-message">
      <p>Sorry, this file type is not supported for preview.</p>
      <p>Please download the file to view its contents.</p>
    </div>
  );
};

const fileTypes = {
  image: renderImage,
  video: renderVideo,
  pdf: renderPdf,
  audio: renderAudio,
  text: renderText
};

const renderFile = (data) => {
  const fileType = getFileType(data?.type);
  try {
    return fileTypes[fileType](data);
  } catch (error) {
    return renderError();
  }
};

function FilePreviewModal({ id, open, handleClose, data, ...rest }) {
  useEffect(() => {
    if (["pdf", "audio"].includes(data?.type)) handleClose(id);
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason === "escapeKeyDown" && handleClose(id)}
      className="ta-modal file-preview-modal"
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <span className="title">File Preview</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          <CloseIcon />
        </span>
      </DialogTitle>
      <h3 className="file-title">{data?.name}</h3>
      <DialogContent>{open && renderFile(data)}</DialogContent>
    </Dialog>
  );
}

export default FilePreviewModal;

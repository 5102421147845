import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { InputText } from "../../../../components/InputFields";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-icon.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-icon.svg";
import { ReactComponent as MailIcon } from "../../../../assets/icons/mail-01 (1).svg";
import { closeModal, openModal } from "../../../../data/slices/modals";
import { sendInvitation } from "../../../../data/slices/memberSlice";
import { getCurrentPlan } from "../../../../data/slices/currentPlan";

import { message } from "antd";
import { useTranslation } from "react-i18next";

function InviteUserForm() {
  const { t } = useTranslation("user");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { currentPlan, planStatus } = useSelector((state) => state.currentPlan);
  const { usersCount } = useSelector((state) => state.stats);

  useEffect(() => {
    if (!currentPlan || currentPlan?.length === 0) {
      dispatch(getCurrentPlan());
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      emails: [""]
    },
    validationSchema: Yup.object().shape({
      emails: Yup.array()
        .of(Yup.string().email().required(t("user-20")))
        .test("unique-emails", "Emails must be unique", function (value) {
          const uniqueEmails = [...new Set(value)];
          return uniqueEmails.length === value.length;
        })
    }),
    onSubmit: (values) => {
      if (planStatus != "active" || currentPlan?.numberOfUsersFeature?.number <= usersCount) {
        message.error("You've reached the maximum user limit for your organization");
        return;
      }
      setLoading(true);
      dispatch(sendInvitation(values)).then((res) => {
        setLoading(false);
        if (!res.error) {
          dispatch(closeModal("invite-user-modal"));
          dispatch(openModal("invite-user-list-modal", { ...values, response: res.payload }));
        } else {
          message.error(res.error?.message || t("user-21"));
        }
      });
    }
  });
  const handleAddEmail = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!formik.values.emails.includes(""))
        formik.setFieldValue("emails", [...formik.values.emails, ""]);
    }
  };

  return (
    <div className="form_user invite-users-form">
      <FormikProvider value={formik}>
        <form action="" onSubmit={formik.handleSubmit} onKeyDown={handleAddEmail}>
          <label>{t("user-22")}</label>
          <FieldArray
            name={"emails"}
            render={(arrayHelpers) => (
              <div className="email-input">
                <div className="email-input-container">
                  {formik.values?.["emails"]?.map((_, index) => {
                    const currentEmail = formik.values?.["emails"][index];
                    const error =
                      formik.errors["emails"] && formik.touched["emails"]
                        ? formik.errors["emails"][index] && formik.touched["emails"][index]
                          ? formik.errors["emails"][index]
                          : null
                        : null;

                    // Check for duplicated emails
                    const isDuplicate =
                      formik.values?.["emails"].filter(
                        (email, idx) => email === currentEmail && idx !== index
                      ).length > 0;

                    return (
                      <div key={index}>
                        <div className={`input-container ${error && isDuplicate ? "error" : ""}`}>
                          {index > 0 ? (
                            <div className="input-menu">
                              <CloseIcon onClick={() => arrayHelpers.remove(index)} />
                            </div>
                          ) : null}
                          <InputText
                            field={{
                              name: `emails.${index}`,
                              type: "string",
                              placeholder: t("user-23")
                            }}
                            value={formik.values?.["emails"][index]}
                            formik={formik}
                            prefix={<MailIcon />}
                            autoFocus={formik.values?.["emails"]?.length - 1 === index}
                          />
                          {/* {error && isDuplicate ? (
                            <span className="error-message">{error}</span>
                          ) : null} */}
                        </div>
                      </div>
                    );
                  })}
                  <div
                    className="plus-btn-container"
                    onClick={() => {
                      if (formik?.errors?.emails) {
                        return;
                      }
                      arrayHelpers.push("");
                    }}
                  >
                    <PlusIcon /> {t("user-24")}
                  </div>
                </div>
              </div>
            )}
          />

          <div className="action_form">
            <button
              type="reset"
              onClick={() => {
                dispatch(closeModal("invite-user-modal"));
              }}
              className="dashboard-cancel-btn"
            >
              {t("user-25")}
            </button>
            <button type="submit" className="dashboard-confirm-btn">
              {loading ? t("user-26") : t("user-27")}
            </button>
          </div>
        </form>
      </FormikProvider>
    </div>
  );
}

export default InviteUserForm;

import NotFound from "./not-found.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFoundView = () => {
  const { t } = useTranslation("public");

  return (
    <div className="not-found-page">
      <div className="not-found-page-info">
        <h1 className="not-found-page-title">{t("public-1")}</h1>
        <p className="not-found-page-subtitle">
          {t("public-2")} <br /> {t("public-3")}
        </p>
      </div>

      <img
        src={NotFound}
        alt="NotFound"
        style={{
          width: "347px"
        }}
      />

      <Link to={"/"} className="not-found-page-btn">
        {t("public-4")}
      </Link>
    </div>
  );
};

export default NotFoundView;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../data/slices/modals";
import TemplateModal from "../../features/Desktops/components/Modals/TemplateModal/TemplateModal";
import ImageGroupModal from "../../features/ImageGroup/components/Modals/GroupModal/GroupModal";
import ImageModal from "../../features/ImageGroup/components/Modals/ImageModal/ImageModal";
import UserGroupModal from "../../features/UserGroup/components/Modals/GroupModal/GroupModal";
import UserModal from "../../features/Users/components/Modals/UserModal";
import FilePreviewModal from "../FilePreview/FilePreview";
import FileTransferModal from "../FileTransfer/FileTransfer";
import { ModalExample, ModalConfirm } from "../Modals";
import AssignModal from "../Modals/AssignModal/AssignModal";
import SuccessPaymentModal from "../Modals/SuccessPaymentModal/SuccessPaymentModal";
import InviteUserModal from "../../features/Users/components/Modals/InviteUserModal/InviteUserModal";
import FeedbackModal from "../Feedback";
import RDPTemplateModal from "../../features/Desktops/components/Modals/TemplateModal/RDPTemplateModal";
import ReconnectModal from "../../features/VNCViewer/components/ReconnectModal/ReconnectModal";
import InviteUserListModal from "../../features/Users/components/Modals/InviteUserListModal/InviteUserListModal";
import NetworkPolicyModal from "../../features/NetworkPolicy/components/Modals/NetworkPolicyModal/NetworkPolicyModal";
import StreamQualityModal from "../../features/VNCViewer/components/StreamQualityModal/StreamQualityModal";
import CancelModal from "../../features/Billing/components/CancelModalConfirm/CancelModal";
import UpgradeModal from "../Modals/UpgradeModal/UgradeModal";
import CreateGroup from "../../features/QuotaManagment/components/Modals/CreateGroup/CreateGroup";
import UpdateUserGroup from "../../features/QuotaManagment/components/Modals/UpdateUserGroup/UpdateUserGroup";
import SnapshotModal from "../../features/VNCViewer/components/SnapshotModal/SnapshotModal";
import SnapshotList from "../SnapshotList/SnapshotList";
import RequestQuoteModal from "../../features/Offers/components/RequestQuoteModal/RequestQuoteModal";
import RequestDataModal from "../Modals/RequestDataModal/RequestDataModal";

const ModalsProvider = (props) => {
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };
  const handleClose = (id) => {
    dispatch(closeModal(id));
  };
  return (
    <>
      <ModalExample
        id="modal-example"
        open={modalState("modal-example", "open")}
        data={modalState("modal-example", "data")}
        handleClose={handleClose}
      />
      <UserModal
        id="user-modal"
        open={modalState("user-modal", "open")}
        data={modalState("user-modal", "data")}
        handleClose={handleClose}
      />
      <InviteUserModal
        id="invite-user-modal"
        open={modalState("invite-user-modal", "open")}
        data={modalState("invite-user-modal", "data")}
        handleClose={handleClose}
      />
      <ModalConfirm
        id="confirm-modal"
        open={modalState("confirm-modal", "open")}
        data={modalState("confirm-modal", "data")}
        handleClose={handleClose}
      />
      <UserGroupModal
        id="user-group"
        open={modalState("user-group", "open")}
        data={modalState("user-group", "data")}
        handleClose={handleClose}
      />
      <ImageGroupModal
        id="image-group"
        open={modalState("image-group", "open")}
        data={modalState("image-group", "data")}
        handleClose={handleClose}
      />
      <ImageModal
        id="image-modal"
        open={modalState("image-modal", "open")}
        data={modalState("image-modal", "data")}
        handleClose={handleClose}
      />
      <FileTransferModal
        id="file-transfer-modal"
        open={modalState("file-transfer-modal", "open")}
        data={modalState("file-transfer-modal", "data")}
        handleClose={handleClose}
      />
      <FilePreviewModal
        id="file-preview-modal"
        open={modalState("file-preview-modal", "open")}
        data={modalState("file-preview-modal", "data")}
        handleClose={handleClose}
      />

      <TemplateModal
        id="template-modal"
        open={modalState("template-modal", "open")}
        data={modalState("template-modal", "data")}
        handleClose={handleClose}
      />
      <RDPTemplateModal
        id="template-modal-rdp"
        open={modalState("template-modal-rdp", "open")}
        data={modalState("template-modal-rdp", "data")}
        handleClose={handleClose}
      />
      <AssignModal
        id="assign-modal"
        open={modalState("assign-modal", "open")}
        data={modalState("assign-modal", "data")}
        handleClose={handleClose}
      />
      <SuccessPaymentModal
        id="success-payment-modal"
        open={modalState("success-payment-modal", "open")}
        data={modalState("success-payment-modal", "data")}
        handleClose={handleClose}
      />
      <FeedbackModal
        id="feedback-modal"
        open={modalState("feedback-modal", "open")}
        data={modalState("feedback-modal", "data")}
        handleClose={handleClose}
      />
      <ReconnectModal
        id="reconnect-desktop-modal"
        open={modalState("reconnect-desktop-modal", "open")}
        data={modalState("reconnect-desktop-modal", "data")}
        handleClose={handleClose}
      />
      <InviteUserListModal
        id="invite-user-list-modal"
        open={modalState("invite-user-list-modal", "open")}
        data={modalState("invite-user-list-modal", "data")}
        handleClose={handleClose}
      />
      <NetworkPolicyModal
        id="network-policy-modal"
        open={modalState("network-policy-modal", "open")}
        data={modalState("network-policy-modal", "data")}
      />
      <StreamQualityModal
        id="stream-quality-modal"
        open={modalState("stream-quality-modal", "open")}
        data={modalState("stream-quality-modal", "data")}
        handleClose={handleClose}
      />
      <CancelModal
        id="cancel-plan-modal"
        open={modalState("cancel-plan-modal", "open")}
        data={modalState("cancel-plan-modal", "data")}
        handleClose={handleClose}
      />
      <UpgradeModal
        id="upgrade-modal"
        open={modalState("upgrade-modal", "open")}
        data={modalState("upgrade-modal", "data")}
        handleClose={handleClose}
      />
      <UpgradeModal
        id="upgrade-modal"
        open={modalState("upgrade-modal", "open")}
        data={modalState("upgrade-modal", "data")}
        handleClose={handleClose}
      />
      <CreateGroup
        id="create-quota-group-modal"
        open={modalState("create-quota-group-modal", "open")}
        data={modalState("create-quota-group-modal", "data")}
        handleClose={handleClose}
      />
      <UpdateUserGroup
        id="update-user-group-modal"
        open={modalState("update-user-group-modal", "open")}
        data={modalState("update-user-group-modal", "data")}
        handleClose={handleClose}
      />
      <SnapshotModal
        id="snapshot-modal"
        open={modalState("snapshot-modal", "open")}
        data={modalState("snapshot-modal", "data")}
        handleClose={handleClose}
      />
      <SnapshotList
        id="snapshot-list-modal"
        open={modalState("snapshot-list-modal", "open")}
        data={modalState("snapshot-list-modal", "data")}
        handleClose={handleClose}
      />
      <RequestQuoteModal
        id="request-quote-modal"
        open={modalState("request-quote-modal", "open")}
        data={modalState("request-quote-modal", "data")}
        handleClose={handleClose}
      />
      {/* <RequestDataModal
        id="request-data-modal"
        open={modalState("request-data-modal", "open")}
        data={modalState("request-data-modal", "data")}
        handleClose={handleClose}
      /> */}
    </>
  );
};

export default ModalsProvider;

/* eslint-disable no-useless-escape */
export function checkPasswordStrength(password) {
  if (!password) {
    return "";
  }

  // Define regex patterns for different character types
  const lowercaseRegex = /[a-z]/;
  const uppercaseRegex = /[A-Z]/;
  const numberRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

  // Check password length
  const lengthScore = password.length >= 8 ? 1 : 0;

  // Check the presence of different character types
  const lowercaseScore = lowercaseRegex.test(password) ? 1 : 0;
  const uppercaseScore = uppercaseRegex.test(password) ? 1 : 0;
  const numberScore = numberRegex.test(password) ? 1 : 0;
  const specialCharScore = specialCharRegex.test(password) ? 1 : 0;

  // Calculate total score
  const totalScore = lengthScore + lowercaseScore + uppercaseScore + numberScore + specialCharScore;

  // You can customize this scoring system based on your specific requirements

  // Return a strength level based on the total score
  if (totalScore === 5) {
    return "Strong";
  } else if (totalScore >= 3) {
    return "Medium";
  } else {
    return "Weak";
  }
}

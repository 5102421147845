import React from "react";
import { Modal, message } from "antd";
import closeIcon from "../../../../assets/icons/x-close.svg";
import "./_RequestQuoteModal.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText, InputTextArea } from "../../../../components/InputFields";
import { MdOutlineContactSupport } from "react-icons/md";
import { useDispatch } from "react-redux";
import { RequestQuote } from "../../../../data/slices/offers";

const RequestQuoteModal = ({ id, open, handleClose, data, ...rest }) => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    handleClose(id);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      enterpriseName: "",
      message: "",
      email: ""
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("This field is required"),
      lastName: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("This field is required"),
      enterpriseName: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("This field is required"),
      message: Yup.string()
        .min(2, "Too Short!")
        .max(1550, "Too Long!")
        .required("This field is required"),
      email: Yup.string().email().required("This field is required")
    }),
    onSubmit: async (values) => {
      let requestBody = {
        feedbackType: "contact",
        email: values.email,
        title: `Request A Quote :${values.email} `,
        comment: `Name: ${values.firstName} ${values.lastName}\nEnterprise Name: ${values.enterpriseName}\nMessage: ${values.message}\n`
      };
      dispatch(RequestQuote(requestBody))
        .unwrap()
        .then((res) => {
          message.success("Request Quote Created Successfully");
          handleClose(id);
          formik.resetForm();
        })
        .catch((err) => {
          message.error(err.message || "Something went wrong");
        });
    }
  });
  return (
    <Modal
      wrapClassName="modal-quote"
      open={open}
      closable={false}
      footer={null}
      maskClosable={true}
    >
      <div className="header-quote">
        <MdOutlineContactSupport color="#0188f7" size={25} />
        <p className="title">Request a quote</p>
        <img src={closeIcon} alt="" className="close-quote" onClick={handleCancel} />
      </div>
      <div className="modal-content-quote">
        <form action="" onSubmit={formik.handleSubmit} className="quote-form">
          <div className="top-section-quote">
            <InputText
              field={{
                name: "firstName",
                type: "text",
                placeholder: "Enter your First Name",
                label: "First Name *"
              }}
              formik={formik}
            />
            <InputText
              field={{
                name: "lastName",
                type: "text",
                placeholder: "Enter your Last Name",
                label: "Last Name *"
              }}
              formik={formik}
            />
            <InputText
              field={{
                name: "email",
                type: "email",
                placeholder: "Enter your Email",
                label: "Email *"
              }}
              formik={formik}
            />
            <InputText
              field={{
                name: "enterpriseName",
                type: "text",
                placeholder: "Enter your enterprise Name",
                label: "Enterprise Name *"
              }}
              formik={formik}
            />
          </div>

          <InputTextArea
            field={{
              name: "message",
              type: "textarea",
              placeholder: "message ...",
              label: "Message *"
            }}
            formik={formik}
          />
        </form>
      </div>
      <div className="action-quote">
        <button
          onClick={() => {
            handleClose(id);
            formik.setValues({});
          }}
          className="dashboard-cancel-btn"
        >
          Cancel
        </button>
        <button onClick={formik.handleSubmit} type="submit" className="dashboard-confirm-btn">
          Request
        </button>
      </div>
    </Modal>
  );
};
export default RequestQuoteModal;

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import closeIcon from "./../../../assets/icons/dashboard-x-close.svg";
import { ReactComponent as BackIcon } from "./../../../assets/icons/fi-rr-angle-left.svg";
import FeedbackForm from "../FeedbackForm/FeedbackForm";
import { useDispatch, useSelector } from "react-redux";
import { setFeedbackType } from "../../../data/slices/feedback";
import { useTranslation } from "react-i18next";

const FeebackType = ({ code }) => {
  const { t } = useTranslation("other");

  const feedbackTypes = {
    1: {
      name: t("other-4"),
      description: t("other-5")
    },
    2: {
      name: t("other-6"),
      description: t("other-7")
    },
    3: {
      name: t("other-8"),
      description: t("other-9")
    }
  };

  const { name, description } = feedbackTypes[code] || {};
  const dispatch = useDispatch();

  return (
    <div className="feedback-type" onClick={() => dispatch(setFeedbackType(code))}>
      <div className="feedback-type-icon">
        <svg width="30" height="24" viewBox="0 0 30 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.25 0.875H8.5V2.5H11.75V5.75H18.25V2.5H21.5V0.875H24.75V4.125H21.5V5.75V7.375H24.75V10.625H26.375V5.75H29.625V13.875H26.375V18.75H23.125V23.625H19.875H16.625V20.375H19.875V18.75H10.125V20.375H13.375V23.625H10.125H6.875V18.75H3.625V13.875H0.375V5.75H3.625V10.625H5.25V7.375H8.5V5.75V4.125H5.25V0.875ZM8.5 15.5H11.75V10.625H8.5V15.5ZM18.25 15.5H21.5V10.625H18.25V15.5Z"></path>
        </svg>
      </div>
      <div className="feedback-type-content">
        <p>{name}</p>
        <p>{description}</p>
      </div>
    </div>
  );
};

function FeedbackModal({ id, open, handleClose, data, ...rest }) {
  const { t } = useTranslation("other");

  const feedbackTypes = {
    1: {
      name: t("other-4"),
      description: t("other-5")
    },
    2: {
      name: t("other-6"),
      description: t("other-7")
    },
    3: {
      name: t("other-8"),
      description: t("other-9")
    }
  };

  const { feedbackType } = useSelector((state) => state.feedback);
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason === "escapeKeyDown" && handleClose(id)}
      className="ta-modal user_modal feedback-modal group_modal dashboard-group-modal"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "420px"
          }
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        {feedbackType ? (
          <span className="back" onClick={() => dispatch(setFeedbackType(null))}>
            <BackIcon />
          </span>
        ) : null}
        {feedbackType ? <span className="label">{feedbackTypes[feedbackType]?.name}</span> : null}
        <span
          className="close-btn"
          onClick={() => {
            dispatch(setFeedbackType(null));
            handleClose(id);
          }}
        >
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>

      <DialogContent className="ta-modal-content-scroll">
        <div className="feedback-container">
          {feedbackType ? (
            <FeedbackForm type={feedbackType} />
          ) : (
            <>
              <FeebackType code={1} />
              <FeebackType code={2} />
              <FeebackType code={3} />
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default FeedbackModal;

import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
const initInvoices = (data) => {
  return data.map((member) => {
    return { ...member, checked: false };
  });
};
const initialState = {
  invoices: null,
  meta: {},
  payMethods: null,
  state: "idle",
  error: null,
  message: null,
  loading: null,
  editPaymentState: false,
  invoicesLoading: false
};

export const getPayMethod = createAsyncThunk("api/get-active-method", async () => {
  let data;
  try {
    const response = await axios.get(`/payment-methods`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const getInvoices = createAsyncThunk("api/get-active-invoices", async (query) => {
  let data;
  try {
    const response = await axios.get(`/invoices`, { params: query });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const editPayMethod = createAsyncThunk("api/edit-active-method", async () => {
  let data;
  try {
    const response = await axios.post(`/payment-methods/portal-session`);
    data = await response.data;
    if (response.status === 200) {
      window.open(data?.url, "_blank");
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const BillingSlice = createSlice({
  name: "billingSlice",
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.invoices = current(state).invoices.map((group) => {
        return { ...group, checked: action.payload };
      });
    },
    selectById: (state, action) => {
      state.invoices = current(state).invoices.map((group) => {
        if (group?.id === action.payload.id) return { ...group, checked: action.payload.value };
        return group;
      });
    },
    resetBillingData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    //edit pay meth
    [editPayMethod.pending]: (state) => {
      state.error = null;
      state.editPaymentState = true;
    },
    [editPayMethod.fulfilled]: (state, action) => {
      state.editPaymentState = false;
    },
    [editPayMethod.rejected]: (state, action) => {
      state.state = "error";
      state.editPaymentState = false;
    },
    //get pay
    [getPayMethod.pending]: (state) => {
      state.error = null;
      state.state = "loading";
      state.editPaymentState = true;
    },
    [getPayMethod.fulfilled]: (state, action) => {
      state.payMethods = action?.payload?.paymentMethods;
      state.editPaymentState = false;
    },
    [getPayMethod.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
      state.editPaymentState = false;
    },
    //get invoices
    [getInvoices.pending]: (state) => {
      state.error = null;
      state.state = "loading";
      state.invoicesLoading = true;
    },
    [getInvoices.fulfilled]: (state, action) => {
      if (action?.payload?.invoices) {
        state.invoices = { ...action?.payload, data: initInvoices(action?.payload?.invoices) };
      }
      state.invoicesLoading = false;
    },
    [getInvoices.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
      state.invoicesLoading = false;
    }
  }
});

export const { selectAll, selectById, resetBillingData } = BillingSlice.actions;

export default BillingSlice.reducer;

import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { IMAGES } from "../../constants";
import axios from "../../utils/axios";

const initialState = {
  allTemplates: null,
  templates: {
    data: null,
    state: "idle",
    error: null,
    message: null,
    loading: null
  },
  groupedTemplates: {
    data: null,
    state: "idle",
    error: null,
    message: null,
    loading: null
  },
  meta: {},
  searchItems: [],
  searchString: ""
};
//get all templates
export const getAllTemplates = createAsyncThunk("api/templatesall", async () => {
  let data;
  try {
    const response = await axios.get(`/templates`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});
//get templates
export const getTemplates = createAsyncThunk("api/templates", async ({ query }) => {
  let data;
  // let str = page && limit ? `?page=${page}&limit=${limit}` : "";
  try {
    const url = query ? `/templates${query}` : `/templates`;
    const response = await axios.get(url);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});
//get grouped templates
export const getGroupedTemplates = createAsyncThunk("api/templates-grouped", async () => {
  let data;
  try {
    const response = await axios.get(`/templates?isGrouped=true`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const createTemplate = createAsyncThunk("api/create-image", async (values, thunkApi) => {
  let data;
  try {
    const response = await axios.post(`/templates`, {
      ...values
    });
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getTemplates({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const updateImage = createAsyncThunk("api/update-image", async (query, thunkApi) => {
  let data;
  let { values, id } = query;
  try {
    const response = await axios.put(`/templates/${id}`, { ...values });
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getTemplates({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const deleteImage = createAsyncThunk("api/delete-image", async (id, thunkApi) => {
  let data;
  try {
    const response = await axios.delete(`/templates/${id}`);
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getTemplates({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return thunkApi.rejectWithValue(error ? error?.error : "Something went wrong");
  }
});

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.templates.data = current(state).templates.data.map((image) => {
        return { ...image, checked: action.payload };
      });
    },
    selectById: (state, action) => {
      state.templates.data = current(state).templates.data.map((image) => {
        if (image?.name === action.payload.id) return { ...image, checked: action.payload.value };
        return image;
      });
    },
    filterTemplates: (state, action) => {
      let { categId } = action.payload;
      let newData = [...state.templates.data];
      let filtredTemplates = newData.filter((el, index) => el.categorieId === categId);
      state.templates.data = filtredTemplates;
    },
    setTemplates: (state, action) => {
      state.templates.data = action.payload;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    },
    resetTemplatesData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    //get templates
    [getTemplates.pending]: (state) => {
      state.templates.error = null;
      state.templates.state = "loading";
    },
    [getTemplates.fulfilled]: (state, action) => {
      const templates = action.payload.templates;
      const meta = action.payload.meta;

      state.templates.data = templates;
      state.meta = meta;

      // state.allTemplates = templates;

      state.searchItems = templates;
      state.templates.state = "success";
    },
    [getTemplates.rejected]: (state, action) => {
      state.templates.error = action.error.message;
      state.templates.state = "error";
    },
    //get all templates
    [getAllTemplates.pending]: (state) => {
      state.templates.error = null;
      state.templates.state = "loading";
    },
    [getAllTemplates.fulfilled]: (state, action) => {
      state.allTemplates = action.payload.templates;
      state.state = "success";
    },
    [getAllTemplates.rejected]: (state, action) => {
      state.templates.error = action.error.message;
      state.templates.state = "error";
    },
    //get grouped templates
    [getGroupedTemplates.pending]: (state) => {
      state.groupedTemplates.error = null;
      state.groupedTemplates.state = "loading";
    },
    [getGroupedTemplates.fulfilled]: (state, action) => {
      const groupedTemplates = action.payload;
      state.groupedTemplates.data = groupedTemplates;
      state.searchItems = groupedTemplates;

      state.groupedTemplates.state = "success";
    },
    [getGroupedTemplates.rejected]: (state, action) => {
      state.groupedTemplates.error = action.error.message;
      state.groupedTemplates.state = "error";
    }
  }
});
export const {
  filterTemplates,
  selectAll,
  selectById,
  setTemplates,
  setSearchString,
  resetTemplatesData
} = templatesSlice.actions;

export default templatesSlice.reducer;

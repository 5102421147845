import { Outlet } from "react-router-dom";
import AuthGuard from "../../components/AuthGuard";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Backdrop from "../../components/Backdrop";
import { AbilityContext } from "../../context/permissions/Can";
import { useAbility } from "@casl/react";
import { Suspense } from "react";

const MainLayout = () => {
  const ability = useAbility(AbilityContext);
  return (
    <AuthGuard>
      <div className="admin_layout">
        <Sidebar />
        <div className="admin_layout__container">
          <Header isAdmin={true} />
          <Suspense>
            <Outlet />
          </Suspense>
        </div>
        <Backdrop />
      </div>
    </AuthGuard>
  );
};

export default MainLayout;

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_USER_FIELDS as fields } from "../../constants/fields";
import React, { useEffect, useState } from "react";
import { createUser, updateUser, updateUserPassword } from "../../../../data/slices/memberSlice";
import { InputSelect, InputText } from "../../../../components/InputFields";
import UploadImage from "../UploadImage/UploadImage";
import { closeModal, openModal } from "../../../../data/slices/modals";
import { Button, message } from "antd";
import { getUserGroups } from "../../../../data/slices/userGroup";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCurrentPlan } from "../../../../data/slices/currentPlan";
import { getStats } from "../../../../data/slices/statisticSlice";

function UserForm({ isEdit, id, user }) {
  const dispatch = useDispatch();
  const { t } = useTranslation("user");

  const { usersGroup } = useSelector((state) => state.usersGroup);

  const { currentPlan, planStatus } = useSelector((state) => state.currentPlan);
  const { usersCount } = useSelector((state) => state.stats);
  useEffect(() => {
    if (!currentPlan || currentPlan?.length === 0) {
      dispatch(getCurrentPlan());
    }
  }, []);

  // useEffect(() => {
  //   dispatch(getStats({}));
  // }, []);

  const formik = useFormik({
    initialValues: {
      profilePicture: isEdit ? user?.profilePicture : "",
      username: isEdit ? user.username : "",
      fullName: isEdit ? user.fullName : "",
      email: isEdit ? user.email : "",
      roles: isEdit ? user?.roles?.map((el) => el.name) : [],
      isActive: isEdit ? user?.isActive : true
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .min(2, t("user-40"))
        .max(70, t("user-41"))
        .matches(/^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/, t("user-42"))
        .required(t("user-43")),
      fullName: Yup.string().min(2, t("user-40")).max(70, t("user-41")).required(t("user-43")),
      password: isEdit
        ? Yup.string()
        : Yup.string().min(4, t("user-40")).max(24, t("user-41")).required(t("user-43")),
      email: Yup.string().email().min(2, t("user-40")).max(70, t("user-41")).required(t("user-43")),
      roles: Yup.array().min(1).required(t("user-43"))
    }),
    onSubmit: (values) => {
      if (!isEdit) {
        if (planStatus != "active" || currentPlan?.numberOfUsersFeature?.number <= usersCount) {
          message.error("You've reached the maximum user limit for your organization");
          return;
        }
        dispatch(createUser(values))
          .unwrap()
          .then((res) => {
            message.success(t("user-44"));
            dispatch(closeModal("user-modal"));
          })
          .catch((err) => {
            message.error(err.message || t("user-45"));
          });
      } else {
        const { password } = values;
        if (password)
          dispatch(updateUserPassword({ password, id }))
            .unwrap()
            .then((res) => {
              message.success(t("user-46"));
            })
            .catch((err) => {
              message.error(err.message || t("user-45"));
            });
        dispatch(updateUser({ values, id }))
          .unwrap()
          .then((res) => {
            message.success(t("user-47"));
            dispatch(closeModal("user-modal"));
          })
          .catch((err) => {
            message.error(err.message || t("user-45"));
          });
      }
    }
  });

  return (
    <div className="form_user">
      <form action="" onSubmit={formik.handleSubmit}>
        <UploadImage formik={formik} />

        <Grid container columnSpacing={2}>
          {fields
            .filter((input) =>
              isEdit ? input.name !== "username" && input.name !== "password" : input
            )
            .map((el, index) => (
              <Grid item md={6} sm={12} xs={12} key={index}>
                {<InputText formik={formik} field={{ ...el, label: t(el?.label) }} />}
              </Grid>
            ))}
          <Grid item md={6} sm={12} xs={12}>
            <InputText
              field={{
                name: "email",
                type: "email",
                placeholder: "Enter your email address",
                label: t("user-54")
              }}
              formik={formik}
              disabled={isEdit ? true : false}
            ></InputText>
          </Grid>
        </Grid>
        <InputSelect
          label={t("user-55")}
          formik={formik}
          options={usersGroup?.map((item) => ({
            value: item?.name,
            label: item?.displayName
          }))}
          defaultOptions={formik.values.roles}
          onChange={(e) => {
            formik.setFieldValue("roles", e);
          }}
          field="roles"
          onDropdownVisibleChange={() => {
            dispatch(getUserGroups({ query: "" }));
          }}
          notFoundContent={
            <Button onClick={() => dispatch(openModal("user-group"))} style={{ width: "100%" }}>
              {t("user-56")}
            </Button>
          }
        ></InputSelect>

        {/* <LacationInput
          countryInfo={countryInfo}
          stateInfo={stateInfo}
          setCountryInfo={setCountryInfo}
          setStateInfo={setStateInfo}
        /> */}

        {/* <div className="active_user">
          <Switch
            defaultChecked={formik.values.isActive}
            onChange={(value) => formik.setFieldValue("isActive", value)}
          />
          <p>
            Active
            <span> User Account Status</span>
          </p>
        </div> */}

        <div className="action_form">
          <button
            onClick={() => {
              dispatch(closeModal("user-modal"));
              formik.setValues({});
            }}
            className="dashboard-cancel-btn"
          >
            {t("user-25")}
          </button>
          <button type="submit" className="dashboard-confirm-btn">
            {t("user-27")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default UserForm;

import React from "react";
import Tabs from "../Tabs";
import { useSelector } from "react-redux";
import { changeNotifTab } from "../../../../data/slices/tabsSlice";

function DrawerViewTitle() {
  const { notificationTabs } = useSelector((state) => state.tabs);

  return (
    <div className="view-title-notif">
      <Tabs
        tabs={notificationTabs.tabs}
        defaultTab={notificationTabs.activeTab}
        setTab={changeNotifTab}
        type={notificationTabs.type}
      />
    </div>
  );
}

export default DrawerViewTitle;

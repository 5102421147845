import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import closeIcon from "../../../../assets/icons/dashboard-x-close.svg";
import React, { useEffect } from "react";
import UploadImage from "../../../Users/components/UploadImage/UploadImage";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { InputText } from "../../../../components/InputFields";
import { Switch } from "antd";
import { getImageGroups } from "../../../../data/slices/groups";
import { handleQuery } from "../../../../helpers/searchQuery";
import { createSnapshot } from "../../../../data/slices/snapshot";
import { useState } from "react";
import { notification, message } from "antd";
import { useTranslation } from "react-i18next";

const SnapshotModal = ({ id, open, handleClose, data = null, ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("user");

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      templateName: "",
      templateDescription: "",
      templateIcon: "",
      templateCategories: ["lab"],
      isPrivate: true
    },
    validationSchema: Yup.object().shape({
      templateName: Yup.string()
        .min(2, t("user-40"))
        .max(70, t("user-41"))
        .matches(/^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/, t("user-57"))
        .required(t("user-43")),
      templateIcon: Yup.string().required("Icon is required")
    }),
    onSubmit: (values) => {
      setLoading(true);
      console.log({ values }, data?.sessionId);
      dispatch(createSnapshot({ ...values, sessionName: data?.sessionId }))
        .unwrap()
        .then((res) => {
          handleClose(id);
          notification.success({
            message: "Snapshot Creation",
            description:
              "The creation of this snapshot may take some time. We'll notify you by email once it's completed."
          });
        })
        .catch((err) => {
          message.error("Snapshot creation failed");
        })
        .finally(() => setLoading(false));
    }
  });
  useEffect(() => {
    if (open) {
      dispatch(getImageGroups({ query: handleQuery({ page: 1, limit: 50 }) }));
    }
    formik.resetForm();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason === "escapeKeyDown" && handleClose(id)}
      className="ta-modal user_modal dashboard-user-modal quota-group-drawer snapshot-modal"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "829px"
          }
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <span className="label">SnapShot Configuration</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>

      <DialogContent className="ta-modal-content-scroll drawer-group">
        <form action="" onSubmit={formik.handleSubmit}>
          <UploadImage formik={formik} label="templateIcon" />
          {formik.errors?.templateIcon && (
            <span className="error_txt">{formik.errors?.templateIcon}</span>
          )}
          <InputText
            formik={formik}
            field={{
              label: "Snapshot Name*",
              name: "templateName",
              required: true,
              placeholder: "Enter your full name"
            }}
          />
          <InputText
            formik={formik}
            field={{
              label: "Description",
              name: "templateDescription",
              required: true,
              placeholder: "Write a brief description..."
            }}
          />
          <div className="snap-permissions">
            <p>Choose whether this snapshot can be private or public.*</p>
            <span className="switch">
              <p>Public</p>
              <Switch onChange={(e) => formik.setFieldValue("isPrivate", !e)} />
            </span>
          </div>

          <div className="confirmation">
            <button
              className="confirmation-cancel"
              type="reset"
              onClick={() => {
                handleClose(id);
              }}
            >
              Cancel
            </button>
            <button type="submit" className="confirmation-confirm">
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SnapshotModal;

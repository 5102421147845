export function getVerbsForResources(resources, roles) {
  const verbs = new Set(); // Use a set to avoid duplicates
  roles?.forEach((role) => {
    role?.rules?.forEach((rule) => {
      if (resources?.includes(rule?.resource)) {
        rule?.verbs?.forEach((verb) => verbs?.add(verb));
      }
    });
  });
  return Array.from(verbs); // Convert set back to array
}

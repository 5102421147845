import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
let notifications = [
  {
    id: 1,
    content: "The upload of your snapshot was completed successfully.",
    isNotificationRead: false,
    isArchived: false
  },

  {
    id: 3,
    content: "The upload of your snapshot was completed successfully.",
    isNotificationRead: false,
    isArchived: false
  },
  {
    id: 2,
    content: "The upload of your snapshot was completed successfully.",
    isNotificationRead: true,
    isArchived: false
  }
];

const initialState = {
  notifications: notifications,
  error: null,
  loading: null
};

export const getNotifications = createAsyncThunk("api/get-notifications", async ({ query }) => {
  let data;
  try {
    const response = await axiosInstance.get(`/notifications`);

    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    return null;
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error?.message : data?.message);
  }
});

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    readNotification: (state, action) => {
      const updatedNotifications = state.notifications.map((el) =>
        el.id === action.payload ? { ...el, isNotificationRead: true } : el
      );
      state.notifications = updatedNotifications;
    },
    readAllNotifications: (state, action) => {
      state.notifications = state.notifications.map((el) => ({
        ...el,
        isNotificationRead: true
      }));
    },
    addNotification: (state, action) => {
      state.notifications.unshift(action.payload);
    }
  },
  extraReducers: {
    [getNotifications.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.notifications = action.payload;
      state.loading = false;
    },
    [getNotifications.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const { readNotification, readAllNotifications, addNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;

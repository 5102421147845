import { Dialog, DialogContent } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/dashboard-x-close.svg";
import React from "react";
import RDPTemplateForm from "../../TemplateForm/RDPTemplateForm";
import { useTranslation } from "react-i18next";

const RDPTemplateModal = ({ id, open, handleClose, data, ...rest }) => {
  const { t } = useTranslation("index");

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason === "escapeKeyDown" && handleClose(id)}
      className="config-desktop-modal"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "555px",
            borderRadius: "11px"
          }
        }
      }}
    >
      <DialogContent className="config-desktop-modal-content">
        <div className="config-desktop-modal-title">
          <img src={data?.desktop?.imageIconUrl} alt="" draggable="false" className="logo-icon" />
          <div className="text-container">
            <p className="title">
              {t("home.TemplateModal-1")} {data?.desktop?.displayName}
            </p>
            <small className="subtitle">{t("home.TemplateModal-2")}</small>
          </div>
          <CloseIcon onClick={() => handleClose(id)} className="close-icon" />
        </div>

        <RDPTemplateForm data={data} />
      </DialogContent>
    </Dialog>
  );
};

export default RDPTemplateModal;

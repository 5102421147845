import { Modal, Slider } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

const levels = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9"
};

const StreamQualityModal = ({ id, open, handleClose, data = null, ...rest }) => {
  const dispatch = useDispatch();
  // 6 is the default value
  const [value, setValue] = useState(2);
  const onClose = () => {
    handleClose(id);
  };

  return (
    <Modal
      open={open}
      onOk={() => {
        handleClose(id);
      }}
      onCancel={onClose}
      className="stream-quality"
      cancelText="Cancel"
      okText="OK"
      closable={false}
    >
      <div className="stream-quality-content">
        <div className="stream-quality-header">Quality streaming</div>
        <div className="stream-quality-slider">
          <p>
            Quality Level: <span>{value >= 5 ? "High" : "Low"}</span>
          </p>
          <Slider
            max={9}
            min={0}
            onChange={(v) => {
              setValue(v);
              data.connection.sendQualityLevel(v);
            }}
            value={value}
            dots
            marks={levels}
          />
        </div>
      </div>
    </Modal>
  );
};
export default StreamQualityModal;

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import closeIcon from "../../../../../assets/icons/dashboard-x-close.svg";
import UserForm from "../../UserForm/UserForm";
import { useTranslation } from "react-i18next";

function UserModal({ id, open, handleClose, data, ...rest }) {
  const { t } = useTranslation("user");

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason === "escapeKeyDown" && handleClose(id)}
      className="ta-modal user_modal dashboard-user-modal"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "829px"
          }
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <span className="label">{data?.isEdit ? t("user-37") : t("user-38")}</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>
      <p className="subtitle">{t("user-39")}</p>

      <DialogContent className="ta-modal-content-scroll">
        <UserForm
          isEdit={data?.isEdit || false}
          id={data?.isEdit ? data?.id : null}
          user={data?.isEdit ? data?.user : null}
        />
      </DialogContent>
    </Dialog>
  );
}

export default UserModal;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createConfig, getConfig } from "../../../../data/slices/ltiConfig";
import { Card, Button, message, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../../Authentication/components/Input";
import WrapperLoader from "../../../../components/WrapperLoader/WrapperLoader";

const { Text } = Typography;

function ConfigLtiV1_3() {
  const dispatch = useDispatch();
  const { config, loading } = useSelector((state) => state.ltiConfig);

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    message.success("Copied to clipboard!");
  };

  const formik = useFormik({
    initialValues: {
      issuer_url: "",
      client_id: ""
    },
    validationSchema: Yup.object().shape({
      issuer_url: Yup.string().required(),
      client_id: Yup.string().required()
    }),
    onSubmit: (values) => {
      dispatch(createConfig({ ...values }))
        .unwrap()
        .then(() => {
          message.success("Configurations created successfully!");
          formik.resetForm();
        })
        .catch((err) => {
          message.error("Failed to create configurations , please try again");
        });
    }
  });
  const formatKeyName = (key) =>
    key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  useEffect(() => {
    formik.setFieldValue("issuer_url", config?.issuer_url);
    formik.setFieldValue("client_id", config?.client_id);
  }, [config]);
  return (
    <WrapperLoader status={loading}>
      <div className="config_form">
        <form action="">
          <Input
            type="text"
            placeholder="Enter the Issuer URL"
            label="Issuer URL *"
            name="issuer_url"
            formik={formik}
            // value={config?.issuer_url}
          />
          <Input
            type="text"
            placeholder="Enter the Client ID"
            label="Client ID *"
            name="client_id"
            formik={formik}
            // value={config?.client_id}
          />
          <Button disabled={config?.client_id} type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>
        </form>
      </div>
      <div className="config-container">
        {config &&
          Object.entries(config).map(([key, value]) => (
            <React.Fragment key={key}>
              {value ? (
                <Card key={key} className="config-card" bordered>
                  <div className="config-content">
                    <Text strong>{formatKeyName(key)}</Text>
                    <Text copyable>{value}</Text>
                    <Button
                      type="primary"
                      icon={<CopyOutlined />}
                      onClick={() => copyToClipboard(value)}
                      size="small"
                    >
                      Copy
                    </Button>
                  </div>
                </Card>
              ) : null}
            </React.Fragment>
          ))}
      </div>
    </WrapperLoader>
  );
}

export default ConfigLtiV1_3;

const handleQuery = ({ page, limit, searchString }) => {
  if (page && limit && searchString) {
    return `?search=${searchString}&page=${page}&limit=${limit}`;
  } else if (page && limit && !searchString) {
    return `?page=${page}&limit=${limit}`;
  } else if (searchString && !page && !limit) {
    return `?search=${searchString}`;
  }
};
export { handleQuery };

import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { getUsers } from "./memberSlice";
import { getImageGroups } from "./groups";

const initialiseMembers = (users) => {
  return users.map((member) => {
    return { ...member, checked: false };
  });
};

const initialState = {
  usersGroup: [],
  meta: {},
  searchGroups: [],
  state: "idle",
  error: null,
  message: null,
  loading: null,
  searchString: "",
  verbs: {
    data: null,
    state: "idle",
    error: null,
    message: null,
    loading: null
  },
  resources: {
    data: null,
    state: "idle",
    error: null,
    message: null,
    loading: null
  }
};

export const getUserGroups = createAsyncThunk("api/users-groups", async ({ query }, thunkApi) => {
  let data;
  // let str = page && limit ? `?page=${page}&limit=${limit}` : "";
  try {
    const response = await axios.get(`/roles${query}`);
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getImageGroups({ query: "" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

//create group / role
export const createGroup = createAsyncThunk("api/create-group", async (values, thunkApi) => {
  let data;
  try {
    const response = await axios.post(`/roles`, values);
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getUserGroups({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    console.log(error);
    return Promise.reject(error.message ? error.message : error.error);
  }
});

//assign user / role
export const assignUsersToGroups = createAsyncThunk("api/assign-user", async (values, thunkApi) => {
  let data;
  try {
    const response = await axios.post(`/roles/rbac/assign`, values);
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getUsers({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    console.log(error);
    return Promise.reject(error.message ? error.message : error.error);
  }
});
//delete group / role
export const deleteGroup = createAsyncThunk("api/delete-group", async (id, thunkApi) => {
  let data;
  try {
    const response = await axios.delete(`/roles/${id}`);
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getUserGroups({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

//edit role / group

export const updateGroup = createAsyncThunk("api/edit-group", async (query, thunkApi) => {
  let { values, id } = query;
  let data;
  try {
    const response = await axios.put(`/roles/${id}`, { ...values });
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getUserGroups({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    console.log(error);
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

//
// get verbs
export const getVerbs = createAsyncThunk("api/verbs", async () => {
  let data;
  try {
    const response = await axios.get(`roles/rbac/verbs`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

// get resources
export const getResources = createAsyncThunk("api/resources", async () => {
  let data;
  try {
    const response = await axios.get(`roles/rbac/resources`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const usersGroupSlice = createSlice({
  name: "usersGroup",
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.usersGroup = current(state).usersGroup.map((member) => {
        return { ...member, checked: action.payload };
      });
    },
    selectById: (state, action) => {
      state.usersGroup = current(state).usersGroup.map((member) => {
        if (member?.name === action.payload.id) return { ...member, checked: action.payload.value };
        return member;
      });
    },
    setUserGroups: (state, action) => {
      state.usersGroup = action.payload;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    },
    resetusersGroupSliceData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    //get users
    [getUserGroups.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getUserGroups.fulfilled]: (state, action) => {
      const groups = action.payload.roles;
      const meta = action.payload.meta;
      const newData = initialiseMembers(groups);
      state.usersGroup = newData;
      state.meta = meta;
      state.searchGroups = newData;
      state.state = "success";
    },
    [getUserGroups.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },

    //get verbs
    [getVerbs.pending]: (state) => {
      state.verbs.error = null;
      state.verbs.state = "loading";
    },
    [getVerbs.fulfilled]: (state, action) => {
      state.verbs.data = action.payload;
      state.verbs.state = "success";
    },
    [getVerbs.rejected]: (state, action) => {
      state.verbs.error = action.error.message;
      state.verbs.state = "error";
    },

    //get resources
    [getResources.pending]: (state) => {
      state.resources.error = null;
      state.resources.state = "loading";
    },
    [getResources.fulfilled]: (state, action) => {
      state.resources.data = action.payload;
      state.resources.state = "success";
    },
    [getResources.rejected]: (state, action) => {
      state.resources.error = action.error.message;
      state.resources.state = "error";
    }
  }
});

export const { selectAll, selectById, setUserGroups, setSearchString, resetusersGroupSliceData } =
  usersGroupSlice.actions;

export default usersGroupSlice.reducer;

import { Input } from "antd";

function InputText(props) {
  const { field, formik, value, min, disabled, ...other } = props;
  const InputComponent = field?.type === "password" ? Input.Password : Input;
  return (
    <span className="s_input">
      <label htmlFor={field?.name}>{field?.label}</label>
      <InputComponent
        id={field?.name}
        name={field?.name}
        type={field?.type}
        className="input_antd"
        placeholder={field?.placeholder}
        value={value || formik?.values[field?.name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        status={formik.errors[field?.name] && formik.touched[field?.name] ? "error" : null}
        autoComplete="new-password"
        min={min}
        disabled={disabled}
        {...other}
      />
      <p className="error_txt">{formik.touched[field?.name] && formik.errors[field.name]}</p>
    </span>
  );
}

export default InputText;

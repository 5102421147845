import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

const initialState = {
  stats: null,
  usersCount: 0,
  resources: null,
  userUsage: {},
  topTemplates: null,
  error: null,
  loading: null
};

export const getStats = createAsyncThunk("api/stats/items/count", async ({ query }) => {
  let data;
  try {
    const response = await axiosInstance.get(`/stats/items/count`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const getResourcesUsage = createAsyncThunk(
  "api/stats/resources-usage",
  async ({ query }) => {
    let data;
    try {
      const response = await axiosInstance.get(`/consumption/org`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const getUsersUsage = createAsyncThunk("api/stats/users-usage", async ({ query }) => {
  let data;
  try {
    let url;

    if (query) {
      url = `/consumption/groups?startDate=${query?.startDate}&endDate=${query?.endDate}`;
    } else {
      url = `/consumption/groups`;
    }

    const response = await axiosInstance.get(url);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const getTopUsedTemplates = createAsyncThunk("api/stats/templates", async ({ query }) => {
  let data;
  try {
    const response = await axiosInstance.get(`/stats/templates/usage?orgId=${query}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const offersSlice = createSlice({
  name: "offersSlice",
  initialState,
  reducers: {
    resetoffersSliceData: (state, action) => {
      return initialState;
    }
  },
  extraReducers: {
    [getStats.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getStats.fulfilled]: (state, action) => {
      state.stats = action.payload;
      if (action.payload?.usersCount) {
        state.usersCount = action.payload?.usersCount;
      }
      state.loading = false;
    },
    [getStats.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getResourcesUsage.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getResourcesUsage.fulfilled]: (state, action) => {
      state.resources = action.payload;
      state.loading = false;
    },
    [getResourcesUsage.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },

    [getUsersUsage.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getUsersUsage.fulfilled]: (state, action) => {
      state.userUsage = action.payload;
      state.loading = false;
    },
    [getUsersUsage.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getTopUsedTemplates.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getTopUsedTemplates.fulfilled]: (state, action) => {
      state.topTemplates = action.payload;
      state.loading = false;
    },
    [getTopUsedTemplates.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    }
  }
});

// eslint-disable-next-line no-empty-pattern
export const { resetoffersSliceData } = offersSlice.actions;

export default offersSlice.reducer;

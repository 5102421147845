import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  isSidebarOpened: false,
  isClipboardEnabled: false,
  isFullScreen: false,
  clipboardText: "",
  sessionClipboardText: [],
  isProfileNavOpen: false
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpened = !current(state).isSidebarOpened;
    },
    openSidebar: (state) => {
      state.isSidebarOpened = true;
    },
    closeSidebar: (state) => {
      state.isSidebarOpened = false;
    },
    toggleFullScreenEnabled: (state) => {
      state.isFullScreen = !state.isFullScreen;
    },
    toggleIsClipboardEnabled: (state) => {
      state.isClipboardEnabled = !state.isClipboardEnabled;
    },
    setClipboardText: (state, { payload }) => {
      state.clipboardText = payload;
    },
    setSessionClipboardText: (state, { payload }) => {
      const newArray = [payload, ...state.sessionClipboardText];
      state.sessionClipboardText = newArray.filter(
        (item, index) => newArray.indexOf(item) === index
      );
    },
    toggleProfileNav: (state) => {
      state.isProfileNavOpen = !current(state).isProfileNavOpen;
    },
    resetsettingsSliceData: (state, action) => {
      return initialState;
    }
  }
});

export const {
  toggleSidebar,
  openSidebar,
  closeSidebar,
  toggleIsClipboardEnabled,
  setClipboardText,
  setSessionClipboardText,
  toggleProfileNav,
  toggleFullScreenEnabled,
  resetsettingsSliceData
} = settingsSlice.actions;

export default settingsSlice.reducer;

import React, { useState } from "react";
import { Drawer } from "antd";
import DrawerViewTitle from "../DrawerViewTitle/DrawerViewTitle";
import { useDispatch, useSelector } from "react-redux";
import NormalNotification from "../Notifications/NormalNotification/NormalNotification";
import { Tooltip } from "antd";
import { readAllNotifications } from "../../../../data/slices/notificationSlice";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

function NotificationsDrawer({ id, open, handleClose, data, ...rest }) {
  const dispatch = useDispatch();

  const { notifications } = useSelector((state) => state.notification);
  const { notificationTabs } = useSelector((state) => state.tabs);

  const handleNotificationsByTypes = (notifications, activeTab) => {
    if (activeTab === "0") {
      return notifications.filter((el) => el.isArchived === false);
    }
    if (activeTab === "1") {
      return notifications.filter((el) => el.isNotificationRead === false);
    }
    if (activeTab === "2") {
      return notifications.filter((el) => el.isArchived === true);
    }
  };
  const handleReadAll = () => {
    dispatch(readAllNotifications());
  };
  return (
    <Drawer
      className="drawer-notifications"
      closable={false}
      maskClosable={true}
      title={false}
      placement="right"
      open={open}
      width="450px"
      onClose={() => handleClose(id)}
      footer={<button className="read_all">View All</button>}
      colorBgMask="red"
    >
      <div className="n_header">
        <h6 className="n_title">Notifications</h6>
        <Tooltip
          overlayStyle={{
            fontSize: "13px"
          }}
          title="Mark all as read"
        >
          <span className="mark_all_read" onClick={handleReadAll}>
            {/* <img src={doneIcon} alt="" /> */}
            <IoCheckmarkDoneSharp color="#0088F7" size={25} />
          </span>
        </Tooltip>
      </div>
      <DrawerViewTitle />
      <div className="content_drawer">
        {handleNotificationsByTypes(notifications, notificationTabs?.activeTab)?.map(
          (notif, index) => (
            <NormalNotification key={index} notification={notif} />
          )
        )}
      </div>
    </Drawer>
  );
}

export default NotificationsDrawer;
